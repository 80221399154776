
import {removeStorageData} from '../../framework/src/Utilities';
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";

const handleNavigation=(screenEndPoint:string,navigationProps:any = {},send:(msg:Message)=>void, dataToSend:any= null)=>{
  const msg: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), navigationProps);
  dataToSend && msg.addData(getName(MessageEnum.NavigationScreenNameMessage), dataToSend);
  msg.addData(getName(MessageEnum.NavigationTargetMessage), screenEndPoint);
  send(msg);
  }


  const clearToken = async()=>{
   await removeStorageData('token');
   await removeStorageData('name');
   await removeStorageData('photo');
  }

  export {handleNavigation, clearToken}