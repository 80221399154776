import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  PopperProps,
  Divider,
  ListItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { AddLegtTypeButton, CustomDepartueAiportAutoCompplete,PaymentLabel, PaymentTexfields, CustomRadio, CustomStyledPopper, FilterTypeButton, FormSubmitButton, GoldishSimpleButton, MuiModal, NoRecordFoundRow, renderTableLoader, SearchTextFieldActiveBooking, StyledEditDeleteMenu, StyledModalContent, StyledPagination } from "../../../components/src/MuiCustomComponents";
import { FilterList, KeyboardArrowDown, RoomOutlined, Search, SearchOutlined, SwapVert } from "@material-ui/icons";
import {theme as colors} from "../../../components/src/utils";

import { Formik } from "formik";
import { Country, ICountry } from "country-state-city";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { ChargeExistingCompType,CombinedTextfieldType,master_image,PaymentTextFieldType, SavedCardType, TouchedMessage, VirtualTerminalType } from "./assets";


const viewSkycardsHeader = [
  {title:'Account ID',value:'account_id'},
  {title:'Client',value:"client"},
  {title:"Trips Remaining",value:'trip_remaining'},
  {title:"Aircraft Category",value:"aircraft_category"},
  {title:"From > To",value:"from_To"},
  {title:"Date Created",value:"date_created"},
  {title:"Expiry Date",value:"expiry_date"},
  {title:"CSC ID",value:"csc_id"},
  {title:"",value:""}
]

const purchaseSkycardHeader = [
  {title:"Order ID",value:"order_id"},
  {title:"Client",value:"client"},
  {title:"Account ID",value:"account_id"},
  {title:"Sky Card Type",value:"sky_card_type"},
  {title:"Date",value:"date"},
  {title:"Transaction Value",value:"transaction_value"},
  {title:"",value:""}
]

const purchasedSkycards=[
  {
    attributes:{
      order_id: "#3320200033",
      account_id: "2205729",
      client_name: "Theressa Webb",
      sky_card_type: "Debit Sky Card",
      date: "12/08/2023",
      transaction_value: "$2250.00"
    }
  }
]

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CfcustomskycardsController, {
  Props,
  configJSON,
} from "./CfcustomskycardsController";

export default class Cfcustomskycards extends CfcustomskycardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContainer=()=>{
    return <Box className='dash_main booking_main'>
      {this.renderAdminListHeader()}
      {this.renderAdminListTable()}
    </Box>
  }

  renderAdminListHeader=()=>{
    const {anchor,appliedFilter} = this.state
    const allFilters = [{category:"Aircraft Type",items:["Piston","Turbo Prop","Semi jet","Large jet","Midsize jet","Super lange jet","Light jet","Entry Level Jet","Super Light Jet","Midsize Jet","Super Midsize Jet","Heavy Jet","Ultra Long Run","VIP Airliner","Airliner"]},
    {category:"Date",items:["Expired","Not Expired"]}]
    return <Grid container justifyContent="space-between" style={{alignItems:'center',rowGap:'1rem'}}>
      <Grid item  md={3} sm={3} >
      <GoldishSimpleButton
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                    justifyContent: "start",
                    fontSize:"1.2rem",
                  }}
                  className='cf-avinode-search'
                >
              {this.handlePageName()}
            </GoldishSimpleButton>
      </Grid>
      <Grid item md={5} justifyContent="center">
        <SearchTextFieldActiveBooking
          fullWidth
          data-testid='search_field'
          onChange={this.handleQueryDebounce}
          placeholder={this.page==="/Viewcustomskycards"?'Enter client name, account ID or csc ID...':"Enter the order ID, account ID or client name..."}
          className="search_admins"
          style={{ position: "relative", left: "-10px" ,width:'80%'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Search style={{ color: "#757677" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={3} sm={12} style={{paddingLeft:"2.5rem"}}>
        <AddLegtTypeButton style={{fontSize:"1rem"}} onClick={()=>this.handleSkycardModal(true)} data-test-id="addSkyCard">+ Add New Custom Sky Card</AddLegtTypeButton>
      </Grid>
      <Grid md={1} >
      <Box style={{display:"flex",justifyContent:"flex-end"}}>
            <FilterTypeButton  data-test-id='menu_opener' onClick={(event:React.MouseEvent<HTMLButtonElement,MouseEvent>)=>this.openFilters(event.currentTarget)}>
              <FilterList style={{ fontSize: "2rem" }} />
            </FilterTypeButton>
          </Box>
          <StyledEditDeleteMenu
              PaperProps={{
                style: {
                  width: "12rem",
                  marginTop: "9rem",
                  padding: "0px 10px",
                  overflow:"auto"
                },
              }}
              id='simple-menu'
              anchorEl={anchor}
              keepMounted
              open={Boolean(anchor)}
              onClose={this.closeFilters}
            >
              <Box className='order_detail_view_menu_box' maxHeight={"13rem"} overflow={"auto"}>
                {allFilters.map((elem) => {
                  return (
                    <>
                    <ListItem className="order_detail_view_menu_item" style={{ position: "sticky", fontWeight: 700 }}>{elem.category}</ListItem>
                    {
                      elem.items.map(category=>
                        <Box className='order_detail_view_menu_item'>
                        <Checkbox
                          checked={appliedFilter.includes(category)}
                          data-test-id='check_box'
                          style={{ color: "white" }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckboxChange(event as unknown as React.MouseEvent<HTMLElement, MouseEvent>, category)}
                        />
                        <Box style={{ textTransform: "capitalize" }}>{category}</Box>
                      </Box>
                        )
                    }
                    </>
                  );
                })}
                </Box>
                <FormSubmitButton
                  className='cf_avinode_apply_filter'
                  fullWidth
                  onClick={this.handleApplyFilter}
                  disabled={appliedFilter.length === 0}
                  data-test-id='apply_filter'
                >
                  Apply Filters
                </FormSubmitButton>
            </StyledEditDeleteMenu>
      </Grid>
    </Grid>
  }

  renderAdminListTable=()=>{
    return <TableContainer className="dash_table_flights" style={{marginTop:"2rem"}}>
      <Table style={{borderCollapse:"separate"}}>
        {this.renderTableHeadRow()}
        <Box height={'15px'}/>
        {this.renderTableCellContainer()}
        
      </Table>
      {this.renderPagination()}
    </TableContainer>
  }

  renderTableHeadRow=()=>{
    const tableHeaders = this.page === "/Viewcustomskycards" ? viewSkycardsHeader : purchaseSkycardHeader
    return <TableHead>
      <TableRow className="dash_table_head" style={{height:"auto"}}>
        {tableHeaders.map((elem)=>this.renderHeadCell(elem))}
      </TableRow>
    </TableHead>
  }

  renderHeadCell=(head:{title:string,value:string})=>{
    const {title,value} = head;
    const {sortBy} = this.state
    return <TableCell style={{borderRadius:"0"}}>
      <Box className="order_detail_table_head_cell">
            <Box>{title}</Box>
            <Box 
             id="order_detail_view_menu_sort_button" data-testid="menu_sort_button" 
             className={value === sortBy? 'order_detail_view_menu_sorted' : ''}
             onClick={()=>{this.handleSorting(value)}}
            >
          {value ? <SwapVert style={{color : colors.GOLD_COLOR}} />:<Box style={{width:"5rem"}}></Box>}</Box>
      </Box>
    </TableCell>
  }

  renderTableCellContainer=()=>{
    const {loading} = this.state;
    return <>
      {!loading ? this.renderNotLoadingContent() :this.renderLoader()}
    </>
  }

  renderNotLoadingContent=()=>{
    const {skyCardData} = this.state;
    const skyCards = this.page === "/Viewcustomskycards" ? skyCardData : purchasedSkycards
    return <>
     {skyCards.length !== 0 ? (skyCards).map((elem) => this.page === "/Viewcustomskycards" ? this.renderEachRowInfo(elem) : this.renderSkyCardsPurchasesRow(elem)) : <NoRecordFoundRow>{null}</NoRecordFoundRow>}
    </>
   }

   renderEachRowInfo=(rowInfo:any)=>{
    const {attributes:{account_id,client_name,trips_remaining,aircraft_category,from_airport,to_airport,date_created,expiry_date,CSC_ID}} = rowInfo;
    return <>
      <TableRow className="dash_table_row order_details_row" onClick={()=>{}}>
        <TableCell className="dash_rounded-row-first-cell">{account_id}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{client_name}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{trips_remaining}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{aircraft_category }</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{from_airport.icao} {">"} {to_airport.icao} </TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{date_created}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{expiry_date}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{CSC_ID}</TableCell>
        <TableCell className={`dash_rounded-row-last-cell`} style={{textTransform:'capitalize'}}>{this.renderTopUpButton()}</TableCell>
      </TableRow>
      <Box height={'0.5rem'}/>
    </>
  }

  renderLoader=()=>{
    const colspan = 6;
    return <TableCell colSpan={colspan} style={{borderBottom:'none'}}>
      {renderTableLoader()}
    </TableCell>
  }

  renderTopUpButton = ()=>{
    return(
      <Box style={{border:"1px solid #FFD27E",borderRadius:"0.6rem",color:"#FFD27E",fontSize:"1rem",padding:"0.2rem 0.2rem",textAlign:"center"}}>{this.page !== "/Viewcustomskycards" ? "Download Invoice" : "Top Up"}</Box>
    )
  }

  ChargeNewCard = ({cardDetails,activeFieldName,setActiveFieldName}:VirtualTerminalType) => {

  
    return (
      <Box className='order_detail_view_cards_parent booking_section' style={{backgroundColor:'#1A1C1E',paddingTop:"1rem",paddingBottom:"2rem"}}>
        <Box className='order_detail_view_cards_head' style={{fontSize:"1.4rem",fontWeight:600}}>Charge a New Card</Box>
        
  
        <Formik
              initialValues={cardDetails}
              onSubmit={(values) => {
              }}
              enableReinitialize
              validateOnChange
              validateOnBlur
              key={cardDetails.invoice_id || "new"}
            >
              {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
                <Grid container>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingRight: "1rem", borderRight: "1px solid #3E4247",rowGap:"1.55rem"}}
            className="booking_section"
          >
            {this.renderTextfield({value:values.amount,formName:"amount", handleChange:handleChange, placeholderText:'Amount', label:"Amount(in $)",touched:touched.amount,message:errors.amount,errors:errors.amount})}         
            {this.renderTextfield({value:values.card_number,formName:"card_number",handleChange:handleChange,placeholderText:"card number",label:"Card Number",touched:touched.card_number,errors:errors.card_number,message:errors.card_number})}
            {this.renderTextfield({value:values.name_on_card,formName:"name_on_card",handleChange:handleChange,placeholderText:"Name",label:"Name on Card",touched:touched.card_number,errors:errors.card_number,message:errors.card_number})} 
          <Box display={'flex'} justifyContent={'space-between'} gridColumnGap={'1rem'}>
          <Box flex={1}>
            
            {this.PaymentFieldWithLabel({value:values.expiry,activeFieldName:activeFieldName,setActiveFieldName:setActiveFieldName,formName:"expiry",handleChange:handleChange,placeholderText:'MM/YY',label:"Expiry Date"})}
            {this.ErrorBoxes({touched:touched.expiry,errors:errors.expiry,message:errors.expiry})}
          </Box>
          <Box flex={1}>
            
            {this.PaymentFieldWithLabel({value:values.security_code,activeFieldName:activeFieldName,setActiveFieldName:setActiveFieldName,formName:"security_code",handleChange:handleChange,placeholderText:"CVV",label:"Security Code"})}
            {this.ErrorBoxes({touched:touched.security_code,errors:errors.security_code,message:errors.security_code})}
          </Box>
  
          </Box>
  
  
          </Grid>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingLeft: "1rem",rowGap:"1.55rem" }}
            className="booking_section"
          >
                <Box>
                  {this.PaymentFieldWithLabel({value:values.street_address, activeFieldName:activeFieldName, setActiveFieldName:setActiveFieldName, formName:"street_address", handleChange:handleChange, placeholderText:'Street Address', label:"Street Address"})}
                  {this.ErrorBoxes({touched:touched.street_address, errors:errors.street_address, message:errors.street_address})}
                </Box>
  
                <Box>
                  {this.PaymentFieldWithLabel({value:values.city, activeFieldName:activeFieldName, setActiveFieldName:setActiveFieldName, formName:"city", handleChange:handleChange, placeholderText:'City', label:"City"})}
                  {this.ErrorBoxes({touched:touched.city, errors:errors.city, message:errors.city})}
  
                </Box>
  
                <Box display={'flex'} justifyContent={'space-between'} gridColumnGap={'1rem'}>
                <Box flex={1}>
                  {this.PaymentFieldWithLabel({value:values.state,  activeFieldName:activeFieldName, setActiveFieldName:setActiveFieldName, formName:"state", handleChange:handleChange, placeholderText:'State', label:"State"})}
                  {this.ErrorBoxes({touched:touched.state, errors:errors.state, message:errors.state})}
                </Box>
  
  
                <Box flex={1}>
                  {this.PaymentFieldWithLabel({value:values.zip_code, activeFieldName:activeFieldName, setActiveFieldName:setActiveFieldName, formName:"zip_code", handleChange:handleChange, placeholderText:'Zip Code', label:"Zip Code"})}
                  {this.ErrorBoxes({touched:touched.zip_code, errors:errors.zip_code, message:errors.zip_code})}
  
                </Box>
  
          </Box>
  
                <Box>
                  <PaymentLabel isFocused = {activeFieldName === 'country'}>Country</PaymentLabel>
                  {this.autoComplete({setFieldValue:setFieldValue,field:"country",placeholder:"Choose Country"})}
                  
                    {this.ErrorBoxes({touched:touched.country, errors:errors.country, message:errors.country})}
  
                </Box>
  
                 </Grid>
                 </Grid>
                 <FormSubmitButton   data-testid = {'process_payment'} onClick={()=>handleSubmit()} fullWidth style={{marginTop:'1rem'}}>Process Payment</FormSubmitButton>
              </>)}
        </Formik>
      
  
      </Box>
    )
  }
   CountryOption=({option}:{option:ICountry})=>{
    return (
      <Box className="register_airport_option">
         <Box color={'#636E72'}>
           <RoomOutlined/>
         </Box>
         <Box>{option.name}</Box>
      </Box>
    )
  }
  PaymentFieldWithLabel=({value,activeFieldName,setActiveFieldName,label,handleChange,placeholderText,formName}:PaymentTextFieldType & {setActiveFieldName : (action:string)=>void,
    activeFieldName : string})=>{
      const cardInputProp = formName === 'card_number' ? {maxLength : 19} : {};
    return <>
    <PaymentLabel isFocused = {activeFieldName === formName} >{label}</PaymentLabel>
    <PaymentTexfields 
       isFocusedTypeInput={true}
       isErrorTouch = {false}
       variant='outlined'
       name={formName}
       onChange={handleChange}
       placeholder={`Enter ${placeholderText}`} 
       fullWidth
       value={value}
       autoComplete='off'
       onBlur= {()=>setActiveFieldName('')}
       onFocus= {()=>setActiveFieldName(formName)}
       data-test-id = {formName}
       InputProps={{
        inputProps : {
          ...cardInputProp,
          style:{padding:'0px'}
        }
       }}
       />
  </>
  }

  ErrorBoxes = ({touched,errors,message}:{touched: TouchedMessage,errors : string | undefined,message:string | undefined})=>{
    return <>
      {touched && errors && (
        <Box className="login_valid_error" id = 'profile_error_properties'>
          {message}*
        </Box>
      )}
    </>
  }

  addCard = ({cardDetails,activeFieldName,setActiveFieldName}:VirtualTerminalType)=>{
    return(
      <Box className='order_detail_view_cards_parent booking_section' style={{backgroundColor:'rgb(40,43,46)'}}>
        <Box className='order_detail_view_cards_head' style={{fontSize:"1.4rem",fontWeight:600}}>Add Custom Sky Card</Box>
        <Formik
              initialValues={cardDetails}
              onSubmit={(values) => {
              }}
              enableReinitialize
              validateOnChange
              validateOnBlur
              key={cardDetails.invoice_id || "new"}
            >
              {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
        <Grid container>
          <Grid item sm={12} md={12} lg={12} xl={12}
          style={{padding:"1rem 2rem 1.6rem 0"}}
          >
          <SearchTextFieldActiveBooking
           fullWidth
           data-testid='search_field'
           placeholder="Search account ID"
           style={{backgroundColor:"rgba(0,0,0,1)"}}
           InputProps={{
             endAdornment: (
               <InputAdornment position='end'>
                 <Search style={{ color: "#757677" }} />
               </InputAdornment>
             ),
           }}
          />
          </Grid>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingRight: "1rem",rowGap:"1.55rem"}}
            className="booking_section"
          >
          <Box>
            <PaymentLabel>Client</PaymentLabel>
            <PaymentLabel style={{color:"white",fontSize:"1.2rem",fontWeight:400}}>Annette Black</PaymentLabel>
          
          </Box>
            <Box>
                  <PaymentLabel isFocused = {activeFieldName === 'country'}>From</PaymentLabel>
                  {this.autoComplete({setFieldValue:setFieldValue,field:"airport",placeholder:"Choose from"})}
                  {this.ErrorBoxes({touched:touched.country, errors:errors.country, message:errors.country})}
            </Box>
            {this.renderTextfield({value:values.amount,formName:"noOfTrips", handleChange:handleChange, placeholderText:'Number of Trips', label:"No of Trips",touched:touched.amount, errors:errors.amount, message:errors.amount})}
            {this.renderTextfield({value:values.card_number,formName:"additionalInfo",handleChange:handleChange,placeholderText:"additional info",label:"Additional Info",touched:touched.card_number,errors:errors.card_number,message:errors.card_number})}
            {this.renderTextfield({value:values.name_on_card,formName:"name_on_card",handleChange:handleChange,placeholderText:"date",label:"Valid From",touched:touched.card_number,errors:errors.card_number,message:errors.card_number})}
          </Grid>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingLeft: "1rem",rowGap:"1.55rem" }}
            className="booking_section"
          >
            <Box>
              <PaymentLabel>Account ID</PaymentLabel>
              <PaymentLabel style={{color:"white",fontSize:"1.2rem",fontWeight:400}}>3728474</PaymentLabel>
          
          </Box>
            <Box>
                  <PaymentLabel isFocused = {activeFieldName === "toAirport"}>To</PaymentLabel>
                  {this.autoComplete({setFieldValue:setFieldValue,field:"toAirports",placeholder:"Choose to"})}
                  
                    {this.ErrorBoxes({touched:touched.country, errors:errors.country, message:errors.country})}
  
                </Box>
                <Box>
                  <PaymentLabel isFocused = {activeFieldName === 'country'}>Aircraft Category</PaymentLabel>
                  {this.autoComplete({setFieldValue:setFieldValue,field:"aircraft",placeholder:"Choose aircraft category"})}
                  
                    {this.ErrorBoxes({touched:touched.country, errors:errors.country, message:errors.country})}
  
                </Box>
                <Box>
                  {this.PaymentFieldWithLabel({value:values.street_address, activeFieldName:activeFieldName, setActiveFieldName:setActiveFieldName, formName:"price", handleChange:handleChange, placeholderText:'Price', label:"Price"})}
                  {this.ErrorBoxes({touched:touched.street_address, errors:errors.street_address, message:errors.street_address})}
                </Box>
  
                <Box>
                  {this.PaymentFieldWithLabel({value:values.city, activeFieldName:activeFieldName, setActiveFieldName:setActiveFieldName, formName:"date", handleChange:handleChange, placeholderText:'date', label:"Valid To"})}
                  {this.ErrorBoxes({touched:touched.city, errors:errors.city, message:errors.city})}
  
                </Box>
  
                 </Grid>
                 </Grid>
              </>)}
        </Formik>
      
  
      </Box>
    )
  }

  chargeExisting=({activeFieldName,setActiveFieldName,cardDetails,savedCard}:ChargeExistingCompType)=>{
    const {amount,invoice_id,saved_payment_card_id} = cardDetails;
    const init_values  = {
      amount_pay:amount,
      invoice_id,
      card_id:saved_payment_card_id
    }
    
  
    return <Formik
    initialValues={init_values}
    onSubmit={(values) => {
      
    }}
    
    enableReinitialize
    validateOnChange
    validateOnBlur
    key={cardDetails.invoice_id || "newone"}
  >
    {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
      <Box className="order_detail_view_cards_parent booking_section" style={{rowGap:"1.85rem",backgroundColor:'#1A1C1E',paddingBottom:"2rem"}}>
      <Box className="order_detail_view_cards_head" style={{fontSize:"1.4rem",fontWeight:600}}>Charge an Existing Card</Box>
      <Box>
            {this.PaymentFieldWithLabel({value:values.amount_pay,activeFieldName:activeFieldName,setActiveFieldName:setActiveFieldName,formName:"amount_pay",handleChange:handleChange,placeholderText:" Amount",label:"Amount(in $)"})}
            {this.ErrorBoxes({touched:touched.amount_pay,errors:errors.amount_pay,message:errors.amount_pay})}
      </Box>
      <Box className="order_detail_last_child" borderRadius={'10px'} overflow={'auto'} height={'16rem'} bgcolor={'black'}>
        {savedCard && savedCard.map((cardSave)=>(
          
             this.paymentRadios({handleChangeCard:()=>setFieldValue('card_id',cardSave.id),cardDetails:cardSave,isChecked:cardSave.id===values.card_id})
          
        ))}
      </Box>
      <FormSubmitButton data-testid = {'process_payment_existing'} onClick={()=>handleSubmit()}  fullWidth>Process Payment</FormSubmitButton>
    </Box>
    </>)}</Formik>
  
  }

   paymentRadios=({cardDetails,isChecked,handleChangeCard}:{cardDetails:SavedCardType,isChecked:boolean,handleChangeCard:()=>void})=>{

    const {attributes:{cards:{card_number,card_type,expiry_date}}} = cardDetails;
      return <Box className="order_detail_view_radio_parent">
          <CustomRadio checked={isChecked} color="primary" onChange={handleChangeCard} data-testid = {'process_payment_existing_radio'}/>
          <Box className="order_detail_view_image_parent"><img style={{objectFit:"cover"}} src={master_image} height={'80%'} width={'100%'}/></Box>
          <Box className="order_detail_card_name_expiry_parent" marginLeft={'1rem'}>
            <Box>{card_type} ending in {card_number}</Box>
            <Box className="order_detail_expiry_font">Exp date: {expiry_date}</Box>
          </Box>
      </Box>
  }

  autoComplete = ({setFieldValue,field,placeholder}:{setFieldValue:(field:string,value:string)=>void,field:string,placeholder:string})=>{
    const {activeFieldName} = this.state
    return(
      <CustomDepartueAiportAutoCompplete
              bgColor={"inherit"}
              height={'3rem'}
              onChange={(_event: Event, newValue: ICountry) => {
                setFieldValue(field,newValue.name);
              }}
              data-test-id = {field}
              isFocusedTypeInput={activeFieldName === field}
              onBlur= {()=>this.setActiveFieldName('')}
              onFocus= {()=>this.setActiveFieldName(field)}
              id = 'profile_country_selector'
              PopperComponent={(props: PopperProps) => (
                  <CustomStyledPopper
                      {...props}
                      zIndex={1}
                      marginTop="1px"
                  />
              )}
              ListboxProps={{ id: "regsiter_option_style" }}
              getOptionLabel={(option: ICountry) => option.name}
              options={Country.getAllCountries()}
              renderOption={(
                  option: ICountry,
                  { selected }: { selected: boolean },
              ) =>this.CountryOption({option:option})}
              renderInput={(params: AutocompleteRenderInputParams) => (
                  <PaymentTexfields
                      {...params}
                      placeholder={placeholder}
                      InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                              <Box className="profile_hover_icons">
                                  <KeyboardArrowDown />
                              </Box>
                          ),
                          startAdornment: (
                              <Box>
                                  {field==="country" && <SearchOutlined />}
                              </Box>
                          ),
                      }}
                  />
                        )}
                            />
    )
  }

  renderTextfield = ({value,formName,handleChange,placeholderText,label,touched,errors}:CombinedTextfieldType )=>{
    const {activeFieldName} = this.state
    return(
      <Box>
            {this.PaymentFieldWithLabel({value:value,activeFieldName:activeFieldName, setActiveFieldName:this.setActiveFieldName, formName:formName, handleChange:handleChange, placeholderText:placeholderText, label:label})}
            {this.ErrorBoxes({touched:touched, errors:errors, message:errors})}
      </Box>
    )
  }

  renderPagination=()=>{
    const {totalPage, currentPage} = this.state;
    return <Box marginTop={'1rem'} alignSelf={"end"}>
      <StyledPagination 
        count={totalPage}
        page={currentPage}
        shape="rounded"
        className="pagination"
        onChange={(_event:React.ChangeEvent<unknown>,page:number)=>{this.handleCurrentPage(page);}}
        >
      </StyledPagination>
    </Box>
  }

  renderSkyCardsPurchasesRow = (rowInfo:any)=>{
    const { attributes: { order_id, account_id, client_name, sky_card_type, date, transaction_value } } = rowInfo;
    return(
      <>
      <TableRow className="dash_table_row order_details_row">
        <TableCell className="dash_rounded-row-first-cell">{order_id}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{client_name}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{account_id}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{sky_card_type}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{date}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{transaction_value}</TableCell>
        <TableCell className={`dash_rounded-row-last-cell`} style={{ textTransform: 'capitalize', textAlign: "center" }}>{this.renderTopUpButton()}</TableCell>
      </TableRow>
      <Box height={'0.5rem'} />
      </>
    )

  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <Box className="main_cont"> 
        {this.renderMainContainer()}
        <MuiModal open={this.state.addSkyCardModal}>
          <StyledModalContent 
          className='large_modal_styling booking_section' style={{paddingTop:"1.4rem"}}>
            
            {this.addCard({activeFieldName:this.state.activeFieldName,setActiveFieldName:this.setActiveFieldName,cardDetails:this.state.cardDetails})}
            {this.ChargeNewCard({activeFieldName:this.state.activeFieldName,setActiveFieldName:this.setActiveFieldName,cardDetails:this.state.cardDetails})}
            <Divider style={{margin:"1rem 0",backgroundColor:"#636e72",borderWidth:"1px"}}></Divider>
            {this.chargeExisting({activeFieldName:this.state.activeFieldName,setActiveFieldName:this.setActiveFieldName,cardDetails:this.state.cardDetails,savedCard:this.state.savedCard})}
            <FormSubmitButton style={{background:"none",border:"1px solid #fff",color:"#fff",margin:"1rem 0"}}>Process Without Payment</FormSubmitButton>
            <FormSubmitButton style={{background:"none",border:"1px solid #FFD27E",color:"#FFD27E"}} onClick={()=>this.handleSkycardModal(false)} data-test-id="cancel">Cancel</FormSubmitButton>
          </StyledModalContent>
        </MuiModal>
        
       </Box>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End