import React, { useEffect, useRef, useState } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import OrderDetailsView from "./OrderDetailsView";

// Customizable Area Start
import { handleNavigation } from "../../../components/src/Navigator";
import { Order } from "./types";
import {getStorageData} from "../../../framework/src/Utilities"
import moment from 'moment'
import {timeToHold} from './assets';
import { FormikProps, FormikValues } from "formik";



type combineString = string|null|undefined

// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  order: Order | undefined;
  hideKeyboard: () => void;
  deleteOrderItem: (orderItemId: number) => void;
  couponCode: string;
  setCouponCode: (text: string) => void;
  applyCouponCode: () => void;
  navigateToAddress: (addressId: number | null, orderId: number) => void;
  loading: boolean;
  handledeleteOrder: () => void;
  handleCancelOrder: () => void;
  getUpcomingOrders:any;
  isPaginationTouched:any;
  appliedFilter:string[];
  upcomingBookings:any;
  setAppliedFilter:any;
  numberOfItemPerPage:number;
  totalPage:number;
  currentPage:number;
  allFilter:string[];
  searchKey:string;
  setCurrentPage:React.Dispatch<React.SetStateAction<number>>;
  setSearcKey:React.Dispatch<React.SetStateAction<string>>;
  setApplySorting:React.Dispatch<React.SetStateAction<string>>;
  applySorting:string;
  isLoading:boolean;
  navigationToBooking:(id:string)=>void,
  pageHeading:()=>string,
  airports:any[],
  createNewProfile:(values:any)=>void,
  anchorEl:null | HTMLElement,
  setAnchorEl:React.Dispatch<React.SetStateAction<null | HTMLElement>> ,
  timer : NodeJS.Timeout | null,
  setTimer : React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>,
  isAddNewClientModalOpen: boolean
  setIsNewClientModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  moment:typeof moment,
  handleCurrentPage:(page:number)=>void,
  handleApplyFilter:()=>void,
  handleSearchChange:(event:React.ChangeEvent<HTMLInputElement>)=>void,
  handleTripType:(trip_type:string)=>string,
  handleSorting:(value: string) => void,
  getVendorStatus:(status: string) => { status: string; className: string; },
  handleMenuOpen:(event: React.MouseEvent<HTMLButtonElement>)=>void,
  handleClose:(event: React.MouseEvent<HTMLElement>)=>void,
  handleCheckboxChange:(event: React.MouseEvent<HTMLElement>, value: string) => void,
  isClientTable:boolean;
  getSearchedAirports:(query:string) => Promise<void>,
  focusedField:null|string,
  setFocusedField:React.Dispatch<React.SetStateAction<null|string>>,
  clientForm:React.MutableRefObject<FormikProps<FormikValues> | null>,
  utilFunction:unknown
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const OrderDetails: React.FC<Props> = (props) => {
  // Customizable Area Start
  const orderId: combineString = props.navigation.getParam("orderId", null);
  const backFrom: combineString = props.navigation.getParam("backFrom", null);
  let clientName:combineString = new URLSearchParams(window.location.search).get("client_name")
  const clientId : combineString = new URLSearchParams(window.location.search).get("client_id")
  const isOustanding = new URLSearchParams(window.location.search).get("outstanding")
  let sortingMethod:string = clientName?"client_name": 'departure_date'
  

  

  const getOrderCallId = useRef("");
  const deleteOrderItemCallId = useRef("");
  const applyCouponCodeCallId = useRef("");
  const deleteOrderCallId = useRef("");
  const cancelOrderCallId = useRef("");
  const token = useRef<string>("");
  const getFromAndToAiporCallId = useRef("")
  const getAirportsAPICallId = useRef("")
  const createNewProfileAPICallId = useRef("")
  const clientForm = React.useRef<FormikProps<FormikValues> | null>(null)
  

  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(true);
  const [couponCode, setCouponCode] = useState<string>("");

  const [anchorEl,setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  const [isAddNewClientModalOpen,setIsNewClientModalOpen] = React.useState(false);

  const isPaginationTouched = React.useRef(false);
  const allFilter = ['confirmed','pending', 'cancelled'];
  isOustanding && allFilter.push("outstanding")
  
  const getUpcomingBookingApiId = React.useRef("");
  const [currentPage,setCurrentPage] = React.useState(1);
  const [appliedFilter,setAppliedFilter] = React.useState(allFilter);
  const [numberOfItemPerPage] = React.useState(10);
  const [totalPage,setTotalPages] = React.useState(0);
  const [upcomingBookings,setUpcomingBookings] = React.useState<any>([]);
  const [searchKey,setSearcKey] = React.useState<string>(clientName?clientName:"");
  const bookingType = 'type=' + (window.location.pathname === '/OrderDetailsView' ?  'upcoming_bookings' : 'previous_bookings') + '&';
  const [isLoading,setIsLoading] = React.useState(false);
  const [userName,setUserName] = React.useState(clientName)
  const [applySorting,setApplySorting] = React.useState(window.location.pathname !== '/Clients' ?sortingMethod : 'account_id');
  const [airports,setAirports] = React.useState([])
  const [focusedField,setFocusedField] = React.useState<string|null>(null)
  const [sortDesc,setSortDesc] = React.useState(false)

  


  const navigationToBooking=(booking_id:string)=>{
    const path = window.location.pathname
    if(pageHeading() !== 'Clients'){
      handleNavigation(`/OrderManagement?booking_id=${booking_id}`,props,sendBlockMessage);
    } else{
      handleNavigation(`/AdminConsole2?client_id=${booking_id}`,props,sendBlockMessage);
    }
  }

  const handleCurrentPage = (page: number) => {
    isPaginationTouched.current = true;
    setCurrentPage(page);
    getUpcomingOrders(page, numberOfItemPerPage,undefined,undefined,undefined,sortDesc);
  }




  const handleApplyFilter = () => {
    getUpcomingOrders(1,undefined,undefined,undefined,undefined,sortDesc);
    setAnchorEl(null);
  }



  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearcKey(event.target.value);
    handleDebouncer(event.target.value);
  }





  const handleTripType = (trip_type: string) => {
    if (trip_type === 'one_way') {
      return 'One-way'
    } else if (trip_type === 'round_trip') {
      return 'Return'
    } else {
      return 'Multi-leg'
    }
  }

  const handleSorting = (value: string,sorting=applySorting) => {
    const applyDescendingSort = handleDescendingSort(value)
    if(value === sorting){
      setSortDesc(!sortDesc)
    }
    else{
      sortDesc && setSortDesc(!sortDesc)
    }
    
    setApplySorting(value);
    getUpcomingOrders(1, numberOfItemPerPage, appliedFilter, value,undefined,applyDescendingSort);
  }



  const handleDescendingSort = (value:string)=>{
    if(value === applySorting){
      return !sortDesc
    }
    else{
      return false
    }


  }

  const getVendorStatus = (status: string) => {
    let status_obj = {
      status: '',
      className: ''
    };
    if (status === 'vendor_pending') {
      status_obj.status = 'Vendor Pending';
      status_obj.className = 'order_detail_view_pending'
    } else if (status === 'vendor_confirmed') {
      status_obj.status = 'Vendor Confirmed';
      status_obj.className = 'order_detail_view_paid'
    } else {
      status_obj.status = 'Trip Cancelled';
      status_obj.className = 'order_detail_view_unpaid'
    }

    return status_obj;
  }


  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLInputElement).tagName !== 'INPUT' || (event.target as HTMLInputElement).type !== 'checkbox') {
      setAnchorEl(null);
    }
  };

  const handleCheckboxChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    event.stopPropagation();
    if (appliedFilter.includes(value)) {
      setAppliedFilter(appliedFilter.filter((filter: string) => filter !== value));
    } else {
      setAppliedFilter([...appliedFilter, value]);
    }
  };

  const handleDebouncer = (value = searchKey) => {
    if (timer) {
      clearTimeout(timer);
    }

    let newTimer = setTimeout(() => {
      getUpcomingOrders(1, undefined, undefined, undefined, value,sortDesc);
    }, timeToHold)

    setTimer(newTimer);
  }

  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    getUpcomingOrders()
    getAirports()
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const resToken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      debugLog("TOKEN", resToken);
      tokenHandler(resToken);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
      extractNetworkResponse(message);
        if (responseJson) {
          switch(apiRequestCallId) {
              case getOrderCallId.current:
                  getOrderCallIdRequestHandler(responseJson);
                  break;
      
              case deleteOrderItemCallId.current:
                  getOrder();
                  deleteOrderItemCallId.current = "";
                  break;
      
              case applyCouponCodeCallId.current:
                applyCouponCodeCallIdResponseHandler(responseJson)
                  break;
      
              case deleteOrderCallId.current:
                deleteOrderCallIdResponseHandler(responseJson);
                  break;
      
              case cancelOrderCallId.current:
                cancelOrderCallIdResponseHandler(responseJson)
                  break;

              case getUpcomingBookingApiId.current:
                  handleUpcomingBookingResponse(responseJson)
                  break;
              case getAirportsAPICallId.current:
                handleAirportData(responseJson.data)
                break
              case getFromAndToAiporCallId.current:
                handleAirportData(responseJson.airports)
                break;
              case createNewProfileAPICallId.current:
                handleCreateClientAPIResponse(responseJson)
                break;

                
                
      
              default:
          }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } = useBlockHelpers();

  useEffect(() => {
    if(backFrom && !isPlatformWeb()) {
      getToken();
      setLoading(true)
    }
  }, [backFrom])



  const pageHeading=()=> {
    const {location:{pathname}} = window;
    switch (pathname) {
      case '/OrderDetailsView':
        return 'Upcoming Bookings';
      case '/Clients':
        return 'Clients';
      default:
        return 'Previous Bookings';
    }
  }
  const isClientTable = pageHeading() === 'Clients';


      const getUpcomingOrders = async(page = currentPage,number_of_item=numberOfItemPerPage,filterApplied=appliedFilter,sort=applySorting,query=searchKey,applySort=false) => {
      const apiEndPoint = pageHeading() !== 'Clients' ? '/bookings' : '/clients';
      const isType = pageHeading() !== 'Clients' ? bookingType : '';
      const isOustandingFilter = isOustanding ? `&outstanding=true` : '';
      const applyDescendingSort = applySort ? "&sort_type=desc" : ""
      const otherFilters = userName?`${getFilterString(filterApplied)}&client_id=${clientId}&sort=${sort}${applyDescendingSort}&query=${query}`:`${getFilterString(filterApplied)}&sort=${sort}${applyDescendingSort}&query=${query}${isOustandingFilter}`
      
      setIsLoading(true);  
      const headers = {
         'token': await getStorageData('token')
      };
      sendNetworkRequest(
        getUpcomingBookingApiId,
        configJSON.getOrderByIdMethod,
        configJSON.getUpcomingBookingsApi + apiEndPoint + `?${isType}page_number=${page}&number_of_items_per_page=${number_of_item}&${otherFilters}`,
        headers
      );
    };

        const handleUpcomingBookingResponse=(responseJson:any)=>{
      if(responseJson.data){
        const {data:{data},total_pages} = responseJson;
        handlePaginationAfterResponse();
        setTotalPages(total_pages);
        setUpcomingBookings(data);
        getUpcomingBookingApiId.current = "";
        if(clientName){
          setSearcKey("")
          setUserName("")
        }
      }
      setIsLoading(false);
    }

    const getFilterString=(filters = appliedFilter)=>{
      let filterQuesry =  filters.map(filter => `filter[]=${encodeURIComponent(filter)}`).join('&');
      return filterQuesry;
 }

     const handlePaginationAfterResponse=()=>{
      if(!isPaginationTouched.current) {
        setCurrentPage(1);
      }
      isPaginationTouched.current = false;
    }



  const getOrderCallIdRequestHandler=(responseJson:any)=>{
    if (responseJson?.data) {
      setOrder(responseJson.data);
  }
  setLoading(false);
  getOrderCallId.current = "";
  }

  const applyCouponCodeCallIdResponseHandler=(responseJson:any)=>{
    if(responseJson.message) {
      alert(JSON.stringify(responseJson.message))
  } else if(responseJson.data?.message) {
      alert(JSON.stringify(responseJson.data.message));
      setCouponCode("");
      getOrder();
  }
  applyCouponCodeCallId.current = "";
  }


  const deleteOrderCallIdResponseHandler=(responseJson:any)=>{
    if(responseJson) {
      navigateGoBack();
  }
  deleteOrderCallId.current = "";
  }

  const cancelOrderCallIdResponseHandler=(responseJson:any)=>{
    if(responseJson) {
      getOrder();
  }
  cancelOrderCallId.current = "";
  }

  const tokenHandler=(resToken:any)=>{
    if (resToken) {
      token.current = resToken;
      getOrder();
    } else {
      alert(configJSON.loginAlertMsg);
    }
  }

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };

  const getOrder = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };
    

    sendNetworkRequest(
      getOrderCallId,
      configJSON.getOrderByIdMethod,
      configJSON.getOrderByIdEndPoint.replace(":id", orderId),
      headers
    );
  };

  const deleteOrderItem = (orderItemId: number) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    const httpBody = {
      order_items_ids: [orderItemId],
    };

    sendNetworkRequest(
      deleteOrderItemCallId,
      configJSON.deleteOrderItemMethod,
      configJSON.deleteOrderItemEndPoint.replace(":id", orderId),
      headers,
      httpBody
    );
  };

  const applyCouponCode = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    const httpBody = {
      "code": couponCode
    }

    sendNetworkRequest(
      applyCouponCodeCallId,
      configJSON.appplyCouponCodeMethod,
      configJSON.appplyCouponCodeEndpoint.replace(":id", orderId),
      headers,
      httpBody
    );
  }

  const navigateToAddress = (addressId: number | null, _orderId: number) => {
    props.navigation.navigate("SelectAddress", {
      orderId: _orderId,
      addressId: `${addressId}`,
    });
  }

  const handledeleteOrder = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    sendNetworkRequest(
      deleteOrderCallId,
      configJSON.deleteOrderMethod,
      configJSON.deleteOrderEndPoint.replace(":id", orderId),
      headers
    );
  }

  const handleCancelOrder = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    sendNetworkRequest(
      cancelOrderCallId,
      configJSON.cencelOrderMethod,
      configJSON.cencelOrderEndPoint.replace(":id", orderId),
      headers
    );
  }

  const navigateGoBack = () => {
    props.navigation.navigate("OrderManagement", {
      orderDeleted: orderId
    })
  }

  const handleAirportData=(airportData:any)=>{
    if(airportData){
      let airportManipulatedData = airportData.map((item:any)=>{
        return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id}
      })
      setAirports(airportManipulatedData)
    }
  }

  const getAirports =async () => {
    let endPoint = configJSON.popularAirportsAPIEndpoint
    const headers = {
      "Content-Type": configJSON.apiContentType,
      
    };  
    sendNetworkRequest(
      getAirportsAPICallId,
      configJSON.getAirportsMethod,
      endPoint,
      headers,
    );
  }

const createNewProfile= async(values:any)=>{
  const airport = `${values.preferredAirport.name} , ${values.preferredAirport.city} - ${values.preferredAirport.code}`
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType
    };

    const httpBody = {
      data:{
        email:values.emailId,
        profile_attributes:[
        {
        country_code:values.countryCode, 
        phone_number: values.phoneNumber,
        preferred_departure_airport: airport,
        full_name:values.fullName
        }
        ]
    }
      
    };
    sendNetworkRequest(
      createNewProfileAPICallId,
      configJSON.createProfileMethod,
      configJSON.createProfileAPIEndpoint,
      headers,
      httpBody
    );
        }

   const getSearchedAirports = async(query:string) => {
     sendNetworkRequest(
       getFromAndToAiporCallId,
       configJSON.getOrderByIdMethod,
       configJSON.getSearchAirportsEndpoint + `?query=${query}`,
     );
   };

   const handleCreateClientAPIResponse = (responseJson:{errors:{account : string}[]} | {errors : string[]})=>{
    if(responseJson.errors){
      if((responseJson as {errors:{account : string}[]}).errors[0].account){
        clientForm.current?.setFieldError("emailId",(responseJson as {errors:{account : string}[]}).errors[0].account)
      }
      else if(responseJson.errors[0] === "Profile full phone number This Phone Number is already taken"){
        clientForm.current?.setFieldError("phoneNumber","This number is already taken")
      }
      else if(responseJson.errors[0]=== "Profile full phone number Invalid or Unrecognized Phone Number"){
        clientForm.current?.setFieldError("phoneNumber","Invalid Phone Number",)
        
      }
    }
    else{
      setIsNewClientModalOpen(false)
      const messageTriger = new Message(getName(MessageEnum.AlertMessage));
      messageTriger.addData(getName(MessageEnum.AlertBodyMessage), "Client Added Successfully");
      sendBlockMessage(messageTriger);
    }
  }

  const utilFunction ={
    handleCreateClientAPIResponse
  }

  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "OrderDetailsView",
    // Customizable Area Start
    order,
    hideKeyboard,
    deleteOrderItem,
    couponCode,
    setCouponCode,
    applyCouponCode,
    navigateToAddress,
    loading,
    handledeleteOrder,
    handleCancelOrder,
    setCurrentPage,
    getUpcomingOrders,
    numberOfItemPerPage,
    isPaginationTouched,
    setApplySorting,
    appliedFilter,
    upcomingBookings,
    setAppliedFilter,
    totalPage,
    currentPage,
    allFilter,
    searchKey,
    setSearcKey,
    applySorting,
    isLoading,
    navigationToBooking,
    pageHeading,
    airports,
    createNewProfile,
    anchorEl,
    setAnchorEl,
    isAddNewClientModalOpen,
    setIsNewClientModalOpen,
    setTimer,
    timer,
    moment,
    getVendorStatus,
    handleApplyFilter,
    handleCheckboxChange,
    handleClose,
    handleCurrentPage,
    handleMenuOpen,
    handleSearchChange,
    handleSorting,
    handleTripType,
    isClientTable,
    getSearchedAirports,
    focusedField,
    setFocusedField,
    clientForm,
    utilFunction
    // Customizable Area End
  };

  return <OrderDetailsView {...viewProps} />;
};

export default OrderDetails;
