export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const visa_image = require('../assets/visa.png');
export const master_image = require('../assets/mastercard.png');
export const defaultCard = require('../assets/creditCard.png')
export const americanExpess = require('../assets/americanExpress.png');



export const customSkyCards = [
    {
    account_id:2205729,
    client:"Theressa webbbb",
    trips_remaining:9,
    aircraft_category:"Mid Jet",
    form_to:"MMAG>EGGW",
    date_created:"12/08/1994",
    expiry_date:"4/12/1978",
    csc_id:"1223450"
    },
    {
    account_id:2205729,
    client:"Darrell Steward",
    trips_remaining:9,
    aircraft_category:"Mid Jet",
    form_to:"MMAG>EGGW",
    date_created:"12/08/1994",
    expiry_date:"4/12/1978",
    csc_id:"1223450"
    },
    {
    account_id:2205729,
    client:"Floyd Miles",
    trips_remaining:9,
    aircraft_category:"Mid Jet",
    form_to:"MMAG>EGGW",
    date_created:"12/08/1994",
    expiry_date:"4/12/1978",
    csc_id:"1223450"
    }

]



export interface PaymentTextFieldType {label:string,handleChange:(field: string, e: React.ChangeEvent<HTMLInputElement>) => void,placeholderText:string,formName:string,value:NullStringNumber}
export type NullStringNumber = null | string | number
export type TouchedMessage = undefined | boolean



export interface VirtualTerminalType{
    cardDetails : CardFormType
    setActiveFieldName : (action:string)=>void,
    activeFieldName:string,
    // virtualTerminalData : VirtualTerminalDataTyoe,
    // setCardDetails:React.Dispatch<React.SetStateAction<CardFormType>>
    // chargeNewCard:(body: unknown,isNewCardUsed:boolean) => Promise<void>
    // savedCard : SavedCardStateType
    // existingCardFormikRef:FormikRefType
    // newCardFormRef:any
  }

export  interface CardFormType{
    amount : null | number,
    card_number : null | number,
    name_on_card : null | string,
    expiry : null | string,
    security_code: null | string,
    street_address : null | string,
    city : null | string,
    state : null | string,
    zip_code: null | string,
    country : null  | string,
    invoice_id : null | number,
    is_paid : boolean,
    saved_payment_card_id:string | null
  }

export  type FormikRefType = React.MutableRefObject<null>
export  type VirtualTerminalDataTyoe = null | VirtualTerminalExactDataType
export type SavedCardStateType = null | SavedCardType[]
export interface VirtualTerminalExactDataType {
    main_invoice : VirtualTerminalInvoiceType,
    incidential_invoice : VirtualTerminalInvoiceType[],
  }
 export interface SavedCardType {
    id: string
    type: string
    attributes: {
      cards: {
        card_holder_name: string
        card_number: string
        expiry_date: string
        card_type: string
        zipcode: string
        country_code: null | string
        street_address: string
        city: string
        state: string
        country: string
        country_code_string: string
      }
      primary_method: boolean
    }
  }
  export interface VirtualTerminalInvoiceType {
    id : number,
    amount_to_pay : string,
    total_amount : string,
    is_paid : boolean,
    invoice_name:string
  }

  export interface ChargeExistingCompType{
    setActiveFieldName : (action:string)=>void
    activeFieldName:string,
    savedCard : SavedCardStateType
    cardDetails : CardFormType
    // chargeNewCard:(body: unknown,isNewCardUsed:boolean) => Promise<void>
    // existingCardFormikRef:FormikRefType
  
  
  }

  export interface InitExistingCardValuesType{
    amount_pay:number | null,
      invoice_id:number | null,
      card_id:string | null
  }

  export interface CombinedTextfieldType extends PaymentTextFieldType {
    touched :undefined | boolean,
    errors : string | undefined,
    message:string | undefined

  }
