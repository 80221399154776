// Customizable Area Start
import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AccordionDetails,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { HomeOutlined, PersonOutlined,ChatBubbleOutline } from "@material-ui/icons";
import "./Navigation.css";
import { NavLink} from "react-router-dom";
import {BookingIcon, ClientsIcon, SkyCardIcon} from "./assets";
import {SidebarAccordion, MyAccordionSummary} from "../../../components/src/MuiCustomComponents"



import SidebarController, {
  Props,
} from "./NavigationMenuController";



const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '40px',
});

const SubAccordionSummary = styled(MyAccordionSummary)({
  backgroundColor : 'inherit',
  width : '115%',
  padding : '0'
});
const adminRole = 'admin';



export default class Sidebar extends SidebarController {
  constructor(props: Props) {
    super(props);

  }


        renderBooking = () => {
          const {role,isUpcomingBookingTouched} = this.state;
          return (
            <SidebarAccordion>
                <MyAccordionSummary
                  style={webStyle.accordionStyle}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <StyledListItemIcon>
                      <BookingIcon/>
                  </StyledListItemIcon>
                  <ListItemText primary='Bookings' className='sidebar_item_text' />
                </MyAccordionSummary>
                <AccordionDetails
                  className='nav_accord_details'
                  style={webStyle.inside_accord}
                >
                  <List>
                    {role === adminRole && 
                    <NavLink to={'/AdvancedSearch'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' isActive={()=>this.handleCreateBooking()} id='avinode_integration'>
                    <ListItem className='nav_list_item' button>
                        <ListItemText primary='Create Bookings' />
                      </ListItem>
                    </NavLink>    
                      }
                        <NavLink to={'/OrderDetailsView'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="upcoming_bookings" isActive={()=>this.handlePreviousAndUpcomingHighlight('/OrderDetailsView',isUpcomingBookingTouched)} onClick={()=>this.handleUpcomingAndPreviousToched(true)}>
                        <ListItem className='nav_list_item' button>
                          <ListItemText primary='Upcoming Bookings' />
                        </ListItem>
                    </NavLink>

                    <NavLink to={'/PreviousBookings'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="previous_bookings" isActive={()=> this.handlePreviousAndUpcomingHighlight('/PreviousBookings',!isUpcomingBookingTouched)} onClick={()=>this.handleUpcomingAndPreviousToched(false)}>
                        <ListItem className='nav_list_item' button>
                          <ListItemText primary='Previous Bookings' />
                        </ListItem>
                    </NavLink>
                  </List>
                </AccordionDetails>
            </SidebarAccordion>
          )
      }
      
      renderGeneralSideTab = (navigateTo:string,title:string,icon:React.ReactNode,classTitle= '') => {
          return (
            <NavLink to={navigateTo} activeClassName={`sidebar_active_element ${classTitle}`} className='sidebar_unactive_element' id="navbar" exact isActive={()=>this.handleHighlightClientsTab(navigateTo)}>
            <ListItem button className='sidebar_menu_item'>
                
                <StyledListItemIcon>
                 {icon}
                </StyledListItemIcon>
                <ListItemText primary={title} className='sidebar_item_text' />

            </ListItem>
            </NavLink>
          )
      }

   

     

    

      renderHelpCentreWithMenu=()=>{
        return  <SidebarAccordion>
        <SubAccordionSummary
          
          style={webStyle.accordionStyle}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <ListItemText primary='Help Centre' />
        </SubAccordionSummary>
        <AccordionDetails
          
          className='nav_accord_details'
          style={{...webStyle.inside_accord,transition : 'none'}}
        >
          <List>
          <NavLink to={'/Contactus'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' >
              <ListItem className='nav_list_item' button>
                <ListItemText primary='Contact Us' />
              </ListItem>
          </NavLink>
          <NavLink to={'/FAQ'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="faq_handle" isActive={()=>this.handleFAQs()} >
              <ListItem className='nav_list_item' button>
                <ListItemText primary='FAQs' />
              </ListItem>
          </NavLink>
              <NavLink to={'/PrivacyPolicy'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="privacy-policy" isActive={()=>this.handlePrivacyEdit()}>
                  <ListItem className='nav_list_item' button>
                    <ListItemText primary='Privacy Policy' className="sidebar_list_extrem_width"/>  
                  </ListItem>
              </NavLink>
              <NavLink to={'/TermsConditions'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="terms-conditions" isActive={()=>this.handleTermsConditionsEdit()}>
              <ListItem className='nav_list_item' button>
                <ListItemText primary='Terms' />  
              </ListItem>
              </NavLink>

          
          </List>
        </AccordionDetails>
      </SidebarAccordion>
    }

    renderSkyCardWithMenu=(skyCard:string)=>{
      return  <SidebarAccordion>
      <SubAccordionSummary
        style={webStyle.skyCardAccordian}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <ListItemText primary={`${skyCard} Sky Card`} />
      </SubAccordionSummary>
      <AccordionDetails
        className='nav_accord_details'
        style={{...webStyle.inside_accord,transition : 'none'}}
      >
        <List>
        <NavLink to={this.handleCardsRoute(skyCard).viewRoute} activeClassName="sidebar_active_element" className='sidebar_unactive_element'  >
            <ListItem className='nav_list_item' button>
              <ListItemText primary="View" />
            </ListItem>
        </NavLink>
        <NavLink to={this.handleCardsRoute(skyCard).manageRoute} activeClassName="sidebar_active_element" className='sidebar_unactive_element' >
            <ListItem className='nav_list_item' button>
              <ListItemText primary="Manage" />
            </ListItem>
        </NavLink>        
        </List>
      </AccordionDetails>
    </SidebarAccordion>
  }

      
      renderAccount = () => {
          return (
            <SidebarAccordion style={{ boxShadow: "none" }}>
                <MyAccordionSummary
                  style={webStyle.accordionStyle}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  
                >
                  <StyledListItemIcon>
                      <PersonOutlined color={"primary"} />
                  </StyledListItemIcon>
                  <ListItemText primary='Account' className='sidebar_item_text' />
                </MyAccordionSummary>
                <AccordionDetails
                
                  className='nav_accord_details'
                  style={webStyle.inside_accord}
                >
                  <List>

                  <NavLink to={'/userProfileBasicBlock'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' >
                      <ListItem className='nav_list_item' button >
                        <ListItemText primary='Profile' />
                      </ListItem>
                  </NavLink>
                      <ListItem className='nav_list_item sidebar_list_nav_list_item_menu'>
                      {this.renderHelpCentreWithMenu()}

                      </ListItem>
                      <NavLink to={'/EmailAccountLoginBlock'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' onClick={this.handleLogout} id="sidebar_logout_button">
                      <ListItem className='nav_list_item' button>
                        <ListItemText primary='Log Out' />
                      </ListItem>
                      </NavLink>
                      <ListItem className='nav_list_item' button>
                        <ListItemText
                            primary='Delete Account'
                            className='sidebar_delete_acc'
                        />
                      </ListItem>
                  </List>
                </AccordionDetails>
            </SidebarAccordion>
          )
      }
      renderSkyCard = () => {
        const skyCards = ["Debit","25 Hour", "Custom"]
        return (
          <SidebarAccordion style={{ boxShadow: "none" }}>
              <MyAccordionSummary
                style={webStyle.accordionStyle}
                aria-controls='panel1a-content'
                id='panel1a-header'
                
              >
                <StyledListItemIcon>
                    <SkyCardIcon/>
                </StyledListItemIcon>
                <ListItemText primary="Sky Cards" className='sidebar_item_text' />
              </MyAccordionSummary>
              <AccordionDetails
              
                className='nav_accord_details'
                style={webStyle.inside_accord}
              >
                <List>
                  {skyCards.map((skycard:string)=>(
                    <ListItem className='nav_list_item sidebar_list_nav_list_item_menu'>
                    {this.renderSkyCardWithMenu(skycard)}
                    
                    </ListItem>
                  ))}
                    
                
                </List>
              </AccordionDetails>
          </SidebarAccordion>
        )
    }

      renderSuperAdmin = () => {
        return (
          <SidebarAccordion style={{ boxShadow: "none" }}>
              <MyAccordionSummary
                style={webStyle.accordionStyle}
                aria-controls='panel1a-content'
                id='panel1a-header'
                
              >
                <StyledListItemIcon>
                    <ClientsIcon  />
                </StyledListItemIcon>
                <ListItemText primary='Super Admin' className='sidebar_item_text' />
              </MyAccordionSummary>
              <AccordionDetails
              
                className='nav_accord_details'
                style={webStyle.inside_accord}
              >
                <List>

                <NavLink to={'/AdminList'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="adminlist" isActive={()=>this.handleAdminListHighlight()}>
                      <ListItem className='nav_list_item' button>
                        <ListItemText primary='Admin Accounts' />
                      </ListItem>
                </NavLink>

                <NavLink to={'/SystemSettings'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' >
                    <ListItem className='nav_list_item' button >
                      <ListItemText primary='System Settings' />
                    </ListItem>
                </NavLink>

                <NavLink to={'/JobTitles'} activeClassName="sidebar_active_element" className='sidebar_unactive_element' id="sidebar_logout_button">
                      <ListItem className='nav_list_item' button>
                        <ListItemText primary='Job Titles' />
                      </ListItem>
                </NavLink>

                </List>

               
              </AccordionDetails>
          </SidebarAccordion>
        )
    }
 

  render() {
    const {role} = this.state
    return (
        <Box  className="sidebar_parent_container">
          <List component="nav" aria-label="main mailbox folders" className="sidebar_list_item_cont">
              {this.renderGeneralSideTab('/','Dashboard',<HomeOutlined color={"primary"} />)}
              {this.renderBooking()}
              {this.renderSkyCard()}
              {this.renderGeneralSideTab('/Clients','Clients',<ClientsIcon  />,'custom_imported_icon')}
              {this.renderGeneralSideTab('/Chat','Inbox',<ChatBubbleOutline color={"primary"}/>,'custom_imported_icon')}
              {this.renderAccount()}  
              {this.renderSuperAdmin()}
          </List>
        </Box>
    );
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#000000"
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  accordionStyle: {
    margin:"0",
  },
  inside_accord:{
    color:"white"
  },
  skyCardAccordian:{
    margin:0,
    gap:"1.5rem",
  }
};
// Customizable Area End