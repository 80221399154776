import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment"
import { handleNavigation } from "../../../components/src/Navigator";
import lodashGet from 'lodash/get';
import { FormikProps } from "formik";


export interface AirportCategoryType{
  label: string
  value: string
}

export interface PopularAiportType {
country: {
  code: string
  name: string
}
name: string
province: {
  code: string
  name: string
}
id: string
href: string
city: string
iata: string
faa: string
type: string
icao: string
}

interface HandleAircraftResponseType{
categories : AirportCategoryType[]
}
interface HandleAiportResponseType{
data : PopularAiportType[]
}

interface HandeSearhAirportType { 
  airports : PopularAiportType[]
}

export interface OneWayFormTypes{
  fromLocation :  null | PopularAiportType,
  toLocation :  null | PopularAiportType,
  departure : null | Date,
  passengers : number,
  fromAirportResults : PopularAiportType[],
  toAirportResults : PopularAiportType[],
  isCalenderOpen:boolean,
  isTimePickerOpen:boolean
}


export type FormikMethodType = 'oneWyFormikMethods' | 'roundTripFormikMethods' | 'multiLegFormikMethods'

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  passengers:number;
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: unknown[];
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  selectedTab:number;
  aircraft:[] | AirportCategoryType[];
  selectedCategories:string,
  airports:PopularAiportType[] | [];
  filledData:unknown;
  selectedAircraftList:string | null,
  selectedFormikMethod: FormikMethodType,
  selectedMultiLegIndex:null | number,
  isAirportloading:boolean,
  oneWayStateValue:null | unknown,
  roundTripStateValue:null | unknown,
  multiLegFormValues:null | unknown,
  tomorrowDate:number | Date,
  focusIndex: null | number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  oneWyFormikMethods:FormikProps<OneWayFormTypes> | null = null;
  roundTripFormikMethods:FormikProps<OneWayFormTypes> | null = null;
  multiLegFormikMethods:FormikProps<OneWayFormTypes> | null = null;
  advancedsearchApiCallId: string = '';
  searchApiId:string = '';
  airCraftCategoriesApiId:string = '';
  airportsApiId:string = '';
  popularAiportApiId:string = '';
  delayTimer: NodeJS.Timeout | string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      passengers: 1,

      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      selectedTab:0,
      aircraft:[],
      selectedCategories:"",
      airports:[],
      filledData :{},
      selectedAircraftList:null,
      selectedFormikMethod:'oneWyFormikMethods',
      selectedMultiLegIndex:null,
      isAirportloading:false,
      oneWayStateValue:null,
      roundTripStateValue:null,
      multiLegFormValues:null,
      tomorrowDate: new Date().setDate(new Date().getDate() + 1),
      focusIndex:null
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.airCraftCategoriesApi()
    this.getPopularAirportsApi()
    const {history : {location:{state : {data}}}} = this.props as Props & {history:{location:{state : {data:unknown}}}};
    this.setState({filledData:data})
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const form_data = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const {formData} = form_data;
      this.handleFormInitialValues(formData);
} 

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      runEngine.debugLog("API Message Recived", message);


      if (responseJson && responseJson.accounts) {
        if (typeof responseJson.accounts === "string") {
          alert(responseJson.accounts);
        } else {
          this.setState({ advancedsearchList: responseJson.accounts.data });
        }
      } 
      
      if (responseJson) {
        switch (apiRequestCallId) {
          case this.airCraftCategoriesApiId:
            this.handleAirCraft(responseJson)
            break;
          case this.airportsApiId:
            this.handleAirPorts(responseJson)
            break;
          case this.popularAiportApiId:
            this.handlePopularAiport(responseJson);
          break;  
          default:
            break;
      }
  }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  
  SearchAirpots = (value:string) => {
    clearInterval(this.delayTimer);
      this.delayTimer = setInterval(() => {
        clearInterval(this.delayTimer); 
        this.handleApiCallForAircarfts(value);
      }, 500);
  }
   handleAirCraft = (responseJson:HandleAircraftResponseType) => {
    this.setState({aircraft:responseJson.categories})
  }

  handleAirPorts = (responseJson:HandeSearhAirportType) => {  
    const {selectedAircraftList, selectedFormikMethod} = this.state;
    let airports = this.handleAirportData(responseJson.airports);
      (this[selectedFormikMethod] as FormikProps<unknown>).setFieldValue(selectedAircraftList as string, airports);
      this.setState({isAirportloading:false})
  }

  handleAirportData=(airportData:PopularAiportType[])=>{
    let airportManipulatedData = airportData.map((item)=>{
      return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id}
    })
    return airportManipulatedData;
  }

  handlePopularAiport=(responseJson:HandleAiportResponseType)=>{
    if(responseJson.data){
      let popularAiports = this.handleAirportData(responseJson.data);
      this.setState({airports:popularAiports as unknown as PopularAiportType[]});
    }
  }




  setModal = (item: unknown) => {
    this.setState({
      activeId: (item as {id:number}).id,
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  airCraftCategoriesApi = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.airCraftCategoriesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_cfavinodeintegration2/aircrafts/aircraft_categories"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAirportsApi = (newValue:string) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.airportsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfavinodeintegration2/airports?query=${newValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPopularAirportsApi = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.popularAiportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.popularSeachApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };





  formatDate = (date:string) => {
    return date ? moment(date).format('MMM D, YYYY  |  ddd - h:mm A') : '';
  };

  formToggler=(toggleTo: 0 | 1 | 2)=>{
    this.setState({selectedTab:toggleTo});
  }
  
  
  handleFormikMethodAndList=  (method:FormikMethodType,list:string,selectedIndex:number,changedValue:string)=>{
   this.setState({
    selectedFormikMethod:method,
    selectedAircraftList:list,
    selectedMultiLegIndex:selectedIndex,
    isAirportloading:true
  })
    this.SearchAirpots(changedValue);
  }


  handleApiCallForAircarfts=(changedValue:string)=>{
    const minimumCharacterLength = 2;
    const {airports, selectedAircraftList, selectedFormikMethod} = this.state
    if(changedValue.length > minimumCharacterLength){
      this.getAirportsApi(changedValue)
    }else{
      this.setState({isAirportloading:false});
      this[selectedFormikMethod]?.setFieldValue(selectedAircraftList as string, airports);
    }
  }

  handleSubmitFunction=(values:OneWayFormTypes)=>{
    const {selectedTab} = this.state;
    const formData = {
      formType : selectedTab,
      data: values
    }
    handleNavigation("/cfavinodeintegration2",this.props,this.send,{formData});
  }


  handleFormInitialValues=(formData:{formType:number,data:unknown})=>{
    const {formType,data} = formData;
    this.setState({selectedTab:formType});
    if(formType === 0){
      this.setState({oneWayStateValue:data});
    }else if(formType === 1){
      this.setState({roundTripStateValue:data});
    }else{
      this.setState({multiLegFormValues:data});
    }


  }

  conditionHandler=(condition:boolean | unknown,output1:string | unknown,output2:OneWayFormTypes | string | unknown)=>{
    let output = condition ? output1 : output2;
    return output;
  }

  handleMultiLegDependency=(formikProps:FormikProps<OneWayFormTypes>,selectedIndex:number,newValue:unknown)=>{
    const {selectedTab} = this.state;
    if(selectedTab !== 2){
      return;
    }
    const {values} = formikProps;
    const nextFromLocationIndex = selectedIndex + 1;  
    const maxLength = (values as unknown as {trips:OneWayFormTypes[]}).trips.length - 1;
    const currentToLocationKey = `trips[${selectedIndex}].toAirportResults`;
    const nextFromLocationKey = `trips[${nextFromLocationIndex}].fromLocation`;
    const nextFromLocationKeyValue = lodashGet(values,nextFromLocationKey);
    const nextFromLocationOptionsKey = `trips[${nextFromLocationIndex}].fromAirportResults`;
    const currentOptionsToLocation = lodashGet(values,currentToLocationKey);
    if(maxLength > selectedIndex && nextFromLocationKeyValue === null){
      formikProps.setFieldValue(nextFromLocationKey, newValue);  
      formikProps.setFieldValue(nextFromLocationOptionsKey, currentOptionsToLocation);  
    }
  }

  scrollToBottom=()=> {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  }

  handleFocusIndex=(focusIndex:number | null)=>{
    this.setState({focusIndex});
  }

  // Customizable Area End
}
