import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible,customSkyCards, SavedCardStateType,CardFormType} from "./assets";
import React from "react"
import { getStorageData } from "../../../framework/src/Utilities";
const cardInitDetails = {
  amount : null,
  card_number : null,
  name_on_card : null,
  expiry : null,
  security_code : null,
  street_address : null,
  city : null,
  state : null,
  zip_code : null,
  country : null,
  invoice_id : null,
  is_paid : true,
  saved_payment_card_id:null
}
const savedCard = {
  id: "475",
  type: "debit card",
  attributes: {
    cards: {
      card_holder_name: "card name",
      card_number: "3744",
      expiry_date: "03/36",
      card_type: "MasterCard",
      zipcode: "3758374",
      country_code: "ald",
      street_address: "street test",
      city: "city test",
      state: "state test",
      country: "country test",
      country_code_string: "alskd"
    },
    primary_method: true
  }

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  skyCardData:any[],
  sortBy:string,
  loading:boolean,
  cardAction:string,
  anchor:null|HTMLElement,
  appliedFilter:string[],
  savedCard:SavedCardStateType,
  activeFieldName:string,
  cardDetails:CardFormType,
  addSkyCardModal:boolean;
  totalPage:number,
  query:string,
  sortType:string,
  currentPage:number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfcustomskycardsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newCardFormRef=React.createRef<HTMLInputElement>()
  geSkyCardsAPICallId:string = ""
  timer : NodeJS.Timeout | null = null;
  page = window.location.pathname
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      skyCardData:[],
      sortBy:"date_created",
      query:"",
      loading:false,
      cardAction:"view",
      anchor:null,
      appliedFilter:["Piston","Turbo Prop","Semi jet","Large jet","Midsize jet","Super lange jet","Light jet","Entry Level Jet","Super Light Jet","Midsize Jet","Super Midsize Jet","Heavy Jet","Ultra Long Run","VIP Airliner","Airliner","Expired","Not Expired"],
      currentPage:1,
      savedCard:[savedCard],
      activeFieldName:"",
      cardDetails:cardInitDetails,
      addSkyCardModal:false,
      totalPage:0,
      sortType:"asc",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      if(apiRequestCallId === this.geSkyCardsAPICallId){
        const {total_pages,Custom_forms:{data}} = responseJson
        
        this.setState({loading:false,skyCardData:data,totalPage:total_pages})
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.getSkyCards()

  }

  

  closeFilters = ()=>{
    this.setState({anchor:null})
  }

  openFilters = (element:HTMLElement)=>{
    this.setState({anchor:element})
  }

  setActiveFieldName = (fieldName:string)=>{
    this.setState({activeFieldName:fieldName})
  }

  handleSkycardModal = (action:boolean)=>{
    this.setState({addSkyCardModal:action})
    
  }

  handleCurrentPage=(currentPage:number)=>{
    this.setState({currentPage});
    this.getSkyCards(undefined,undefined,currentPage);
}

handleQueryDebounce=(event:React.ChangeEvent<HTMLInputElement>)=>{
  const timeToHold = 1500;
  const value = event.target.value;
  if(this.timer){
      clearTimeout(this.timer);
  }
  this.timer = setTimeout(()=>{
      this.handleQuery(value);
  },timeToHold)
}

handleQuery=(query:string)=>{
  
  this.setState({query:query,currentPage:1});
  this.getSkyCards(undefined,query,1);
}


handleSorting = (value: string,sorting=this.state.sortType) => {
  const sortMethod = this.handleSortMethod(value)
  this.setState({sortBy:value,sortType:sortMethod,currentPage:1})
  
  this.getSkyCards(value,undefined,1,undefined,sortMethod)
}

handleSortMethod = (value:string)=>{
  const {sortBy,sortType} = this.state 

  if(value === sortBy){
    if(sortType === "desc"){
      return "asc"
    }
    else{
      return "desc"
    }
  }
  else{
    return "asc"
  }

}



  getSkyCards=async(sorted_column=this.state.sortBy,query=this.state.query,currentPage=this.state.currentPage,number_of_items_per_page=10,sort_type=this.state.sortType,filters=this.state.appliedFilter)=>{
    this.setState({loading:true});
    const filter = this.getFilterString(filters)
    const params = `page_number=${currentPage}&number_of_items_per_page=${number_of_items_per_page}&sort=${sorted_column}&sort_type=${sort_type}&${filter}&query=${query}`;
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.geSkyCardsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSkyCardsAPIEndpoint + `?` + params
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePageName = ()=>{
    const {location:{pathname}} = window
    if(pathname === "/Viewcustomskycards"){
      return "Custom Sky Cards"
    }
    else if(pathname === "/Managecustomskycards"){
      return "Custom Sky Cards List"
    }
    else{
      return "Sky Cards Purchases"
    }
  }

  handleCheckboxChange = (event: React.MouseEvent<HTMLElement>, value: string)=>{
    const { appliedFilter } = this.state
    event.stopPropagation();
    if(appliedFilter.includes(value)){
      const updatedFilters = appliedFilter.filter((filter: string) => filter !== value)
      this.setState({ appliedFilter: updatedFilters })
    }
    else{
      this.setState({ appliedFilter: [...appliedFilter, value] })
    }
  }

  handleApplyFilter = ()=>{
    this.getSkyCards(undefined, undefined, 1);
    this.setState({ anchor: null });
  }

  getFilterString = (filters = this.state.appliedFilter)=>{
    let filterQuesry = filters.map(filter => `filter[]=${encodeURIComponent(filter)}`).join('&');
    return filterQuesry;
  }
  // Customizable Area End
}
