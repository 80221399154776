import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {AddLegtTypeButton, CSSPropertiesMap, CustomTypography, GeneralParentBox } from '../../../components/src/MuiCustomComponents';
import {SkyCardDebitCardIcon , SkyCardIcon } from '../../../components/src/assets';
import {theme as colors } from '../../../components/src/utils';
// Customizable Area End

import Cf25hourskycardController, {
  Props,
  configJSON,
} from "./Cf25hourskycardController";

export default class Cf25hourskycard extends Cf25hourskycardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderHeader=()=>{
    return <Box className="dash_flex_row">
        <CustomTypography style={{color:'white'}}>25 Hour Sky Cards</CustomTypography>
        <AddLegtTypeButton style={{ fontSize: '0.9rem',marginRight:"0.4rem",textTransform:'none'}}>+ Add New 25 hr Sky Card</AddLegtTypeButton>
    </Box>
  }

  renderCards=()=>{
    const cardArray = [1,1,11,1,1];
    return <GeneralParentBox style={{padding:'1rem', marginTop :"1rem"}} className="card_parent">
        <Grid container spacing={2}>
          {cardArray.map((_card_el)=> this.renderCard())}
        </Grid>
    </GeneralParentBox>
  }

  renderCard=()=>{
    return <Grid item lg={4} md={6} sm={12} xl={3}>
      <Box>
        <Grid container style={webStyle.cardParent}>
          <Grid xs={9} style={webStyle.cardContent}>{this.renderCardContent()}</Grid>
          <Grid xs={3} style={{marginLeft:'-3px',...webStyle.cardImg25}}><SkyCardIcon/></Grid>
        </Grid>
      </Box>
    </Grid>
  }

  renderCardContent=()=>{
    return <Box style={webStyle.cardContentStyle}>
        <Box style={{...webStyle.viewButtonParent,columnGap:'1rem'}}>
          <SkyCardDebitCardIcon/>
          <Box style={webStyle.cardHeading}>25 Hr Sky Card</Box>
        </Box>

        <Box className="booking_section" style={{rowGap:"0.4rem"}}>
            <Box fontSize={'1.2rem'}>Light Jet</Box>
            <Box style={webStyle.cardContentBottom}>
              <Box style={{...webStyle.viewButtonParent,color:colors.GOLD_COLOR}}>
                <Box color={'#94A3B8'} fontWeight={'bold'}>Price:</Box>
                <Box color={'#FFB833'} fontWeight={'bold'}>$5215.00</Box>
              </Box>

              <Box style={{...webStyle.viewButtonParent,marginTop:'-4px',color:colors.GOLD_COLOR}}>
                <Box component={'span'} style={{textDecoration:"underline",cursor:'pointer'}}>View</Box>
                <Box component={'span'}>{'>'}</Box>
              </Box>
            </Box>
        </Box>
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        {this.renderHeader()}
        {this.renderCards()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle : CSSPropertiesMap = {
    cardHeading:{
      fontSize:"1.4rem"
    },
    cardParent:{
      overflow:'hidden',
      borderRadius:"10px"
    },
    cardContent:{
      backgroundColor:'#3E4247'
    },
    cardImg25:{
      background: 'linear-gradient(234.48deg, #064BB4 7.51%, #70D4FF 96.16%)',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      flexDirection:'column',
      borderRadius:'0px 10px 10px 0px'
    },
    cardContentStyle:{
      height:"8.5rem",
      padding:'1rem',
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between'
    },
    cardContentBottom:{
      display:'flex',
      alignItems:'center',
      justifyContent : 'space-between'
    },
    viewButtonParent:{
      display:'flex',
      columnGap: "5px",
      alignItems : 'center'
    }

};
// Customizable Area End
