interface RoleObj{
    is_admin: boolean;
    is_super_admin: boolean;
}

const theme = {
    PRIMARY_COLOR:"#FFFFFF",
    GRAY_TEXT_COLOR:"#808080",
    GRAY_COLOR_FORM:"#94A3B8",
    GOLD_COLOR: '#FFD27E',
    SAVE_DARK_COLOUR:'#FFB833'
}

const handleRole=(roleObj:RoleObj)=>{
    const {is_admin,is_super_admin} = roleObj;
    if(is_admin) return 'admin';
    else if(is_super_admin)  return 'super_admin';
    return 'user';
}


export {theme, handleRole}