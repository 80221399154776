import React from "react";

// Customizable Area Start
import { Box, IconButton, InputAdornment } from "@material-ui/core";
import "./ForgotPassword.css";
import {
  CustomFormTextField,
  CustomLabel,
  FormSubmitButton,
  ModalHeading,
  RenderTimerAndResend,
  StyledOtpContainer,
  otpcontainerStyle,
  otpinputStyle
} from "../../../components/src/MuiCustomComponents";
import OtpInput from "react-otp-input";
import { otpValidationSchema, renderPasswordErrorBox } from "../../../components/src/Validations";
import { CheckCircleOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikProps, FormikTouched } from "formik";
import { theme as colors } from "../../../components/src/utils";


type handleChange = (eventOrPath: React.ChangeEvent<HTMLInputElement> | string) => void;
type loginInitialValuesType = { email: string };
const loginInitialValues = { email: "" };
const emailValidationSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Invalid email format*") 
    .required("Email is required*") 
});

type password_initial_values_type = {  
  new_password:string,
  confirm_password:string
}

const passwordValidationSchema = Yup.object().shape({
  new_password: Yup.string()
      .required('New Password is required*')
      .notOneOf([Yup.ref('old_password'), null], 'New Password must be different from Old Password*')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be strong*'
      ),
  confirm_password: Yup.string()
      .required('Confirm New Password is required*')
      .oneOf([Yup.ref('new_password'), null], 'Confirm New Password must match New Password*')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be strong*'
      )
});

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderEmail = (
    errors: FormikErrors<loginInitialValuesType>,
    touched: FormikTouched<loginInitialValuesType>,
    handleChange: handleChange
  ) => {
    return (
      <Box>
        <CustomLabel>Email</CustomLabel>
        <CustomFormTextField
          autoComplete="off"
          className={touched.email && errors.email ? "login_border_error" : ""}
          placeholder="Your Email"
          size="medium"
          fullWidth
          name="email"
          onChange={handleChange}
          password={true}
          isFocusedTypeInput={this.state.focusedField === "email"}
          onFocus={()=>this.handleFocusField("email")}
          onBlur={()=>this.handleFocusField(null)}
          id="forgot_password_test_email"
        />
        {touched.email && (
          <Box className="login_valid_error">{errors.email}</Box>
        )}
      </Box>
    );
  };


  renderOtp=()=>{
    const otpInitialValues = {otpValue:''}
    return (<Box className="forp_otp_form">
          
          <StyledOtpContainer style={{padding:"10px"}}>
            <Formik
              initialValues={otpInitialValues}
              onSubmit={(values) => {
                this.handleOtpConfirmation(values.otpValue)
              }}
              innerRef={(formikProps) => {
                this.otpFormik = formikProps as FormikProps<unknown>;
              }}
              validationSchema={otpValidationSchema}
              validateOnChange
              validateOnBlur
            >
              {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue
              }) => (
                  <>
                    <ModalHeading>Enter Code</ModalHeading>
                    <Box className='profile_gen_modal_margin'>Check your email to find your 
                    <br/>
                    verification code and enter below
                    </Box>
                    <Box>
                    <OtpInput
                        value={values.otpValue}
                        onChange={(otpFigure:string) => {setFieldValue('otpValue',otpFigure)}}
                        numInputs={4}
                        inputStyle={{
                         ...otpinputStyle,
                          border: `1px solid ${touched.otpValue && errors.otpValue ? "red" : "#6C7278"}`,
                        }}
                        focusStyle={{outline:'none', border : '2px solid #FFDA94',borderRadius:'8px'}}
                        containerStyle={otpcontainerStyle}
                    />
                    {touched.otpValue && errors.otpValue && <Box className="register_valid_error" position="absolute">
                  {errors.otpValue}
              </Box>}
                  </Box>

                  <Box className="profile_gen_modal_submit_cont" mb={4}>
                      <FormSubmitButton fullWidth 
                       onClick={()=>{handleSubmit()}}
                       id="verifyOtpButton"
                       className={
                        this.isCreateAccountGrey(values.otpValue) ? "" : "regsiter_account_grey"
                      }>Verify</FormSubmitButton>
                      </Box>       
                  </>
              )}
            </Formik>
            <RenderTimerAndResend seconds={this.state.seconds} submit={()=>{this.handleResendOtp()}}/>
            </StyledOtpContainer>
          
    </Box>)
  }

  renderChangePasswordModal = () => {
    const { passwordVisiblity,isErrorPasswordContainerVisible } = this.state;
    const password_initial_values = {
      new_password:'',
      confirm_password:''
    }
    return (
        <Formik
          initialValues={password_initial_values}
          onSubmit={(values) => {
            this.handleChangePassword(values.new_password,values.confirm_password)
          }}
          innerRef={(formikProps) => {
            this.updatePasswordFormik = formikProps as FormikProps<unknown>
          }}
          validationSchema={passwordValidationSchema}
          validateOnChange
          validateOnBlur
        >
          {(formikProps) => (
            <>
              <ModalHeading style={{fontSize:"2.3rem"}}>New Password</ModalHeading>
              <Box className="profile_gen_modal_margin" style={{margin:"1.5rem 0rem",marginBottom:"1.6rem"}}>
                Your new password must be different from your previous password
              </Box>
              <Box display={'flex'} flexDirection={'column'} style={{rowGap:"1rem"}}>               
                {this.renderPasswordTextFieldWithLabel(
                  "New",
                  passwordVisiblity.New,
                  formikProps,
                  'new_password'
                )}
                {this.renderPasswordTextFieldWithLabel(
                  "Confirm New",
                  passwordVisiblity.Confirm,
                  formikProps,
                  'confirm_password'
                )}

                {isErrorPasswordContainerVisible && renderPasswordErrorBox(formikProps.values.new_password,this.state.isErrorPasswordContainerVisible)}


                <Box className="profile_gen_modal_submit_cont" marginTop={'1.5rem'}>
                  <FormSubmitButton id="resetPassword" fullWidth className="profile_test_submit" onClick={()=>formikProps.handleSubmit()}>
                    Reset Now
                  </FormSubmitButton>
                </Box>
              </Box>
              </>
            
          )}
        </Formik>

    );
  };

  renderPasswordTextFieldWithLabel=(label:string,isPasswordVisible:boolean,formikProps:FormikProps<password_initial_values_type>,keyName:keyof password_initial_values_type)=>{
    const {values,handleChange,touched,errors} = formikProps;
    const {focusedField} = this.state
    return <Box>
        <CustomLabel>{label} Password</CustomLabel>
                <CustomFormTextField
                autoComplete="off"
                placeholder={`Enter ${label} Password`}
                size="medium"
                fullWidth
                value={values[keyName]}
                name={keyName}
                onChange={handleChange}
                id={keyName}
                type={!isPasswordVisible ? 'password' : 'text'}
                isFocusedTypeInput={focusedField === label}
                password={true}
                onFocus={()=> {
                  this.handleErrorContainerVisible(true)
                  this.handleFocusField(label)
                }}
                onBlur={()=>this.handleFocusField(null)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {this.handleVisibilityPassword(label,!isPasswordVisible);}}                                                  
                        className="password_button_type"
                        data-test-id="showPassword"
                      >
                        {!isPasswordVisible ? (
                          <VisibilityOff style={{ color: colors.GRAY_COLOR_FORM }} />
                        ) : (
                          <Visibility style={{ color: colors.GRAY_COLOR_FORM }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                />    
                 {touched[keyName] && errors[keyName] && <Box className="login_valid_error" id="profile_error_properties">
                  {errors[keyName]}
            </Box>}

    </Box>
  }

  renderPasswordSuccess = ()=>{
    return(
      <StyledOtpContainer  display={"flex"} alignItems={"center"} justifyContent={"center"} height={"25rem"}>
        <Box display={"flex"} flexDirection={"column"} alignSelf={"center"} justifyContent={"center"} alignItems={"center"} mt={2}>
        <CheckCircleOutline style={{fontSize:"83px",color:"#FFD27E"}}></CheckCircleOutline>
        <Box mt={2} fontSize={"1.6rem"} fontWeight={700}>Password Reset Successfully</Box>
        <Box fontSize={"1rem"} fontWeight={500} mt={1}>Please login to get started</Box>
        <FormSubmitButton style={{marginTop:"2.5rem"}} id="logInButton" fullWidth onClick={this.handleNavigationToLogIn}>Back to Log in</FormSubmitButton>
      </Box>
      </StyledOtpContainer>
    )
  }   

  

  renderEmailForm = () => {
    return (
      <>
        <Box className={"login_form_head"} width={"100%"}>
          Forgot Password?
        </Box>
        <Box className={"forp_gen_modal_margin"}>
          Enter your email address to reset your <br /> password
        </Box>

        <Formik
          initialValues={loginInitialValues}
          innerRef={(formikProps)=>{
            this.emailIdFormik = formikProps as FormikProps<unknown>
          }}
          onSubmit={values => {
            this.handleSendOtpToEmail(values.email)
            this.handleEmail(values.email)
          }}
          validationSchema={emailValidationSchema}
          validateOnChange
          validateOnBlur
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={"login_form_container"}>
                {this.renderEmail(errors, touched, handleChange)}
                <FormSubmitButton
                style={{ marginTop: "3rem" }}
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
                className={
                  this.isCreateAccountGrey(values.email) ? "" : "regsiter_account_grey"
                }
                id="login_test_button"
                data-test-id="verifyButton"
              >
               Continue
              </FormSubmitButton>
              </Box>
            </form>
          )}
        </Formik>
      </>
    );
  };

  


  // Customizable Area End
  render() {
    const {formType} = this.state
    return (
      // Customizable Area Start
      
      <Box className={"login_form_parent"} id="forgot_password_container">
          {formType === "email" && this.renderEmailForm()}
          {formType === "otp" && this.renderOtp()}
          {formType==="password" && this.renderChangePasswordModal()}
          {formType === "passwordModal" && this.renderPasswordSuccess()}
      </Box>
      // Customizable Area End
    );
  }
}
