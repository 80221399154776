import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  styled,
  MenuItem,
  FormControl,
  InputAdornment,
  Avatar,
  Button,
  IconButton,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import * as Yup from 'yup';
import "./UserProfileBasicBlock.css";
import { SearchOutlined, KeyboardArrowDown, Edit, BorderColorOutlined, RoomOutlined, PhotoCameraOutlined, ChevronRight,ArrowBack, Visibility , VisibilityOff} from "@material-ui/icons";
import {CustomFormSelect,CustomDepartueAiportAutoCompplete,CustomStyledPopper, CustomFormTextField, CustomLabel,UserProfileLabel,HorizantalRow,FormSubmitButton, MuiModal, StyledModalContent,ModalHeading, Footer, GeneralParentBox,GoldishSimpleButton, AddLegtTypeButton, CustomFormSelectNew, MuiResuableStatusModalBody,StatusModal, RenderTimerAndResend, otpcontainerStyle, otpinputStyle} from "../../../components/src/MuiCustomComponents";
import {renderPasswordErrorBox,emailValidationSchema,otpValidationSchema,commissionKeySchema} from '../../../components/src/Validations';
import { theme as colors } from "../../../components/src/utils";
import {EmailSvg, DeleteSvg} from "./assets";
import {v4} from "uuid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ICountry, IState, ICity} from 'country-state-city';
import { Formik, FormikProps } from 'formik';
import {ProfileDataType,AdminProfileDataType,setFieldErrorType,setFieldValueType,password_initial_values_type,JobRoleType, userProfileFormDataType, PhotoType} from "./UserProfileBasicBlockController.web"
import OtpInput from 'react-otp-input';
import {AutocompleteRenderInputParams} from '@material-ui/lab'


type InitiailValue =  {
    optedState : IState | null,
    optedCity:ICity | null,
    optedCountry:ICountry | null,
}
type formikCobmineTypeType = ProfileDataType & InitiailValue;
type CombineLocationType = ICountry | IState | ICity

const adminSelectProps = {
  getContentAnchorEl: null,
  className: "profile_ditry_menu",
  anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
  },
  transformOrigin: {
      vertical: "top",
      horizontal: "left",
  },
}

const passwordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required('Old Password is required*'),
  new_password: Yup.string()
      .required('New Password is required*')
      .notOneOf([Yup.ref('old_password'), null], 'New Password must be different from Old Password*')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be strong*'
      ),
  confirm_password: Yup.string()
      .required('Confirm New Password is required*')
      .oneOf([Yup.ref('new_password'), null], 'Confirm New Password must match New Password*')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be strong*'
      )
});

const DefaultMenuItem = ()=><MenuItem style={{ display: "none" }}>Please Specify</MenuItem>
const profileValidation = Yup.object().shape({
    phone_number: Yup.string(),
      street_address: Yup.string().nullable(),
      zip_code: Yup.string()
    .nullable() 
    .test(
      'validZipCode',
      'Invalid zip code format',
      (value) => {
        if (!value) {
          return true; 
        }
        const alphanumericValue = value.replace(/[-\s]/g, '');
        return /^[0-9A-Za-z]{4,9}$/.test(alphanumericValue);
      }
    ),
});

const fullNameValidation = Yup.object().shape({
    full_name: Yup.string()
      .trim() 
      .matches(/^[a-zA-Z\s'-]+$/, "Invalid Full Name*")
      .min(1, 'Name cannot be empty*')
      .required('Full name is required*')
      .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
        return value && value.trim().length > 0;
      }),
  });


  const adminProfileSchema = Yup.object().shape({
    commission: commissionKeySchema,
    full_name: fullNameValidation.fields.full_name,
    account_type: Yup
    .string()
    .notOneOf(['none'], 'Account Type is required*')
    .required('Account type is required*')
  });

  const createNewAdminSchema = Yup.object().shape({
    email: emailValidationSchema.fields.email,
    ...adminProfileSchema.fields
  })
  
  

const GridContainer = styled(Grid)({
    marginTop: '-3rem',  
  });

  const GridItem = styled(Grid)({
    paddingTop: '0px',  
    paddingBottom: '0px',
  });
// Customizable Area End

import UserProfileBasicBlockController, {
  Props,
} from "./UserProfileBasicBlockController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserProfileBasicBlock extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

            renderCameraContainer=()=>{
                return <Box className="profile_pic_camera">
                    <PhotoCameraOutlined style={{fontSize:'2rem'}}/>
                    <Box>Add Picture</Box>
                </Box>
            }

            renderAvatar = (image: string | Blob) => {
                return <Avatar style={{height:'100%',width:'100%'}} src={this.handleImageBlob(image)} className="profile_avatar"/>;
            }

            renderProfileContainer = (formikProps: FormikProps<formikCobmineTypeType>) => {
                const {profileData:{account_id,full_name},photo} = this.state;
                return (
                    <Formik
                    initialValues={{photo}}
                    onSubmit={(values)=>{
                       this.updateUserProfileDetails((values as {photo : string | Blob}),'profilePicture');
                    }}   
                    validateOnChange
                    validateOnBlur
                    enableReinitialize
                    >
                        {({
                            handleSubmit,
                            errors,
                            setFieldValue,
                            values,
                            setFieldError
                        })=>( <Box className="profile_profile_pic_cont">
                      <Box>
                      {this.renderProfileModal(handleSubmit,setFieldValue,setFieldError,this.deleteUserProfilePicture)}
                         <Box className="profie_profile_pic">
                            <Box className="profile_pic_pen_icon">
                                  <Box className="profile_pen_icon_cont">
                                     <BorderColorOutlined style={{ fontSize: "15px" }} onClick={()=>this.handleProfilePictureModalClose(true)} className="profile_pen_test"/>
                                  </Box>
                            </Box>
                            {!values.photo
                               ? this.renderCameraContainer()
                               : this.renderAvatar(values.photo)}
                         </Box>
                        <Box className="login_valid_error" id="profile_error_properties">
                      {errors.photo}
                    </Box>                        
                      </Box>
                      <Box className="profie_details_cont">
                        {this.renderFullNameEditModal()}
                         <UserProfileLabel>Full Name</UserProfileLabel>
                         <Box className="profile_pic_container">
                            <Box className="profile_name_style">{full_name}</Box>
                            <BorderColorOutlined
                               onClick = {()=>this.handleEditFullNameModalClose(true)} 
                               className="profile_edit_icon"
                               style={{ fontSize: "20px",cursor : "pointer" }}
                            />
                         </Box>
                         <Box className="profile_user_id">Account ID: {account_id}</Box>
                      </Box>
                   </Box>)}
                  
                   </Formik>
                )
            }

            renderHorizantalRow = () => {
                return (
                    <Box className="profile_horizantal_row">
                        <HorizantalRow width={"100%"} />
                    </Box>
                )
            }

            renderStateCountryCityOption = (option: CombineLocationType) => {
                return (
                    <Box className={`register_airport_option`}>
                        <Box color="#636E72">
                            <RoomOutlined />
                        </Box>
                        <Box>{option.name}</Box>
                    </Box>
                )
            }

            renderRow1 = (email:string | null) => {
                return (
                    <>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Email</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                value={email}
                                placeholder="Your Email"
                                size="medium"
                                fullWidth
                                name="email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Edit className="profile_edit_icon" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </GridItem>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Password</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                type="password"
                                value={"********"}
                                placeholder="Your Email"
                                size="medium"
                                fullWidth
                                name="email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Edit className="profile_edit_icon" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </GridItem>
                    </>
                )
            }

            renderRow2 = (formikProps:FormikProps<formikCobmineTypeType>) => {
                const {values, touched, errors} = formikProps;
                return (
                    <>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Phone Number</CustomLabel>
                            <PhoneInput
                                onChange={(value, data:{dialCode:string}, event, formattedValue)=>{
                                    const countryCode = data.dialCode; 
                                    const phoneNumber = countryCode && value.substring(countryCode.length);
                                    formikProps.setFieldValue("phone_number",value);
                                    formikProps.setFieldValue("phoneNumber",phoneNumber);
                                    formikProps.setFieldValue("countryCode",countryCode);
                                  }}
                                inputStyle={touched.phone_number && errors.phone_number ? {border:"1px solid #F87171"}: {}}
                                inputClass={`regsiter_phone_input`}
                                dropdownClass="register_drop_down_class"
                                containerClass="regsiter_contaitner_class"
                                country="us"
                                value={values.phone_number}
                                placeholder="Your Phone Number"
                            />
                            {touched.phone_number && errors.phone_number && <Box className="login_valid_error" id="profile_error_properties">
                      {errors.phone_number}
                    </Box>}
                        </GridItem>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Preffered Departure Airport</CustomLabel>
                            <CustomDepartueAiportAutoCompplete
                                bgColor={"#0D1015"}
                                onChange={(_event: Event, newValue: string) => {}}
                                PopperComponent={CustomStyledPopper}
                                ListboxProps={{ id: "regsiter_option_style" }}
                                getOptionLabel={(option: {name : string}) => option.name}
                                options={[]}
                                values={values.preferred_departure_airport}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomFormTextField
                                        {...params}
                                        placeholder="Search Departure Location"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box className="profile_hover_icons">
                                                    <KeyboardArrowDown />
                                                </Box>
                                            ),
                                            startAdornment: (
                                                <Box>
                                                    <SearchOutlined />
                                                </Box>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </GridItem>
                    </>
                )
            }

            renderRow3 = (formikProps:FormikProps<formikCobmineTypeType>) => {
                const { cities } = this.state;
                const {values, handleChange} = formikProps;
                return (
                    <>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Street Address</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                placeholder="Enter street address"
                                size="medium"
                                fullWidth
                                name="street_address"
                                value={values.street_address}
                                onChange={handleChange}
                            />
                        </GridItem>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>City</CustomLabel>
                            <CustomDepartueAiportAutoCompplete
                                bgColor={"#0D1015"}
                                onChange={(_event: Event, newValue: ICity) => {
                                    formikProps.setFieldValue('optedCity',newValue);
                                }}
                                id = 'profile_select_city'
                                value={values.optedCity}
                                PopperComponent={(props: AutocompleteRenderInputParams) => (
                                    <CustomStyledPopper
                                        {...props}
                                        zIndex={1}
                                        marginTop="1px"
                                    />
                                )}
                                getOptionLabel={(option: {name : string}) => option.name}
                                ListboxProps={{ id: "regsiter_option_style" }}
                                options={cities}
                                renderOption={(
                                    option: ICity | IState | ICountry,
                                    { selected }: { selected: boolean },
                                ) => <>{this.renderStateCountryCityOption(option)}</>}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomFormTextField
                                        {...params}
                                        placeholder="Search city"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box className="profile_hover_icons">
                                                    <KeyboardArrowDown />
                                                </Box>
                                            ),
                                            startAdornment: (
                                                <Box>
                                                    <SearchOutlined />
                                                </Box>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </GridItem>
                    </>
                )
            }

            renderRow4 = (formikProps:FormikProps<formikCobmineTypeType>) => {
                const { states } = this.state;
                const {values, errors, touched} = formikProps
                return (
                    <>
                        <GridItem style={{ zIndex: 1 }} item md={6} sm={12}>
                            <CustomLabel>State</CustomLabel>
                            <CustomDepartueAiportAutoCompplete
                                bgColor="#0D1015"
                                onChange={(_event: Event, newValue: IState) => {
                                    formikProps.setFieldValue('optedState',newValue);
                                    formikProps.setFieldValue('optedCity',null);
                                    this.handleStateChange(newValue);
                                }}
                                id = 'profile_select_state'
                                value={values.optedState}
                                PopperComponent={(props: AutocompleteRenderInputParams) => (
                                    <CustomStyledPopper
                                        {...props}
                                        zIndex={1}
                                        marginTop="1px"
                                    />
                                )}
                                ListboxProps={{ id: "regsiter_option_style" }}
                                getOptionLabel={(option: IState) => option.name}
                                options={states}
                                renderOption={(
                                    option: ICity | IState | ICountry,
                                    { selected }: { selected: boolean },
                                ) => <>{this.renderStateCountryCityOption(option)}</>}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomFormTextField
                                        {...params}
                                        placeholder="Search State"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box className="profile_hover_icons">
                                                    <KeyboardArrowDown />
                                                </Box>
                                            ),
                                            startAdornment: (
                                                <Box>
                                                    <SearchOutlined />
                                                </Box>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </GridItem>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Zip Code</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                placeholder="Enter zip code"
                                size="medium"
                                fullWidth
                                name="zip_code"
                                className={touched.zip_code && errors.zip_code ? "register_border_error" : ""}
                                onChange={formikProps.handleChange}
                                value={values.zip_code}
                            />
                            {touched.zip_code && errors.zip_code && <Box className="login_valid_error" id="profile_error_properties">
                      {errors.zip_code}
                    </Box>}
                        </GridItem>
                    </>
                )
            }

            renderRow5 = (formikProps: FormikProps<formikCobmineTypeType>) => {
                const { dietryData, countries } = this.state
                const {values} = formikProps
                return (
                    <>
                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Main Country of Residence</CustomLabel>
                            <CustomDepartueAiportAutoCompplete
                                bgColor={"#0D1015"}
                                onChange={(_event: Event, newValue: ICountry) => {
                                    formikProps.setFieldValue('optedCountry',newValue);
                                    formikProps.setFieldValue('optedCity',null);
                                    formikProps.setFieldValue('optedState',null);
                                    this.handleCountryChange(newValue);
                                }}
                                id = 'profile_country_selector'
                                PopperComponent={(props: AutocompleteRenderInputParams) => (
                                    <CustomStyledPopper
                                        {...props}
                                        zIndex={1}
                                        marginTop="1px"
                                    />
                                )}
                                value={values.optedCountry}
                                ListboxProps={{ id: "regsiter_option_style" }}
                                getOptionLabel={(option: {name : string}) => option.name}
                                options={countries}
                                renderOption={(
                                    option: ICity | IState | ICountry,
                                    { selected }: { selected: boolean },
                                ) => <>{this.renderStateCountryCityOption(option)}</>}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomFormTextField
                                        {...params}
                                        placeholder="Search Country"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box className="profile_hover_icons">
                                                    <KeyboardArrowDown />
                                                </Box>
                                            ),
                                            startAdornment: (
                                                <Box>
                                                    <SearchOutlined />
                                                </Box>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </GridItem>

                        <GridItem item md={6} sm={12}>
                            <CustomLabel>Dietery Requirements</CustomLabel>
                            <FormControl placeholder="Please Verify" fullWidth>
                                <CustomFormSelect
                                    labelId="demo-simple-select-label"
                                    variant="outlined"
                                    className="profile_customSelect"
                                    name="dietary_requirements"
                                    id="dietary_requirements"
                                    value={values.dietary_requirements || 'Please Specify'}    
                                    onChange={formikProps.handleChange}
                                    inputProps={{
                                        IconComponent: KeyboardArrowDown, 
                                    }}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        className: "profile_ditry_menu",
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "left",
                                        },
                                        transformOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    <MenuItem
                                        style={{ display: "none" }}
                                        value={"Please Specify"}
                                    >
                                        Please Specify
                                    </MenuItem>
                                    {dietryData.map((dietryElem: string) => (
                                        <MenuItem key={v4()} value={dietryElem}>
                                            {dietryElem}
                                        </MenuItem>
                                    ))}
                                </CustomFormSelect>
                            </FormControl>
                        </GridItem>
                    </>
                )
            }

            renderForm = (formikProps: FormikProps<formikCobmineTypeType>) => {
                return (
                    <Box>                  
                                    <GridContainer container spacing={5}>
                                        {this.renderRow1(formikProps.values.email)}
                                        {this.renderRow2(formikProps)}
                                        {this.renderRow3(formikProps)}
                                        {this.renderRow4(formikProps)}
                                        {this.renderRow5(formikProps)}
                                    </GridContainer>
                    </Box>
                )
            }

            renderSubmitAndCancel=(formikProps: FormikProps<formikCobmineTypeType>)=>{
                return <Box className="profile_submission">
                <FormSubmitButton onClick={()=>{formikProps.handleReset();this.handleResetForm();}} style={{color:"white",background:"#3E4247"}}  fullWidth>Cancel</FormSubmitButton>
                <FormSubmitButton onClick={()=>formikProps.handleSubmit()} fullWidth className="profile_test_submit">Save Changes</FormSubmitButton>
                </Box>
            }

            renderProfileModal = (handleSuccess:()=>void,setFieldValue:setFieldValueType,setFieldError :setFieldErrorType,deleteProfilePic:()=>void) => {
                return (
                  <MuiModal
                    open={this.state.isEditProfileModalVisible}
                    onClose={() => {this.handleProfilePictureModalClose(false)}}
                  >
                    <StyledModalContent>
                            <ModalHeading>Edit Profile Picture</ModalHeading>
                            <Box className="profile_pic_edit_buttons_cont">
                                      
                            <Button fullWidth className="profile_pic_edit_buttons" onClick={()=>{this.inputFileRef.current?.click()}}>Upload 
                            
                                <input
                                     type="file"
                                     id="file-input"
                                     ref={this.inputFileRef}
                                     accept=".png, .jpg, .jpeg"
                                     style={{ display: "none" }}
                                     onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                     ) => {
                                        if (
                                           event.target.files &&
                                           event.target.files.length > 0
                                        ) {
                                            const file = event?.target?.files[0];
                                            const maxSize = 5 * 1024 * 1024;
                                            const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
                                            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                                                setFieldValue("photo", file);
                                                handleSuccess();
                                            } else {
                                                setFieldError("photo","Please add valid image");
                                            }
                                        }
                                     
                                     }}
                                  />
                            </Button>
                            <Button fullWidth className="profile_pic_edit_buttons" onClick={deleteProfilePic}>Remove photo</Button>
                            </Box>
                            <FormSubmitButton onClick={()=>this.handleProfilePictureModalClose(false)} style={{color:"white",background:"#3E4247"}}  fullWidth>Cancel</FormSubmitButton>
                    </StyledModalContent>
                  </MuiModal>
                )
            }

            renderFullNameEditModal = ()=>{
                const {profileData:{full_name}} = this.state;
                return <MuiModal
                    open = {this.state.isEditFullNameModalVisible}
                    onClose={() => {this.handleEditFullNameModalClose(false)}}
                >
                      <StyledModalContent>
                           <ModalHeading>Change Your Name</ModalHeading>
                           <Box className='profile_gen_modal_margin'>Enter Your Full Name</Box>
                           <Box>        
                                <Formik
                                    initialValues={{full_name}}
                                    onSubmit={(values) => {
                                        this.updateUserProfileDetails((values as {full_name : string}),'full_name');
                                    }}
                                    validationSchema={fullNameValidation}
                                    validateOnChange
                                    validateOnBlur
                                    enableReinitialize 
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        values,
                                        handleChange,
                                        touched
                                    }) => (<>
                                        <CustomLabel>Full Name</CustomLabel>
                                        <CustomFormTextField
                                        autoComplete="off"
                                        placeholder="Enter Full Name"
                                        size="medium"
                                        fullWidth
                                        name="full_name"
                                        value={values.full_name}
                                        onChange={handleChange}
                                        id="profile_test_full_name"
                                        className={touched.full_name && errors.full_name ? "register_border_error" : ""}
                                        />
                                        {touched.full_name && errors.full_name && <Box className="register_valid_error" position="absolute">
                                            {errors.full_name}
                                        </Box>}
                                        <Box className="profile_gen_modal_submit_cont">
                                        <FormSubmitButton style={{color:"white",background:"#3E4247"}}  fullWidth onClick={()=>this.handleEditFullNameModalClose(false)}>Cancel</FormSubmitButton>
                                        <FormSubmitButton fullWidth className="profile_test_submit" onClick={()=>handleSubmit()}>Save Changes</FormSubmitButton>
                                        </Box>
                                    </>)}
                                </Formik>       
                                
                           </Box>
                      </StyledModalContent>  
                </MuiModal>
            }

            renderFooter=(formikProps: FormikProps<formikCobmineTypeType>)=>{
                return <Footer>
                    {this.renderSubmitAndCancel(formikProps)}
                </Footer>
            }

            renderUserProfile = () => {
                const initialValues = {
                  optedState: this.state.selectedState,
                  optedCity: this.state.selectedCity,
                  optedCountry: this.state.selectedCountry,
                  ...this.state.profileData,
                }
              
                return (
                  <Box className='profile_main_container'>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => {
                        this.updateUserProfileDetails(values as userProfileFormDataType, "profileDetails")
                      }}
                      validationSchema={profileValidation}
                      validateOnChange
                      validateOnBlur
                      enableReinitialize
                      innerRef={(formikProps) => {
                        this.formikForm = formikProps as FormikProps<unknown>
                      }}
                    >
                      {(props: FormikProps<formikCobmineTypeType>) => (
                        <form onSubmit={props.handleSubmit}>
                          {this.renderProfileContainer(props)}
                          {this.renderHorizantalRow()}
                          {this.renderForm(props)}
                          {this.renderFooter(props)}
                        </form>
                      )}
                    </Formik>
                  </Box>
                )
              }


              renderAdminProfileParent=()=>{
                return <Box className="booking_section admin_section_parent">
                    {this.renderProfileHeading()}
                    {this.renderAdminProfile()}
                    {this.renderAdminFooter()}
                    {this.renderEditEmailModal()}
                    {this.renderChangePasswordModal()}
                    {this.renderMuiModal()}
                    {this.renderOtp()}
                    {this.renderDeleteAccountConfirmationModal()}
                </Box>
              }

              renderMuiModal=()=>{
                const {statusModalContent:{heading,subHeading}} = this.state;
                return <MuiModal
                open={this.state.isStatusModalVisible}
              >
                <StatusModal className="status_update_modal">
                <MuiResuableStatusModalBody
                  heading = {heading}
                  subHeading = {subHeading}
                >
                  <FormSubmitButton onClick={()=>this.handleStatusVisible(false)} fullWidth className="okay_button">Okay</FormSubmitButton>
                </MuiResuableStatusModalBody>
                              
                </StatusModal>
              </MuiModal>
              }

              renderDeleteAccountConfirmationModal=()=>{
                return <MuiModal
                open={this.state.isDeleteProfileModalVisible}
              >
                <StatusModal className="status_update_modal_delete">
                <MuiResuableStatusModalBody
                  heading = {'Delete Account'}
                  subHeading = {'Are you sure? This action is permanent.'}
                  iconCont = {()=>this.renderDeleteIconContainer()}
                >
                   <Box style={{display:"flex",justifyContent:"space-between",width:"30rem",columnGap:"1rem"}}>
                         <AddLegtTypeButton className = 'yes_button' style={{width:'50rem',color:'#F87171',border:'1px solid #F87171'}} fullWidth onClick={this.deleteAdminAccount}>Yes</AddLegtTypeButton>
                           <FormSubmitButton style={{width:'50rem'}} fullWidth  className="no_button" onClick={()=>this.handleDeleteAccountModalVisible(false)}>No</FormSubmitButton>
                         </Box>
                </MuiResuableStatusModalBody>
                              
                </StatusModal>
              </MuiModal>
              }

              renderDeleteIconContainer=()=>{
                return <Box className="modal_check_icon_cont" component={'span'} style={{padding:'0.7rem',top:'-18%'}}>
                  <DeleteSvg/>
                </Box>
              }

              renderProfileHeading=()=>{
                const {isEditAdminModeOpen,isCreateNewAdmin,isAdminProfileUpdateRoute} = this.state;
                const mainViewHead = isAdminProfileUpdateRoute ? <><ArrowBack className = 'cf_avinod_arrow_search' onClick={this.handleNavigateToAdminList}/> Admin Information</> : 'Profile';
                const editSubHead = isAdminProfileUpdateRoute ? 'Edit Admin Information' : 'Edit Personal Information'
                const editHead = !isCreateNewAdmin ?  editSubHead : 'Create New Admin';
                const heading  = isEditAdminModeOpen ? <><ArrowBack className = 'cf_avinod_arrow_search' onClick={this.handleCancel}/> {editHead}</> : mainViewHead
                return <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>{heading}</GoldishSimpleButton>
              }

              renderAdminProfile=()=>{
                const {isEditAdminModeOpen} = this.state;
                return <GeneralParentBox style={{marginBottom:'7rem',padding:'1rem'}}>
                   {!isEditAdminModeOpen ? <Grid container justifyContent="space-between">{this.renderViewAdminProfile()}</Grid> : this.renderEditAdminProfile()}
                </GeneralParentBox>
              }

              renderViewAdminProfile=()=>{
                return <>
                    <Grid item lg={4}  md={12} style={{padding:'1rem'}}>{this.renderImageAndEmail()}</Grid>
                    <Grid item lg={8}  md={12} style={{padding:'1rem'}}>{this.renderPersonalInfo()}</Grid>
                </>
              }

              renderEditAdminProfile = () => {
                const {adminData, isCreateNewAdmin} = this.state;
                const createNewAdminInitialValue = isCreateNewAdmin ? {email:''}:{}
                const newSchema = isCreateNewAdmin ? createNewAdminSchema : adminProfileSchema;
                return (
                  <Formik
                    initialValues={{...adminData,...createNewAdminInitialValue}}
                    validationSchema={newSchema}
                    onSubmit={(values) => {
                      this.updateAdminProfileDetails(values);
                    }}
                    innerRef={(formikProps) => {
                      this.adminFormik = formikProps as FormikProps<unknown>
                    }}
                    validateOnChange
                    validateOnBlur
                    enableReinitialize
                  >
                    {(formikProps) => (
                      <Grid container justifyContent='space-between'>
                        {this.renderProfileModal(()=>this.handleProfilePictureModalClose(false),formikProps.setFieldValue,formikProps.setFieldError,this.deleteAdminProfilePicture)}
                        <Grid item lg={2} md={12}>
                          <Box className='profile_center_iamge_edit profile_center_iamge_edit_image_box'>
                          {this.renderProfilePictureEdit(formikProps.values.photo)}
                          { !isCreateNewAdmin && this.renderAccountId()}
                          </Box>
                        </Grid>
                        <Grid item lg={10} md={12}>
                          {this.renderEditAdminForm(formikProps)}
                        </Grid>
                      </Grid>
                    )}
                  </Formik>
                )
              }
              

              renderEditAdminForm = (formikProps:FormikProps<AdminProfileDataType>) => {
                const { focusedLabel,roles,jobRolesData, isCreateNewAdmin } = this.state;
                const {values:{full_name,commission,account_type,job_id},handleChange,touched,errors} = formikProps;
                return (
                  <Box
                    className='booking_section edit_system_setting_parent edit_admin_form'
                    style={{ rowGap: "0rem"}}
                  >

                    {isCreateNewAdmin &&   <Box style={{borderBottom:'1px solid #3E4247',padding:'2rem 1.5rem'}}>
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "email"}
                        isError={touched.email && errors.email}
                      >
                        Email Address
                      </CustomLabel>
                      <CustomFormTextField
                        isFocusedTypeInput={true}
                        isErrorTouch = {touched.email && errors.email}
                        variant='outlined'
                        fullWidth
                        id='email'
                        name='email'
                        className={`admin_full_name`}
                        value={formikProps.values.email}
                        placeholder={"Enter Email Address"}
                        onFocus={() => {
                          this.handleActiveFocusedLabelProfie("email")
                        }}
                        onBlur={() => {
                          this.handleActiveFocusedLabelProfie(null)
                        }}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                       {touched.email && errors.email && <Box className="register_valid_error" position="absolute">
                            {errors.email}
                        </Box>}
                    </Box>}

                    <Box style={{padding:'1.5rem'}}>
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "full_name"}
                        isError={touched.full_name && errors.full_name}
                      >
                        Full Name
                      </CustomLabel>
                      <CustomFormTextField
                        isFocusedTypeInput={true}
                        isErrorTouch = {touched.full_name && errors.full_name}
                        variant='outlined'
                        fullWidth
                        id='full_name'
                        name='full_name'
                        className={`admin_full_name`}
                        value={full_name}
                        placeholder={"Enter Full Name"}
                        onFocus={() => {
                          this.handleActiveFocusedLabelProfie("full_name")
                        }}
                        onBlur={() => {
                          this.handleActiveFocusedLabelProfie(null)
                        }}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                       {touched.full_name && errors.full_name && <Box className="register_valid_error" position="absolute">
                            {errors.full_name}
                        </Box>}
                    </Box>
                    <Box style={{padding:'1.5rem'}}>
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused = {focusedLabel ===  'job_title'}
                      >
                        Job Title
                      </CustomLabel>
                      <FormControl
                          className="mui_select_unkownn-1"
                          fullWidth 
                       >
                        <CustomFormSelectNew
                          labelId='demo-simple-select-label'
                          onFocus={() => {
                            this.handleActiveFocusedLabelProfie("job_title")
                          }}
                          onBlur={() => {
                            this.handleActiveFocusedLabelProfie(null)
                          }}
                          variant='outlined'
                          className='admin_job_title'
                          inputProps={{
                            IconComponent: KeyboardArrowDown,
                          }}
                          value={job_id}
                          name='job_id'
                          onChange={handleChange}
                          fullWidth
                          MenuProps={{...adminSelectProps,
                            style:{marginTop:'5px',zIndex:6},
                            className: "advance_search_picker job_title_picker",
                          }}
                          style={{color : this.conditionHandler(job_id === 'none','grey',''),backgroundColor:'black'}}
                        >
                          {
                          (jobRolesData as JobRoleType[]).map(({attributes:{job_title},id}:JobRoleType)=>{
                            return <MenuItem value={id}>
                              <Box style={{maxWidth:'65rem',overflow:'hidden',textOverflow:"ellipses",whiteSpace:'nowrap'}}>{job_title}</Box>
                              </MenuItem>
                          })}
                          <MenuItem value={'none'} style={{display:'none'}}>Choose Job Title</MenuItem>
                        </CustomFormSelectNew>
                      </FormControl>
                    </Box>
                    <Box style={{padding:'1.5rem'}}>
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "account_type"}
                      >
                        Account Type
                      </CustomLabel>
                      <FormControl 
                       className="mui_select_unkownn"
                       fullWidth
                       >
                        <CustomFormSelectNew
                          style={{color : this.conditionHandler(account_type === 'none','grey',''),backgroundColor:"black"}}
                          onFocus={() => {
                            this.handleActiveFocusedLabelProfie("account_type")
                          }}
                          onBlur={() => {
                            this.handleActiveFocusedLabelProfie(null)
                          }}
                          variant='outlined'
                          className='admin_account_type'
                          value={account_type}
                          inputProps={{
                            IconComponent: KeyboardArrowDown, 
                          }}
                          fullWidth
                          id={'account_type'}
                          name={'account_type'}
                          MenuProps={{...adminSelectProps,
                            className: "advance_search_picker",
                            style:{marginTop:'5px'},
                          }}
                          onChange={handleChange}
                        >
                          <DefaultMenuItem/>
                          {roles.map((role)=><MenuItem value={role.roleValue} key={role.roleValue}>{role.role}</MenuItem>)}
                          <MenuItem value={'none'} style={{display:'none'}}>Choose Account Type</MenuItem>
                        </CustomFormSelectNew>
                      </FormControl>
                      {touched.account_type && errors.account_type && <Box className="register_valid_error" position="absolute">
                            {errors.account_type}
                        </Box>}
                    </Box>
                    <Box style={{padding:'1.5rem'}}>
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "commission"}
                        isError={touched.commission && errors.commission}
                      >
                        Commission (%)
                      </CustomLabel>
                      <CustomFormTextField
                        isErrorTouch = {touched.commission && errors.commission}
                        isFocusedTypeInput={true}
                        value={commission}
                        variant='outlined'
                        fullWidth
                        id='commission'
                        className='admin_commission'
                        placeholder={"Enter Commission"}
                        onChange={handleChange}
                        onFocus={() => {
                          this.handleActiveFocusedLabelProfie("commission")
                        }}
                        onBlur={() => {
                          this.handleActiveFocusedLabelProfie(null)
                        }}
                        autoComplete='off'
                      />
                        {touched.commission && errors.commission && <Box className="register_valid_error" position="absolute">
                            {errors.commission}
                        </Box>}
                    </Box>
                  </Box>
                )
              }
              
              
              renderImageAndEmail=()=>{
                const {adminData:{account_id,photo}} = this.state;
                return <Box className="admin_profile_avatar_parent">
                            <Box className="profie_profile_pic" height={'12.5rem'} width={'12.5rem'}><Avatar style={{height:'100%',width:'100%'}} src={photo as string} className="profile_avatar"/></Box>
                            <Box className="profile_admin_key">Account ID:</Box>
                            <Box fontSize={'1.4rem'}>{account_id}</Box>
                            {this.renderAdminEmail()}
                </Box>
              }

              renderAdminEmail=()=>{
                const {adminData:{email}} = this.state;

                return <Box className="admin_profile_email" onClick={()=>this.handleEditEmailModalOpen(true)}>
                    <Box className="profile_mail_parent">
                        <Box className="profile_mail_box"><EmailSvg/></Box>
                        <Box className="profiel_email_parent">
                            <Box color={'#CBD5E1'}>Update Email</Box>
                            <Box fontSize={'1.2rem'}>{email}</Box>
                        </Box>
                    </Box>
                    <ChevronRight/>
                </Box>
              }

              renderPersonalInfo=()=>{
                const {adminData:{full_name,account_type,job_title,commission}} = this.state;
                const account_info = account_type === 'admin' ? 'Admin' : 'Super Admin'
                return <Box className="profile_admin_info_box" >
                    <Box fontSize={'1.8rem'} fontWeight={'500'}>Personal Info.</Box>
                    {this.renderAdminEachRowInfo('Full Name',full_name)}
                    <HorizantalRow/>
                    {this.renderAdminEachRowInfo('Job Title',job_title)}
                    <HorizantalRow/>
                    {this.renderAdminEachRowInfo('Account Type',account_info,'#34D399')}
                    <HorizantalRow/>
                    {this.renderAdminEachRowInfo('Your Commission (%)',commission + '%','#F59E0B')}
                </Box>
              }


              renderAdminEachRowInfo=(info:string,infoValue:null | string,color='string')=>{
                const value = infoValue || '- - -';
                return <Box className="profile_admin_row">
                    <Box>{info}</Box>
                    <Box color={color} style={{width:'65%', textAlign:'right', wordBreak: 'break-all', textTransform:'capitalize'}}>{value}</Box>
                </Box>
              }

              renderAdminFooter=()=>{
                const {isEditAdminModeOpen} = this.state;
                return <Footer>
                    { !isEditAdminModeOpen ?this.renderUpdateAndEditMailButtonParent() : this.renderAdminCancelAndSaveChanges()}
                </Footer>
            }

            renderAdminCancelAndSaveChanges=()=>{
                return <Box className="profile_submission">
                <FormSubmitButton style={{color:"white",background:"#3E4247"}} className="admin_cancel_changes"  fullWidth onClick={()=>this.handleCancel()}>Cancel</FormSubmitButton>
                <FormSubmitButton fullWidth className="profile_test_submit" onClick={this.handleSaveAdminProfileChanges}>Save</FormSubmitButton>
                </Box>
            }


            renderUpdateAndEditMailButtonParent=()=>{
                return <Box className="profile_submission" style={{ width : this.isAdminProfileBoolean() ? '40%' : '60%'}}>
                {!this.isAdminProfileBoolean() && <AddLegtTypeButton className = 'delete_account' style={{width:'50rem',color:'#F87171',border:'1px solid #F87171',backgroundColor:'#1A1C1E'}} fullWidth onClick={()=>this.handleDeleteAccountModalVisible(true)}>Delete Account</AddLegtTypeButton>}
                <AddLegtTypeButton className = 'update_password_button' onClick={()=>this.handleChangePasswordModalOpen(true)} style={{width:'50rem',backgroundColor:'#1A1C1E'}} fullWidth>Update Password</AddLegtTypeButton>
                <FormSubmitButton style={{width:'50rem'}} fullWidth  className="edit_profile_admin" onClick={()=>this.handleEditAdminProfileMode(true)}>Edit Profile</FormSubmitButton>
            </Box>
            }

            
            renderEditEmailModal = ()=>{
                return <MuiModal
                    open = {this.state.isEditEmailModalOpen}
                    onClose={() => {this.handleEditEmailModalOpen(false)}}
                >
                  <Formik
                    initialValues={{email:''}}
                    innerRef={(formikProps)=>{
                      this.emailFormik = formikProps as FormikProps<unknown>
                    }}
                    onSubmit={(values)=>{
                      this.setChangeEmail(values);
                      this.updateEmail(values);
                    }}
                    validationSchema={emailValidationSchema}
                    validateOnChange
                    validateOnBlur

                  >
                    {({handleChange,touched,errors,handleSubmit})=>(
                      <StyledModalContent>
                           <ModalHeading>New Email</ModalHeading>
                           <Box className='profile_gen_modal_margin'>Enter your new email address</Box>
                           <Box>        

                                <CustomLabel>Email</CustomLabel>
                                <CustomFormTextField
                                autoComplete="off"
                                placeholder="Enter New Email"
                                size="medium"
                                fullWidth
                                name = {'email'}
                                id = {'email'}
                                onChange={handleChange} 

                                />            
                                <Box className="profile_verification_text">
                                  {touched.email && errors.email ? <Box className="login_valid_error">
                                      {errors.email}
                                  </Box>: <Box>
                                      A verification code will be sent to the new email address
                                    </Box>}
                                </Box>
                                <Box className="profile_gen_modal_submit_cont">
                                <FormSubmitButton fullWidth className="profile_test_submit email_continue" onClick={()=>{handleSubmit()}}>Continue</FormSubmitButton>
                                </Box>                     
                           </Box>
                      </StyledModalContent>  
                    )}

                  </Formik>
                </MuiModal>
            }

            renderProfilePictureEdit=(photo:PhotoType)=>{
                return <Box className="profie_profile_pic profile_pen_icon_cont_open" width={'10rem'} height={'10rem'} onClick={()=>this.handleProfilePictureModalClose(true)}>
                <Box className="profile_pic_pen_icon">
                      <Box className="profile_pen_icon_cont">
                         <BorderColorOutlined style={{ fontSize: "15px" }}className="profile_pen_test"/>
                      </Box>
                </Box>

                {!photo
                  ? this.renderCameraContainer()
                  : this.renderAvatar(photo)
                }
             </Box>
            }

            renderAccountId=()=>{
              const {adminData:{account_id}} = this.state;
            return <Box className = 'profile_mail_parent'>
                <Box className="profile_admin_key">Account ID:</Box>
                <Box fontSize={'1.4rem'}>{account_id}</Box>
            </Box>
            }

            renderChangePasswordModal = () => {
              const { passwordVisiblity,isErrorPasswordContainerVisible } = this.state;
              const password_initial_values = {
                old_password:'',
                new_password:'',
                confirm_password:''
              }
              return (
                <MuiModal
                  open={this.state.isChangePasswordModalOpen}
                  onClose={() => {
                    this.handleChangePasswordModalOpen(false);
                    this.handleOffPasswordVisibility();
                  }}
                >
                  <Formik
                    initialValues={password_initial_values}
                    onSubmit={(values) => {
                      this.updateAdminPassword(values);
                    }}
                    innerRef={(formikProps) => {
                      this.updatePasswordFormik = formikProps as FormikProps<unknown>
                    }}
                    validationSchema={passwordValidationSchema}
                    validateOnChange
                    validateOnBlur
                  >
                    {(formikProps) => (
                      <StyledModalContent>
                        <ModalHeading style={{fontSize:"2.3rem"}}>Change password</ModalHeading>
                        <Box className="profile_gen_modal_margin" style={{margin:"1.5rem 0rem",marginBottom:"1.6rem"}}>
                          Your new password must be different from your previous password
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} style={{rowGap:"1rem"}}>
                          {this.renderPasswordTextFieldWithLabel(
                            "Old",
                            passwordVisiblity.Old,
                            formikProps,
                            'old_password'
                          )}
                          {this.renderPasswordTextFieldWithLabel(
                            "New",
                            passwordVisiblity.New,
                            formikProps,
                            'new_password'
                          )}
                          {this.renderPasswordTextFieldWithLabel(
                            "Confirm",
                            passwordVisiblity.Confirm,
                            formikProps,
                            'confirm_password'
                          )}

                          {isErrorPasswordContainerVisible && renderPasswordErrorBox(formikProps.values.new_password,this.state.isErrorPasswordContainerVisible)}


                          <Box className="profile_gen_modal_submit_cont" marginTop={'1.5rem'}>
                            <FormSubmitButton fullWidth className="profile_test_submit" onClick={()=>formikProps.handleSubmit()}>
                              Continue
                            </FormSubmitButton>
                          </Box>
                        </Box>
                      </StyledModalContent>
                    )}
                  </Formik>
                </MuiModal>
              );
            };
            

            renderPasswordTextFieldWithLabel=(label:string,isPasswordVisible:boolean,formikProps:FormikProps<password_initial_values_type>,keyName:keyof password_initial_values_type)=>{
              const {values,handleChange,touched,errors} = formikProps;
              return <Box>
                  <CustomLabel>{label} Password</CustomLabel>
                          <CustomFormTextField
                          autoComplete="off"
                          placeholder={`Enter ${label} Password`}
                          size="medium"
                          fullWidth
                          value={values[keyName]}
                          name={keyName}
                          onChange={handleChange}
                          id={keyName}
                          type={!isPasswordVisible ? 'password' : 'text'}
                          onFocus={()=>  this.handleErrorContainerVisible(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.handleVisibilityPassword(label,!isPasswordVisible);
                                  }}
                                  className="password_button_type"
                                >
                                  {!isPasswordVisible ? (
                                    <VisibilityOff style={{ color: colors.GRAY_COLOR_FORM }} />
                                  ) : (
                                    <Visibility style={{ color: colors.GRAY_COLOR_FORM }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          />    
                           {touched[keyName] && errors[keyName] && <Box className="login_valid_error" id="profile_error_properties">
                            {errors[keyName]}
                      </Box>}

              </Box>
            }

            renderOtp=()=>{
              const otpInitialValues = {otpValue:''}
              return (<Box className="forp_otp_form">
                 <MuiModal
                  open={this.state.isOtpModalOpen}
                  onClose={() => {
                    this.handleisOtpModalOpen(false);
                  }}
                >
                    <StyledModalContent style={{width:"400px"}}>
                      <Formik
                        initialValues={otpInitialValues}
                        onSubmit={(values) => {
                          this.verifyOtp(values);
                        }}
                        innerRef={(formikProps) => {
                          this.otpFormik = formikProps as FormikProps<unknown>;
                        }}
                        validationSchema={otpValidationSchema}
                        validateOnChange
                        validateOnBlur
                      >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <>
                              <ModalHeading>Enter Code</ModalHeading>
                              <Box className='profile_gen_modal_margin'>Check your email to find your verification code and enter below</Box>
                              <Box>
                              <OtpInput
                                  value={values.otpValue}
                                  onChange={(otpFigure:string) => {setFieldValue('otpValue',otpFigure)}}
                                  numInputs={4}
                                  inputStyle={{
                                   ...otpinputStyle,
                                    border: `1px solid ${touched.otpValue && errors.otpValue ? "red" : "#6C7278"}`,
                                  }}
                                  focusStyle={{outline:'none', border : '2px solid #FFDA94',borderRadius:'8px'}}
                                  containerStyle={otpcontainerStyle}
                              />
                              {touched.otpValue && errors.otpValue && <Box className="register_valid_error" position="absolute">
                            {errors.otpValue}
                        </Box>}
                            </Box>

                            <Box className="profile_gen_modal_submit_cont">
                                <FormSubmitButton fullWidth className="profile_test_submit email_continue" onClick={()=>{handleSubmit()}}>Verify</FormSubmitButton>
                                </Box>       
                            </>
                        )}
                      </Formik>
                      <RenderTimerAndResend seconds={this.state.seconds} submit={this.resendClick}/>
                    </StyledModalContent>
                </MuiModal>
              </Box>)
            }
            
              
              
  

 // Customizable Area End
  render() {
    // Customizable Area Start
    const {userRole} = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      {userRole === 'admin' ? this.renderAdminProfileParent() : this.renderUserProfile()}
      </>
      // Customizable Area End
    );
  }
}
