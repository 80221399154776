// Customizable Area Start
import React from 'react';
import * as yup from 'yup';
import {CheckRounded, ClearOutlined} from "@material-ui/icons"
import {Box} from "@material-ui/core";
const amountWithCommaRegex = /^(\d{1,3})(,\d{3})*(\.\d+)?$/;



interface LocationValidationArgs {
    fieldName: string;
    otherFieldName: string;
    requiredMessage: string;
    notSameMessage: string;
  }
  
  interface DateValidationArgs {
    requiredMessage: string;
  }
  
  interface DateRangeValidationArgs {
    fromDateFieldName: string;
    toDateFieldName: string;
    fromDateRequiredMessage: string;
    toDateRequiredMessage: string;
    toDateBeforeFromDateMessage: string;
  }

  const createLocationValidation = ({
    fieldName,
    otherFieldName,
    requiredMessage,
    notSameMessage,
  }: LocationValidationArgs): any => {
    return yup
      .mixed()
      .nullable()
      .required(requiredMessage)
      .test(`${fieldName}-notSame`, notSameMessage, function (value?: any) {
        
        const otherFieldValue: any = this.parent[otherFieldName];
        if (!otherFieldValue || (
          value?.city !== otherFieldValue?.city || value.code !== otherFieldValue.code)) {
          return true;
        }
        return false;
      });
  };
  
  const createDateRequiredValidation = ({
    requiredMessage,
  }: DateValidationArgs): any => {
    return yup
      .date()
      .nullable()
      .required(requiredMessage)
  };
  
  const createDateRangeSchema = ({
    fromDateFieldName,
    toDateFieldName,
    fromDateRequiredMessage,
    toDateRequiredMessage,
    toDateBeforeFromDateMessage,
  }: DateRangeValidationArgs) => {
    return {
      [fromDateFieldName]: yup.date()
        .nullable()
        .required(fromDateRequiredMessage)
        .typeError(`${fromDateFieldName} must be a valid date`),
        
      [toDateFieldName]: yup.date()
        .nullable()
        .required(toDateRequiredMessage)
        .typeError(`${toDateFieldName} must be a valid date`)
        .when(fromDateFieldName, (fromDate: Date | null, schema: yup.DateSchema) =>
          fromDate ? schema.min(fromDate, toDateBeforeFromDateMessage) : schema
        ),
    };
  };

   const aircarftSelectSchema = yup.object().shape({
        aircraftCategory: yup
          .string()
          .required("Aircraft category is required*")
          .notOneOf(
            ["Aircraft Category"],
            'Aircraft category is required*',
          ),
    })
  
  const oneWayFormValidationSchema = yup.object().shape({
    fromLocation: createLocationValidation({
      fieldName: 'fromLocation',
      otherFieldName: 'toLocation',
      requiredMessage: 'From Location is required*',
      notSameMessage: "From and To airport can't be the same*",
    }),
  
    toLocation: createLocationValidation({
      fieldName: 'toLocation',
      otherFieldName: 'fromLocation',
      requiredMessage: 'To Location is required*',
      notSameMessage: "From and To airport can't be the same*",
    }),
  
    departure: createDateRequiredValidation({
      requiredMessage: 'Departure date is required*',
    }),
  
  });

  const oneWayFormSchema = yup.object().shape({
    ...aircarftSelectSchema.fields,
    ...oneWayFormValidationSchema.fields,
  });
  

  const roundTripFormValidationSchema = oneWayFormValidationSchema.shape({
    departure: createDateRequiredValidation({
      requiredMessage: 'Return date is required*',
    }),
    leg1Departure: yup
      .date()
      .nullable()
      .required('Departure date is required**')
      .max(yup.ref('departure'), 'Departure date cannot be after the return date*'),
  });

  const roundTripSchema = yup.object().shape({
    ...aircarftSelectSchema.fields,
    ...roundTripFormValidationSchema.fields,
  });

  const multiLegFormValidationSchema = yup.object().shape({
    trips: yup.array().of(oneWayFormValidationSchema)
  });

  const multiLegSchema = yup.object().shape({
    ...aircarftSelectSchema.fields,
    ...multiLegFormValidationSchema.fields,
  });

  const contactUsValidationSchema=(key:string)=> {
    return yup.object().shape({
      [key]: yup.string()
                .matches(/^\+?[0-9\s-]+$/, "Phone number must contain only digits")
                .required('This field is required')
    });
  }


  const contact_us_validation_schema = yup.object().shape({
    phone_number: yup.string()
        .required('Call number is required*'),
    whatsapp: yup.string()
        .required('Text number is required*'),
    call_label: yup.string()
        .required('Call label is required*'),
    text_label: yup.string()
        .required('Text label is required*'),
});


const payment_summary_validation_schema = yup.object().shape({
  booking_amount:yup.string().nullable()
  .matches(amountWithCommaRegex, 'Booking amount must be valid price')
  .required('Booking amount is required*'),
  taxes:yup.string().nullable()
  .matches(amountWithCommaRegex, 'Taxes must be a number*')
  .required('Taxes are required*'),
  segment_fees: yup.string().nullable()
  .matches(amountWithCommaRegex, 'Segment fees must be a valid price*')
  .required('Segment fees are required*')
});


const order_details_schema = yup.object().shape({
  client_name: yup.object().shape({
    full_name: yup.string().nullable().required('Full name is required.'),
  }),
  account_id: yup.object().shape({
    account_id: yup.string().nullable().required('Account ID is required.'),
  }),
});

const commission_schema = yup.object().shape({
  sales_rep: yup.string().required('Sales representative is required*').typeError('Sales representative is required*'),
});
const flightIteneraryValidationSchema = yup.object().shape({
  from_airport: createLocationValidation({
    fieldName: 'fromLocation',
    otherFieldName: 'to_airport',
    requiredMessage: 'From Location is required',
    notSameMessage: "From and To airport can't be the same",
  }),

  to_airport: createLocationValidation({
    fieldName: 'toLocation',
    otherFieldName: 'from_airport',
    requiredMessage: 'To Location is required',
    notSameMessage: "From and To airport can't be the same",
  }),
  arrival: yup.date().nullable().required("Arrival date is required"),
  departure : yup
  .date()
  .nullable()
  .required('Departure date is required*')
  .max(yup.ref('arrival'), 'Departure date cannot be after the arrival date'),
  no_of_passengers: yup
  .number()
  .required('Number of passengers is required')
  .typeError('Number of passengers must be a number'),
  no_of_stops:yup.string().nullable().required('Number of stops is required'),
  vendor_charged: yup
  .string()
  .nullable()
  .matches(amountWithCommaRegex, 'Vendor charged must be a valid amount'),
})

const systemSettingsSchema = yup.object().shape({
  domestic_segment_fee: yup.number().typeError('Domestic segment fee must be a number*'),
  international_segment_fee: yup.number().typeError('International segment fee must be a number*'),
  landing_fee: yup.number().typeError('Landing fee must be a number*'),
  markup_percentage: yup.number().typeError('Markup percentage must be a number*').max(100,'Markup percentage must be a percentage figure').nullable(),
  tax: yup.number().typeError('Tax must be a number').max(100,'Tax must be a percentage figure').nullable(),
  payment_processing_fee: yup.number().typeError('Payment Processing Fee must be a number').max(100,'Payment Processing Fee must be a percentage figure').nullable()
});

const passwordTester = (
  password: string,
  isPasswordErrorsVisible: boolean,
  condition: boolean,
) => {
  if (password && condition) {
    return <CheckRounded className="register_valid_tick" />;
  }
  if (isPasswordErrorsVisible  && !password) {
    return <CheckRounded className="register_valid_grey" />;
  }
  return <ClearOutlined className="register_valid_cross" />;
};


const renderPasswordErrorBox=(value:any,isErrorPasswordContainerVisible:any)=>{
              
  return  <Box className="register_password_validation_container" padding={'0rem'} marginTop={'1rem'}>
        <Box>Password must contain:</Box>
        <Box className="regsiter_display_flex">
            <Box component={"span"} className="register_icon_container">
              {passwordTester(
                value,
                isErrorPasswordContainerVisible,
                /[A-Z]/.test(value),
              )}
            </Box>
            At least one capital letter{" "}
        </Box>
        <Box className="regsiter_display_flex">
  <Box component={"span"} className="register_icon_container">
    {passwordTester(
      value,
      isErrorPasswordContainerVisible,
      /[a-z]/.test(value),
    )}
  </Box>
  At least one lowercase letter
</Box>
<Box className="regsiter_display_flex">
  <Box component={"span"} className="register_icon_container">
    {passwordTester(
      value,
      isErrorPasswordContainerVisible,
      /\d/.test(value),
    )}
  </Box>
  At least one number
</Box>
<Box className="regsiter_display_flex">
          <Box component={"span"} className="register_icon_container">
            {passwordTester(
              value,
              isErrorPasswordContainerVisible,
              /[!@#$%^&*(),.?":{}|<>]/.test(value),
            )}
          </Box>
          At least one special character
        </Box>
<Box className="regsiter_display_flex">
  <Box component={"span"} className="register_icon_container">
    {passwordTester(
      value,
      isErrorPasswordContainerVisible,
      value.length >= 8,
    )}
  </Box>
  Minimum character length is 8 characters
</Box>
        </Box>
}

const emailValidationSchema = yup.object().shape({
  email:yup.string().email('Inavlid Email Address*').required('Email is required*')
})

const otpValidationSchema = yup.object().shape({
  otpValue : yup.string().required('OTP is required').matches(/^\d{4}$/, 'OTP must be exactly 4 digits')
})

const commissionKeySchema =  yup.string()
    .trim()
    .matches(/^\d+$/, 'Commission must be a number*')
    .test('is-less-than-100', 'Commission must be less than 100%', function(value) {
      return !value || (parseInt(value, 10) < 100);
    })



const invoiceValidationSchema = yup.object().shape({
  invoice_name:yup.string().required('Invoice Name is required'),
  invoice_items_attributes: yup.array().of(
    yup.object().shape({
      item_name: yup.string()
        .trim()
        .matches(/.*/, "Invalid Item Name*")
        .min(1, 'Item name cannot be empty*')
        .required('Item name is required*')
        .test('is-not-only-spaces', 'Item cannot be only spaces', value => {
          return value && value.trim().length > 0;
        }),
        price: yup.string()
        .trim()
        .matches(/^\d+(\.\d+)?$/, 'Price must be a number*')
        .required('Price is required*'),
      quantity: yup.string()
        .trim()
        .matches(/^\d+(\.\d+)?$/, 'Quantity must be a number*')
        .required('Quantity is required*'),
    })
  ),
})

const expiryDateValidation = () => {
  return yup.string()
    .nullable()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Expiry must be in MM/YY format')
    .required('Expiry is required')
    .test(
      'expiry',
      'Expiry date is past',
      function (value) {
        if (!value) return true;
        const [month, year] = value.split('/').map(Number);
        const now = new Date();
        const expiryDate = new Date(`20${year}` as unknown as number, month - 1);
        return expiryDate >= new Date(now.getFullYear(), now.getMonth(), 1);
      }
    );
};

  export {expiryDateValidation, invoiceValidationSchema, commissionKeySchema, otpValidationSchema, emailValidationSchema, renderPasswordErrorBox, passwordTester, systemSettingsSchema,flightIteneraryValidationSchema, commission_schema, order_details_schema,payment_summary_validation_schema, contact_us_validation_schema, roundTripFormValidationSchema, oneWayFormValidationSchema, multiLegFormValidationSchema, oneWayFormSchema, roundTripSchema, multiLegSchema, contactUsValidationSchema}

// Customizable Area End