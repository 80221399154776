import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import {FilterList, MoreVert, Search} from '@material-ui/icons';
import { AddLegtTypeButton, CSSPropertiesMap, CustomDashboardButton, CustomTypography, GeneralParentBox, SearchTextFieldActiveBooking, StatusDot, StyledChip } from "../../../components/src/MuiCustomComponents";
import { Send_Icon } from "../../../components/src/assets";


// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

   renderMessageList=() =>{
    const messageArray = [1,1,1,1];
    return <Box className="booking_section">
      {this.renderMessageSearhAndFilter()}
      <Box style={webStyles.messageParent}>
        {messageArray.map((_messageOption,msgIndex)=>this.renderMessageOption(msgIndex))}
      </Box>
    </Box>
   }

   renderMessageSearhAndFilter=()=>{
    return <Box className="dash_flex_row" style={webStyles.messageAndSend}>
      <SearchTextFieldActiveBooking
        fullWidth
        style={webStyles.filterTextfield}
        placeholder="Search By Name & ID..."
        inputProps={{style:{fontSize:'1.3rem'}}}
        InputProps={{
          endAdornment:(
            <InputAdornment position="end">
                <Search style={{color:'#757677',fontSize:'2rem'}}/>
            </InputAdornment>
          )
        }}
      />

        <CustomDashboardButton style={webStyles.filterButton}>
            <FilterList style={{fontSize:'2.4rem'}}/>
        </CustomDashboardButton>

    </Box>
   }

   renderMessageOption=(msgIndex:number)=>{
    const borderTop = msgIndex !== 0 ? '1px solid #393D42' : '';
    return <Box style={{...webStyles.eachMessageContainer,borderTop}}>
      <Box style={webStyles.messageBox1}>
          <Box style={webStyles.messageBox1}>
             <StatusDot outerColor="#1B441B" innerColor="#1EC611"/>
             <Avatar style={webStyles.avatar}/>
             {this.renderNameAndLastMessage()}
          </Box>
      </Box>

      <Box style={{...webStyles.nameAndLastMessage,alignItems:'center'}}>
        <Box>11:49</Box>
        <StyledChip label='New'/>
      </Box>

    </Box> 
   }

   renderNameAndLastMessage=()=>{
    return <Box style={webStyles.nameAndLastMessage}>
      <Box style={webStyles.personName}>Jacob Jonas</Box>
      <Box style={webStyles.lastMessageStyle}>the problem is with flight...</Box>
    </Box>
   }

   renderChatContainer=()=>{
    return <Box style={webStyles.chatParent}>
        {this.renderChatContainerHeader()}
        <Box style={{overflow:'auto'}}>{this.renderChatBox()}</Box>
        <Box style={{marginTop:"auto"}}>{this.renderMessageTextFieldAndSend()}</Box>
    </Box>
  }

   renderChatContainerHeader=()=>{
    return <Box style={webStyles.chatHeader}>
      <Box style={webStyles.messageBox1}>
        <Avatar style={webStyles.avatar}/>
        <Box style={webStyles.personName}>John Smith</Box>
        <StyledChip label='Guest User' style={webStyles.guestChip}/>
      </Box>

      <Box style={webStyles.messageBox1}>
        <AddLegtTypeButton style={webStyles.closedButtonStyle}>Mark As Closed</AddLegtTypeButton>
        <IconButton><MoreVert color="primary"/></IconButton>
      </Box>
    </Box>
   }



   renderChatBox=()=>{
    const text = [
      {text:'I’m not able to register my account!',isMine:false},
      {text:'+1 855-935-9538',isMine:false},
      {text:'johnsmith@gmai.com',isMine:false}, 
      {text:'I am checking on it',isMine:true},
    ];
    return <Box style={webStyles.textBoxParent}>
      {text.map((message)=>this.renderTextMessage(message))}
    </Box>
  }

   renderMessageTextFieldAndSend=()=>{
    return <Box style={{...webStyles.messageAndSend}}>
      <SearchTextFieldActiveBooking
          fullWidth
          style={{...webStyles.filterTextfield,borderRadius:"2.5rem"}}
          placeholder="Type your message here..."        
          inputProps={{style:{fontSize:'1rem'}}}
      />
      <Button style={webStyles.sendIconParent}><Send_Icon/></Button>
    </Box>
   }

   renderTextMessage=({text,isMine}:{text:string,isMine:boolean})=>{
    const textStyle = isMine ? webStyles.myText : webStyles.otherText;
    return <Box style={{...webStyles.textBox,...textStyle}}>
      {text}
    </Box>
   }

   renderHeaderContainer=() =>{
    return <Box className="dash_flex_row">
      <CustomTypography style={{color:'white'}}>Inbox</CustomTypography>
    </Box>
   }

   renderChatParent=()=>{
    return <GeneralParentBox style={{...webStyles.inboxParent,height:'75vh'}}>
      <Grid container style={webStyles.fullHeight}>
          <Grid xs={4}><Box style={webStyles.inboxChild}>{this.renderMessageList()}</Box></Grid>
          <Grid xs={8}><Box style={{...webStyles.inboxChild,borderLeft:'1px solid #3E4247'}}>{this.renderChatContainer()}</Box></Grid>
      </Grid>
    </GeneralParentBox>
   }


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     <Box className="booking_section chat_container_parent">
       {this.renderHeaderContainer()}
       {this.renderChatParent()}
     </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start



const webStyles:CSSPropertiesMap = {
  inboxParent:{
    padding : '1rem 0rem',
  },
  fullHeight:{
    height:"100%"
  },
  inboxChild:{
    padding : '0rem 1rem'
  },
  filterButton:{
    height:'3.5rem',
    width:'3.5rem',
    borderRadius:"50%",
    minWidth:'auto'
  },
  filterTextfield:{
    height:"2rem",
    flexDirection:'inherit',
    backgroundColor:'#1A1C1E',
  },
  messageParent:{
    backgroundColor:'#1A1C1E',
    borderRadius:"10px"
  },
  avatar:{
    height:'3rem',
    width:'3rem'
  },
  eachMessageContainer:{
    padding:'1rem',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  messageBox1:{
    display:"flex",
    columnGap:'1rem',
    alignItems:'center'
  },
  nameAndLastMessage:{
    display:'flex',
    flexDirection:'column',
    rowGap:'0.2rem'
  },
  personName:{
    fontSize:'1.3rem',
    fontWeight:'bold'
  },
  lastMessageStyle:{
    color:"#64748B"
  },
  chatParent:{
     display:'flex' ,
     flexDirection:'column',
     rowGap:'1rem',
     height:"100%"
  },
  chatHeader:{
    backgroundColor:'#1A1C1E',
    padding:'1rem',
    display:'flex',
    justifyContent:'space-between',
    borderRadius:'10px'
  },
  guestChip:{
    fontSize:'1rem',
    fontWeight:100,
    padding:"0.5rem 0.6rem",
    backgroundColor:'#363229',
    color:"#FFD27E"
  },
  closedButtonStyle:{
    fontSize:"1rem",
    height:'2.8rem',
    border:'1px solid #94A3B8',
    color:'white'
  },
  textBoxParent:{
    display:'flex',
    flexDirection:'column',
    rowGap:'0.4rem',
  },
  textBox:{
    padding:'1rem',
    width:'fit-content',
    wordWrap: 'break-word'

  },  
  otherText:{
    backgroundColor:'#1A1C1E',
    borderRadius:"0px 10px 10px 10px",
  },
  myText:{
    backgroundColor:'#FFD27E',
    color:'black',
    alignSelf:'end',
    borderRadius:"10px 0px 10px 10px",
  },
  messageAndSend:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    columnGap:'1.5rem'
  },
  sendIconParent:{
    backgroundColor : '#FFE1A7',
    padding:'0.9rem 1.5rem',
    borderRadius:"2rem"
  }
};
// Customizable Area End
