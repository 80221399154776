// Customizable Area Start
import React from "react";
import {
  Box,
  Accordion,
  AccordionSummaryProps,
  styled,
  AccordionDetails,
  IconButton,
  MenuItem,
  Button
} from "@material-ui/core";
import {CustomTypography, StyledEditDeleteMenu} from "../../../components/src/MuiCustomComponents"
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import {Edit, KeyboardArrowDown, MoreVert, DeleteOutline} from "@material-ui/icons"



const FAQAnswerAccordion = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<KeyboardArrowDown style={{ color: "white" }}/>}
      {...props}
    />
  ))({
    //   height: '30px',
      borderRadius : '0.5rem',
    '& .MuiAccordionSummary-content': {
      alignItems:"center"
    },
    '&.Mui-expanded': {
      minHeight: '48px',
      margin: '0px', 
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px', 
    },
    '& .MuiAccordionSummary-expandIcon':{
        alignSelf:'baseline'
    }
  });
  
  const FAQAccordion = styled(Accordion)({
    color: 'white !important',
    boxShadow: 'none !important',
    '& p':{
        margin:0
    }
  });

  
  import ContactusController, { Props } from "./ContactusController";
  
  
  export default class FAQ extends ContactusController {
      constructor(props: Props) {
          super(props);
        }
        
        renderFAQSection = () => {
            const { faqData } = this.state
            return (
               <Box>
                  <Box style={webStyles.headCont}>
                     <CustomTypography>FAQs</CustomTypography>
                     <Button style={webStyles.createFAQButton} onClick={this.handleNavigationToCreateFaq} className="faq_create_faq">+ Create New FAQ</Button>
                  </Box>
                  <Box style={{ ...webStyles.childCont, ...webStyles.answerCont }}>
                     {faqData &&
                        faqData.map((eachfaq: any,index:number) =><React.Fragment key = {index}>
                            {this.renderQuestioAccordion(eachfaq)}
                        </React.Fragment>
                        )}
                  </Box>
               </Box>
            )
         }
         
         renderQuestioAccordion = (eachfaq: any) => {
            const {
               attributes: { question, answer, id },
            } = eachfaq
            return (
               <Box style={webStyles.faqParentCont} className="faq_Container" key={id}>
                  <FAQAccordion style={{ backgroundColor: "#2D3034", width: "97%" }}>
                     <FAQAnswerAccordion
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                     >
                        <Box style={webStyles.question}>{question}</Box>
                     </FAQAnswerAccordion>
                     <AccordionDetails style={webStyles.answer} dangerouslySetInnerHTML={{ __html: answer }} />
                  </FAQAccordion>
                  {this.renderFaqMenu(id)}
               </Box>
            )
         }
         
         renderFaqMenu = (id:any) => {
            const { faqMenuAnchors } = this.state;
            const faqMenuAchor = faqMenuAnchors[id];

            return (
               <Box>
                  <IconButton className="faq_menu" onClick={(event: any) => this.handleClick(event,id)}>
                     <MoreVert style={webStyles.menuIcon} />
                  </IconButton>
                  <StyledEditDeleteMenu
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                     }}
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     style={webStyles.menuStyle}
                     id='simple-menu'
                     anchorEl={faqMenuAchor}
                     keepMounted
                     open={Boolean(faqMenuAchor)}
                     onClose={()=>this.handleClose(id)}
                  >
                     <MenuItem style={webStyles.menuItemStyle} onClick = {()=>this.handleNavigationToEditFaq(id)} className='faq_edit_button'>
                        <Edit style={{marginRight:"10px"}}/>
                        <Box>Edit</Box>
                     </MenuItem>
                     <MenuItem style={{ ...webStyles.menuItemStyle, color: "#DC2626" }} onClick = {()=>this.handleDeleteFAQInitializer(id)} className='faq_delete_button'>
                        <DeleteOutline style={{marginRight:"10px"}}/>
                        <Box>Delete</Box>
                     </MenuItem>
                  </StyledEditDeleteMenu>
               </Box>
            )
         }
         


render() {
    return (
<Box className="contact_us_parent_section" style={webStyles.mainCont}>
        {this.renderFAQSection()}
</Box>
      );
    }
}


type CSSPropertiesMap = {
    [key: string]: React.CSSProperties;
};

export const webStyles:CSSPropertiesMap = {
    mainCont:{
        backgroundColor:'inherit',
        color:'white',
        display:'flex',
        flexDirection: 'column',
        rowGap:'3rem',
        paddingBottom:'4rem'
    },
    generalBoxStyles:{
        backgroundColor:'#2D3034',
        borderRadius:'1.4rem',
        padding:'1.5rem'
    },
    goldTextAndSize:{
        fontSize:'1.15rem',
        color:'#FFD27E',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    goldIconStyle:{
        color:'#FFD27E'
    },
    flex:{
        display:'flex',
        columnGap:'0.5rem',
        alignItems:'center'
    },
    childCont:{
        marginTop : '1rem'
    },
    question:{
        fontSize:'1.2rem'
    },
    answerCont:{
        display:'flex',
        flexDirection : 'column',
        rowGap : '0.6rem'
    },
    answer:{
        fontSize : '1.3rem',
        color : '#AEAEB2',
        padding:'0px 16px',
        display:'inherit'
    },
    faqParentCont:{
        display:'flex',
        backgroundColor:'#2D3034',
        alignItems:'flex-start',
        borderRadius:'1.4rem',
        overflow:'hidden',
        padding : '0.7rem 0.4rem',
        paddingRight:'1rem'
    },
    menuIcon:{
        position:'relative',
        cursor:'pointer',
        color:'white'
    },
    menuStyle:{
        marginTop:'3rem',
        marginLeft:'1.3rem'
    },
    menuItemStyle:{
        columnGap : '0.4rem',
    fontSize:'1.2rem'
},
createFAQButton:{
    color:'#FFB833',
    textTransform:'none',
    fontSize:'1.25rem',
    fontWeight:'bold'
},
headCont:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
},
eachRequestParent:{
    display:'flex',
    columnGap:'0.5rem',
    fontSize:'1.15rem'
},
requestDateAndColor:{
    color:'#94A3B8',
    fontSize:'1rem'
},
requestInfo:{
    display:'flex',
    flexDirection:'column',
    rowGap:'0.2rem'
}
}

// Customizable Area End
