import * as React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  AccordionDetails,
  IconButton,
  Button,
  InputAdornment,
  MenuItem,
  MenuProps,
  PopperProps,
} from "@material-ui/core";
import {AutocompleteRenderInputParams} from "@material-ui/lab/Autocomplete"
import {theme as colors} from '../../../components/src/utils';
import {LinearProgressWithLabel, CustomDepartueAiportAutoCompplete,CustomSelectOrderDetails, CustomCheckbox ,StyledGrid, GoldishSimpleButton, GenralUsedAccordion, MyAccordionSummary, AddLegtTypeButton, FormSubmitButton,PaymentTexfields, PaymentLabel, CustomRadio, MuiModal, StyledModalContent,SearchStyledAutocomplete, RequirementKindTextField,CustomFormTextField, CustomSwitch,Footer,renderOptionFrom, HorizantalRow, CustomStyledPopper, SurityModal, TimePickerNew, StatusModal, MuiResuableStatusModalBody, StatusGeneralParentContainer, ModalHeading, PaymentStatusIcon, PaymentStatusParent, cleanCardNumber, formatCardNumber, handleVendorChargedChange,dollarTextFieldInputProps, validContractsExtension, GridItem} from '../../../components/src/MuiCustomComponents';
import {Replay,RoomOutlined,SearchOutlined,ArrowBack, Edit, KeyboardArrowDown, DeleteOutline, SaveAlt,DateRangeOutlined, Search, CheckOutlined} from "@material-ui/icons";
import {PNG_ICON,PDF_ICON, UPLOAD_ICON, DEBIT_CARD_ICON, EXPRESS_ICON, UploadIcon2, master_image, visa_image, defaultCard, americalExpess, RefundStatusIcon} from "./assets";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from 'uuid';
import {AddPassengerModal, CancelAndSaveChanges, AddNewClientModal} from "../../../components/src/ReUsableModal";
import {Formik , FieldArray, ArrayHelpers, FormikProps} from 'formik';
import {payment_summary_validation_schema, order_details_schema, commission_schema, flightIteneraryValidationSchema,invoiceValidationSchema, expiryDateValidation} from "../../../components/src/Validations";
import {makeStyles} from "@material-ui/core/styles";
import { get as lodashGet} from "lodash";
import {Country, ICountry} from 'country-state-city';
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
const debounceTime = 500;
import {VirtualTerminalType,PaymentTextFieldType,OrderDetailsAddCompType, CommissionEditModalType, PaymentSummaryModalType,PaymentSummarySubmitType, SelectMenuOptionType,MainInvoiceType, UploadDocumentAccordionType,EachRequirementObjectType, RequirementsSectionType, RequirementEditModalType, FileData , RequirementTextType, EditRequireMentEachSectionType, FileType, DocumentShowAndDeleteType, RequirementsGridsTypes, EachRequirementCompType, InvoiceParentType, InvoiceSectionType, momentType, FlightInteneraryType, IncidentalsInvoiceType, IncidientaiInvoiceParentType, CreateEditInvoiceSectionType, IncidentalInvoiceFormType, FlightInteneraryCompType, FromAndToType, FlightInteneraryInitType, OrderSummaryType, OrderDetailEditModalType, AnnoutSearchType, OrderDetailsType, setEditInvoiceInitialValueType, EachRowInfoType, FlightItenEachCompType, ParentPassengerSectionsType, PassengerType, IncidentalDetailObjectType, PassengerSectionType, LeadAndAddPassType, SavePassengerInitType, SavedPassengerType, AddNewPassType, TouchedMessage, FlightIteneraryValueType, FlightItenEditModalType, PaymentWithLabelCompFocusType, BookingRefundStatusModalType, PaymentMethodRadioContainerType, VirtualTerminalExactDataType, PaymentWithRadioType, CardFormType, PaymentStatusOpenAndStatusComp, SuccessPaymentModalOpenType, ChargeExistingCompType, SavedCardType, InitExistingCardValuesType, RefundCompType, RefundCardCompType, RefundInitValueType, RefundFormikType, RefundInfoComponentType, RefundLoaderType, OrderSummaryPaymentMethodComp, PaymentMethodType, PaymentProcessingCompType, IsClientOrderType, PassengerInfoMainInvoiceCompType, PassportGridCompType, DebitSkyCardInvoiceInfoType, PaymentMethodCardType, MomentCompType} from "./types";

const cardFormValidationSchema = (maxAmount:number) => {
  return Yup.object().shape({
    amount: Yup.number()
      .nullable()
      .typeError('Amount must be a number')
      .positive('Amount must be positive')
      .max(maxAmount, `Amount cannot be more than $${maxAmount}`)
      .required('Amount is required'),
    card_number: Yup.string().nullable()
      .matches(/^(\d{4} ){3}\d{4}$/, 'Card number is not valid')
      .required('Card number is required'),
    name_on_card: Yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'Name on card is required')
      .required('Name on card is required'),
    expiry: expiryDateValidation(),
    security_code: Yup.string()
      .nullable()
      .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits')
      .required('CVV is required'),
    street_address: Yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'Street address is required')
      .required('Street address is required'),
    city: Yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'City is required')
      .required('City is required'),
    state: Yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'State is required')
      .required('State is required'),
    zip_code: Yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'ZIP code is required')
      .required('ZIP code is required'),
    country: Yup.string()
      .nullable()
      .matches(/\S/, 'Country is required')
      .required('Country is required'),
  });
};

const amountValidationSchema = (maxAmount:number) => Yup.number()
  .nullable()
  .typeError('Amount must be a number')
  .positive('Amount must be positive')
  .max(maxAmount, `Amount cannot be more than $${maxAmount}`)
  .required('Amount is required');

const chargeExistingCardValidation = (maxAmount:number) => {
  return Yup.object().shape({
    amount_pay: amountValidationSchema(maxAmount),
  });
};

const refunFormValidation = (maxAmount:number) => {
  return Yup.object().shape({
    amount: amountValidationSchema(maxAmount),
  });
};



const useStyles = makeStyles({
  dropdown: {
    backgroundColor: '#3E4247',
    marginTop:'-0.5px',
    borderRadius:'5px',
    border:'1px solid white',
    color:'white',
    '&[data-option-selected="true"]': {
      backgroundColor: 'gold', 
      color: 'white',
    }
  },
  noOptions: {
    color: 'white',
  },
  option: {
    '&[data-option-selected="true"]': {
      backgroundColor: 'gold', 
      color: 'white',
    }
  },
});


const adminSelectProp = {
  getContentAnchorEl: null,
  className: "profile_ditry_menu",
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  disableScrollLock: true,
}

// Customizable Area End

import { ViewProps, configJSON } from "./OrderManagement";

const OrderManagementView: React.FC<ViewProps> = ({
  // Customizable Area Start
  orderDetails,
  paymentSummary,
  commission,
  bookingNumber,
  legsData,
  passengerData,
  isInvoiceVisible,
  setIsInvoiceVisible,
  updtePaymentSummary,
  setIsPaymentSumaryModalOpen,
  isPaymentSumaryModalOpen,
  optionsbyName,
  optionsbyId,
  searchClientByName,
  updateOrderDetails,
  setIsOrderDetailsModelOpen,
  isOrderDetailsModelOpen,
  adminData,
  setIsCommissionModelOpen,
  isCommissionModelOpen,
  redirectBack,
  updateCommission,
  orderSummary,
  fromAiportsData,
  toAiportsData,
  getSearchedAirports,
  isFromAirportTouched,
  flightInternariesInitValues,
  updateFlightItenerary,
  setIsFlightIteneraryModalOpen,
  isFlightIteneraryModalOpen,
  requirement,
  updateRequirements,
  isRequirementModalOpen,
  setIsRequirementModalOpen,
  uploadContracts,
  setIsContractsModalOpen,
  isContractsModalOpen,
  mainInvoice,
  incidentals,
  isSavedPassengerModalOpen,
  setIsSavedPassengerModalOpen,
  isAddPassengerModalOpen,
  setIsAddPassengerModalOpen,
  isNewClientModalOpen,
  setIsNewClientModalOpen,
  manageIncidentalInvoice,
  downloadInvoice,
  setEditInvoiceInitialValue,
  editInvoiceInitialValue,
  convertFilesToBase64,
  inputFileRef,
  inputFileRefAccordion,
  inputFileRefContracts,
  defaultPrevent,
  propagationStop,
  moment,
  paymentStatusOptions,
  vendorStatusOption,
  addSavePassengerToBooking,
  currentBookingPage,
  popularAirports,
  focusedField,
  setFocusedField,
  createNewProfile,
  clientForm,
  passportRef,
  addOrEditSavePassenger,
  setaddEditPassengerInitialValues,
  addEditPassengerInitialValues,
  deletePassenger ,
  makeLeadPassenger,
  cardDetails,
  activeFieldName,
  setActiveFieldName,
  setIsSureModalOpen,
  isSureModalOpen,
  setDeleteFunction,
  deleteFunction,
  manageClickOnYes,
  downloadAllPassportAndCustomer,
  handleFlightItenariesInitialValues,
  setFlightInternariesInitValues,
  setIsEditFlightMode,
  isEditFlightMode,
  flight_init_values,
  setHoveredLeg,
  hoveredLeg,
  requirementAccordionRef,
  requirementRef,
  setFlightIteneraryActive,
  flightIteneraryActive,
  statusModalHeading,
  isStatusModalOpen,
  setIsStatusModalOpen,
  virtualTerminalData,
  setCardDetails,
  chargeNewCard,
  paymentStatusAndOpen,
  setPaymentStatusAndOpen,
  savedCard,
  isApiProcessing,
  existingCardFormikRef,
  newCardFormRef,
  isNewCardUsed,
  refundData,
  refundInitValue,
  processRefund,
  setRefundProcessingLoaderIndex,
  refundProcessingLoaderIndex,
  paymentMethod,
  setIsPaymentProcessing,
  isPaymentProcessing,
  commaToFigure,
  isProfitCommissionSaving,
  isPaymentSummarySaving,
  isRequirementSaving,
  isOrderDetailsSaving,
  isInvoiceProcessing,
  navigationToClientDetails,
  requirementFiledUploadRef
  // Customizable Area End
}) => {

  // Customizable Area Start
  // Customizable Area End
  
  return (
    // Customizable Area Start
    <>
      {!isInvoiceVisible ?<Box className="booking_section">
        <SurityModal isOpen={isSureModalOpen}><SureModalButtonContainer submit={()=>{manageClickOnYes(deleteFunction.lastClicked,deleteFunction.deleteId)}} cancel={()=>setIsSureModalOpen(false)}/></SurityModal>
        <PaymentStatusModal isNewCardUsed={isNewCardUsed} existingCardFormikRef={existingCardFormikRef} newCardFormRef={newCardFormRef} isApiProcessing={isApiProcessing} setPaymentStatusAndOpen={setPaymentStatusAndOpen} paymentStatusAndOpen={paymentStatusAndOpen}/>
        <BookingRefundStatusModal setStatusModal={setIsStatusModalOpen} headingObject={statusModalHeading} isModalOpen={isStatusModalOpen}/>
        <BookingNumberHeading redirectBack={redirectBack}/>
        <OrderDetailSection
          isOrderDetailsSaving={isOrderDetailsSaving}
          isPaymentSummarySaving={isPaymentSummarySaving}
          isProfitCommissionSaving={isProfitCommissionSaving}
          booking_no={bookingNumber}
          orderDetails={orderDetails as OrderDetailsAddCompType['orderDetails']}
          bookingType = {currentBookingPage}
          paymentSummary={paymentSummary}
          commission={commission}
          submitPaymentSummary={updtePaymentSummary}
          isPaymentSumaryModalOpen={isPaymentSumaryModalOpen}
          setIsPaymentSumaryModalOpen={setIsPaymentSumaryModalOpen}
          optionsbyName={optionsbyName}
          optionsbyId={optionsbyId}
          searchClientByNameFunction={searchClientByName}
          updateOrderDetails={updateOrderDetails}
          isOrderDetailsModelOpen={isOrderDetailsModelOpen}
          setIsOrderDetailsModelOpen={setIsOrderDetailsModelOpen}
          adminData={adminData}
          setIsCommissionModelOpen={setIsCommissionModelOpen}
          isCommissionModelOpen={isCommissionModelOpen}
          updateCommission={updateCommission}
          setIsNewClientModalOpen={setIsNewClientModalOpen}
          isNewClientModalOpen={isNewClientModalOpen}
          moment={moment}
          paymentStatusOptions={paymentStatusOptions}
          vendorStatusOption={vendorStatusOption}
          airports={fromAiportsData}
          getSearchedAirports={getSearchedAirports}
          focusedField = {focusedField}
          setFocusedField = {setFocusedField}
          createNewProfile = {createNewProfile}
          clientForm = {clientForm}
          commaToFigure={commaToFigure}
          navigationToClientDetails={navigationToClientDetails}
 />
        <FlightItinerary 
          legsData={legsData as FlightInteneraryType[]}
          bookingType = {currentBookingPage}
          fromAiportsData={fromAiportsData}
          toAiportsData={toAiportsData}
          getSearchedAirports={getSearchedAirports}
          isFromAirportTouched={isFromAirportTouched}
          flightInternariesInitValues={flightInternariesInitValues}
          updateFlightItenerary={updateFlightItenerary}
          setIsFlightIteneraryModalOpen={setIsFlightIteneraryModalOpen}
          isFlightIteneraryModalOpen={isFlightIteneraryModalOpen}
          uploadContracts={uploadContracts}
          setIsContractsModalOpen={setIsContractsModalOpen}
          isContractsModalOpen={isContractsModalOpen}
          convertFilesToBase64={convertFilesToBase64}
          inputFileRef={inputFileRef}
          inputFileRefAccordion={inputFileRefAccordion}
          inputFileRefContracts={inputFileRefContracts}
          defaultPrevent={defaultPrevent}
          propagationStop={propagationStop}
          moment={moment}
          handleFlightItenariesInitialValues={handleFlightItenariesInitialValues}
          setFlightInternariesInitValues={setFlightInternariesInitValues}
          setIsEditFlightMode={setIsEditFlightMode}
          isEditFlightMode={isEditFlightMode}
          flight_init_values={flight_init_values}
          setIsSureModalOpen={setIsSureModalOpen}
          setDeleteFunction={setDeleteFunction}
          setHoveredLeg={setHoveredLeg}
          hoveredLeg={hoveredLeg}
          setFlightIteneraryActive={setFlightIteneraryActive}
          flightIteneraryActive={flightIteneraryActive}
         />
        <PassengerSection 
        isApiProcessing={isApiProcessing}
        passengerData={passengerData}
        isSavedPassengerModalOpen={isSavedPassengerModalOpen}
        setIsSavedPassengerModalOpen={setIsSavedPassengerModalOpen}
        isAddPassengerModalOpen={isAddPassengerModalOpen}
        setIsAddPassengerModalOpen={setIsAddPassengerModalOpen}
        propagationStop={propagationStop}
        addSavePassengerToBooking={addSavePassengerToBooking}
        bookingType = {currentBookingPage}
        focusedField={focusedField}
        setFocusedField={setFocusedField}
        passportRef={passportRef}
        addOrEditSavePassenger={addOrEditSavePassenger}
        addEditPassengerInitialValues={addEditPassengerInitialValues}
        setaddEditPassengerInitialValues={setaddEditPassengerInitialValues}
        deletePassenger={deletePassenger}
        makeLeadPassenger={makeLeadPassenger}
        setIsSureModalOpen={setIsSureModalOpen}
        setDeleteFunction={setDeleteFunction}
        downloadAllPassportAndCustomer={downloadAllPassportAndCustomer}
        />
        <RequirementsSection
         isRequirementSaving={isRequirementSaving}
         requirementAttributes={requirement} 
         updateRequirements={updateRequirements}
         setIsRequirementModalOpen={setIsRequirementModalOpen}
         isRequirementModalOpen={isRequirementModalOpen}
         convertFilesToBase64={convertFilesToBase64}
         inputFileRef={requirementRef}
         inputFileRefAccordion={requirementAccordionRef}
         defaultPrevent={defaultPrevent}
         propagationStop={propagationStop}
         bookingType = {currentBookingPage}    
         requirementFiledUploadRef={requirementFiledUploadRef}     
         />
        <PaymentsMethodAndOrderSummary orderSummary={orderSummary} paymentMethod={paymentMethod}/>
       {virtualTerminalData !== null && <VirtualTerminal isPaymentProcessing={isPaymentProcessing} setIsPaymentProcessing={setIsPaymentProcessing} existingCardFormikRef={existingCardFormikRef} newCardFormRef={newCardFormRef} savedCard={savedCard} chargeNewCard={chargeNewCard} setCardDetails={setCardDetails} virtualTerminalData={virtualTerminalData} cardDetails={cardDetails} setActiveFieldName={setActiveFieldName} activeFieldName={activeFieldName}/>}
        {refundData.length !== 0 && <RefundSection refundProcessingLoaderIndex={refundProcessingLoaderIndex} setRefundProcessingLoaderIndex={setRefundProcessingLoaderIndex} moment={moment} processRefund={processRefund} refundData = {refundData} refundInitValue={refundInitValue}/>}
        {mainInvoice && <InvoicesParent
          setIsSureModalOpen={setIsSureModalOpen}
          setDeleteFunction={setDeleteFunction}
          moment={moment}
          mainInvoice={mainInvoice}
          incidentals={incidentals}
          setIsInvoiceVisible={setIsInvoiceVisible} 
          downloadInvoice={downloadInvoice}
          manageIncidentalInvoice={manageIncidentalInvoice}
          setEditInvoiceInitialValue={setEditInvoiceInitialValue}
          propagationStop={propagationStop}
          />}
      </Box> : <CreateEditInvoiceSection isInvoiceProcessing={isInvoiceProcessing} setEditInvoiceInitialValue={setEditInvoiceInitialValue} editInvoiceInitialValue={editInvoiceInitialValue} cancelInvoiceCreateEdit={setIsInvoiceVisible} manageIncidentalInvoice={manageIncidentalInvoice}/>}
    </>
    // Customizable Area End
  );
};

// Customizable Area Start

       const BookingRefundStatusModal=({headingObject,isModalOpen,setStatusModal}:BookingRefundStatusModalType)=>{
        const {heading,sub_heading} = headingObject;
          return <MuiModal open={isModalOpen}>
             <StatusModal className="status_update_modal" style={{minWidth:'25rem'}}>
                <MuiResuableStatusModalBody
                  heading = {heading}
                  subHeading = {sub_heading}
                  iconCont={StatusGeneralIconContainer}
                >
                  <FormSubmitButton onClick={()=>setStatusModal(false)} fullWidth data-testid="dashboard_button">Go back to Booking</FormSubmitButton>
                </MuiResuableStatusModalBody>
             </StatusModal>
          </MuiModal>
       }

       const PaymentStatusModal=({isNewCardUsed,existingCardFormikRef,newCardFormRef,isApiProcessing,paymentStatusAndOpen,setPaymentStatusAndOpen}:PaymentStatusOpenAndStatusComp)=>{
        const {isOpen,isSuccess} = paymentStatusAndOpen;
        const heading =  !isSuccess ? "Payment Failed" : "Payment Successful";
        const subheading = !isSuccess  ?'unsuccessful' : 'successful'
        
        return <PaymentStatusParent isOpen={isOpen}>
          <PaymentStatusIcon isSuccessModal={isSuccess}/>
          <ModalHeading>{heading}</ModalHeading>  
          <Box> Your payment was {subheading}</Box>
          {isSuccess ? <SuccessPaymentModalFooter isNewCardUsed={isNewCardUsed} existingCardFormikRef={existingCardFormikRef} newCardFormRef={newCardFormRef} setPaymentStatusAndOpen={setPaymentStatusAndOpen}/> : <FailedPaymentModalFooter isNewCardUsed={isNewCardUsed} existingCardFormikRef={existingCardFormikRef} newCardFormRef={newCardFormRef} isApiProcessing={isApiProcessing} setPaymentStatusAndOpen={setPaymentStatusAndOpen}/>}
        </PaymentStatusParent>
       }

       const FailedPaymentModalFooter=({isNewCardUsed,existingCardFormikRef,newCardFormRef,isApiProcessing,setPaymentStatusAndOpen}:SuccessPaymentModalOpenType & {isApiProcessing:boolean})=>{
        const tryAgainText = !isApiProcessing ? 'Try Again' : 'Proceeding...'
        const handleReprocess = ()=>{
          if(isNewCardUsed){
            (newCardFormRef as unknown as {current : {handleSubmit : ()=>void}}).current.handleSubmit();
          }else{
            (existingCardFormikRef as unknown as {current : {handleSubmit : ()=>void}}).current.handleSubmit();
          }
        }
        return <>
           <FormSubmitButton onClick={()=>handleReprocess()} disabled={isApiProcessing} fullWidth data-testid="reprocess_payment"><Replay style={{marginRight:"0.5rem",transform: 'scaleX(-1) rotate(-70deg)'}}/> {tryAgainText}</FormSubmitButton>
           <AddLegtTypeButton data-testid = "back_to_booking_sreen" fullWidth onClick={()=>setPaymentStatusAndOpen({isOpen:false,isSuccess:false})}>Use alternative payment method</AddLegtTypeButton>
        </>
       }

       const SuccessPaymentModalFooter=({setPaymentStatusAndOpen}:SuccessPaymentModalOpenType)=>{
        return <>
            <FormSubmitButton data-testid = "back_to_booking" fullWidth onClick={()=>setPaymentStatusAndOpen({isOpen:false,isSuccess:false})}>Back to Booking Details</FormSubmitButton>
        </>
       }


       const StatusGeneralIconContainer =()=>{
        return <StatusGeneralParentContainer 
        styles={{padding:'1.2rem 1rem'}}
        >
        <RefundStatusIcon 
        />
        </StatusGeneralParentContainer>
      }

      const SureModalButtonContainer=({cancel,submit}:{cancel:()=>void,submit:()=>void})=>{
        return <Box style={{display:'flex',justifyContent:"space-between",columnGap:'1.9rem',width:'23rem'}}>
               <AddLegtTypeButton fullWidth data-testid="cancel_sure" onClick={()=>cancel()}>Cancel</AddLegtTypeButton>
               <FormSubmitButton onClick={()=>submit()} fullWidth data-testid="confirm_sure">Yes</FormSubmitButton>
        </Box>
      }

      const CreateEditInvoiceSection=({isInvoiceProcessing,cancelInvoiceCreateEdit,manageIncidentalInvoice,editInvoiceInitialValue,setEditInvoiceInitialValue}:CreateEditInvoiceSectionType)=>{
        const header = editInvoiceInitialValue !== null ? 'Edit' : 'Create'
        return   <Box className="invoice_parent_flex_column">
                  <GoldishSimpleButton
                    style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}
                    data-testId = 'show_main_action'
                    onClick={()=>{cancelInvoiceCreateEdit(false);setEditInvoiceInitialValue(null)}}
                  >
                  <ArrowBack className = 'cf_avinod_arrow_search'/>
                      {header} Invoice
                  </GoldishSimpleButton>
                  <InvoiceContent isInvoiceProcessing={isInvoiceProcessing} setEditInvoiceInitialValue={setEditInvoiceInitialValue} cancelInvoiceCreateEdit={cancelInvoiceCreateEdit} manageIncidentalInvoice={manageIncidentalInvoice} editInvoiceInitialValue={editInvoiceInitialValue}/>
                </Box>
      }

      const InvoicesParent = ({ setIsSureModalOpen,setDeleteFunction,moment, propagationStop, mainInvoice,incidentals,setIsInvoiceVisible,manageIncidentalInvoice,downloadInvoice,setEditInvoiceInitialValue}:InvoiceParentType)=>{
       return <Box className="order_detail_column_cont">
        <Box className="order_detail_head_edit ">
          <GoldishSimpleButton
            style={{
              color: 'white',
              textTransform: "capitalize",
              justifyContent: "start",
            }}
          >
            Invoices
          </GoldishSimpleButton>
        </Box>
        <Box className="order_detail_black_passenger" padding={"1rem"}>
        <InvoiceSection mainInvoice={mainInvoice} downloadInvoice={downloadInvoice} moment={moment}/>
        {incidentals?.map((eachInvoice:IncidentalsInvoiceType,elem_index:number)=>  <IncidentalsInvoice setIsSureModalOpen={setIsSureModalOpen} setDeleteFunction={setDeleteFunction} moment={moment} propagationStop={propagationStop} setEditInvoiceInitialValue={setEditInvoiceInitialValue} downloadInvoice={downloadInvoice} manageIncidentalInvoice={manageIncidentalInvoice} setIsInvoiceVisible={setIsInvoiceVisible} key={elem_index} eachInvoice={eachInvoice.data} invoiceNumber={elem_index}/>)}
        <AddLegtTypeButton  fullWidth data-testId = 'create_invoices' onClick={()=>setIsInvoiceVisible(true)}>+ Create New Invoice</AddLegtTypeButton>
        </Box>
      </Box>
      }

      const each_invoice_form = {item_name:"",price:'',quantity:""};
      const invoiceInitialValues = {
        invoice_name:"",
        invoice_items_attributes: [
          {...each_invoice_form}
        ]
      }

    const calcualteTotalAmount=(invoice:typeof invoiceInitialValues)  =>{
      let totalAmount = 0;
      invoice.invoice_items_attributes.forEach((item:typeof each_invoice_form)=>{
        const price = parseFloat(item.price);
        const quantity = parseFloat(item.quantity);
        if(!isNaN(price) && !isNaN(quantity)){
          totalAmount += price* quantity;
        }
      })
      return totalAmount;
    }

    const InvoiceContent = ({isInvoiceProcessing,cancelInvoiceCreateEdit,manageIncidentalInvoice,editInvoiceInitialValue,setEditInvoiceInitialValue}:CreateEditInvoiceSectionType) => {
      const initialValue = editInvoiceInitialValue !== null ? editInvoiceInitialValue.initial_value : invoiceInitialValues;
      const method = editInvoiceInitialValue !== null ? 'edit' : 'create';
      const scrollBottom=()=>{
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      }

      return (
        <div className="invoice_parent" style={{marginBottom:"7rem",padding:'3rem',maxHeight:'none'}}>
          <Formik
            initialValues={initialValue}
            onSubmit={(values) => {
              manageIncidentalInvoice(values,method,editInvoiceInitialValue?.invoiceId);
            }}
            enableReinitialize
            validateOnChange
            validateOnBlur
            validationSchema={invoiceValidationSchema}
          >
            {(formikProps) => {
              return (
                <>
                  <Grid item sm={3}>
                    <Box>
                      <PaymentLabel>Invoice Name</PaymentLabel>
                      <PaymentTexfields data-testid ='invoice_name_incidental' value={formikProps.values.invoice_name} fullWidth placeholder="Add Invoice Name" isFocusedTypeInput={false} autoComplete='off' onChange={formikProps.handleChange} id='invoice_name' />
                      {formikProps.touched.invoice_name && formikProps.errors.invoice_name && (
                    <Box className="login_valid_error" id="profile_error_properties">
                      {formikProps.errors.invoice_name}
                    </Box>
                  )}
                    </Box>
                  </Grid>

                  <Box fontSize={"1.5rem"} marginTop={'0.8rem'}>Order Details</Box>
                  <Box color={"#888888"} fontSize={"1rem"}>
                  Type individual line items below.
                  </Box>
                  <Box>
                    <Box className="invoice_header">
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item sm={6}>
                              Item
                            </Grid>
                            <Grid item sm={2}>
                              Price
                            </Grid>
                            <Grid item sm={2}>
                              Quantity
                            </Grid>
                            <Grid item sm={1} style={{ textAlign: "right" }}>
                              Sub-Total
                            </Grid>
                          </Grid>
                    </Box>

                    <FieldArray
                      name="invoice_items_attributes"
                      render={(arrayHelpers: ArrayHelpers) => (
                        <>
                          {formikProps.values.invoice_items_attributes.map((_formElem,formIndex:number) => {
                            return (
                              <Box
                                key={formIndex}
                                className="invoice_header"
                                style={{ backgroundColor: "inherit", padding: "0" }}
                              >
                                <EachInvoiceForm formikProps={formikProps} formIndex={formIndex} arrayHelpers={arrayHelpers}/>
                              </Box>
                            );
                          })}
                                 <Box className="add_item_invoice">
                                      <AddLegtTypeButton
                                        style={{
                                          margin:'1rem 0rem',
                                          fontSize:'0.8rem',
                                          height:'3rem'
                                        }}
                                        onClick={()=>{arrayHelpers.push(each_invoice_form);setTimeout(scrollBottom);scrollBottom();}}
                                        data-testId = 'add_item'
                                      >
                                        + Add Line Item
                                      </AddLegtTypeButton>
                                  </Box>
                                  <Box style={{borderTop:'1px solid #3E4247'}} className="invoice_section_head" textAlign={'right'} padding={'1rem'}>
                                    <Box>Total: ${calcualteTotalAmount(formikProps.values).toLocaleString()}</Box>
                                  </Box>
                        </>
                      )}
                    />



                  </Box>
           

                  <Footer>
                  <SubmitAndCancel isDisableSubmit={formikProps.values.invoice_items_attributes.length === 0 || isInvoiceProcessing} cancelForm={cancelInvoiceCreateEdit} submit={()=>formikProps.handleSubmit()} setEditInvoiceInitialValue={setEditInvoiceInitialValue}/>
                  </Footer>
                </>
              );
            }}
          </Formik>
        </div>
      );
    };


      const SubmitAndCancel=({isDisableSubmit,cancelForm,submit,setEditInvoiceInitialValue}:{cancelForm: React.Dispatch<React.SetStateAction<boolean>>,submit:()=>void, setEditInvoiceInitialValue: setEditInvoiceInitialValueType,isDisableSubmit:boolean})=>{
        return <Box className="profile_submission">
        <FormSubmitButton  style={{color:"white",background:"#3E4247"}}  fullWidth onClick={()=>{cancelForm(false);setEditInvoiceInitialValue(null)}} data-testId = 'cancel_button'>Cancel</FormSubmitButton>
        <FormSubmitButton disabled={isDisableSubmit} fullWidth className="profile_test_submit" data-testId = 'save_changes' onClick={()=>submit()}>Save</FormSubmitButton>
        </Box>
    }

    const EachInvoiceForm=({formikProps,formIndex,arrayHelpers}:{formikProps : FormikProps<IncidentalInvoiceFormType['initial_value']>,formIndex:number,arrayHelpers:ArrayHelpers})=>{
      const {values} = formikProps;
      const {remove} = arrayHelpers
      const price = lodashGet(values,`invoice_items_attributes[${formIndex}].price` as unknown as string) * lodashGet(values,`invoice_items_attributes[${formIndex}].quantity` as unknown as string)
      return  <Grid container justifyContent="space-between" alignItems="center" style={{borderBottom:'1px solid #3e4247',padding:'1.6rem 1.5rem',position:"relative"}}>
      <Grid item sm={6} >
        <InputWithLabel label="Item Name" keyName={`invoice_items_attributes[${formIndex}].item_name`} formikProps={formikProps} placeholder="Add Item Name"/>
      </Grid>
      <Grid item sm={2} >
      <InputWithLabel label="Price" keyName={`invoice_items_attributes[${formIndex}].price`} formikProps={formikProps} placeholder="Add Price"/>
      </Grid>
      <Grid item sm={2} >
      <InputWithLabel label="Quantity" keyName={`invoice_items_attributes[${formIndex}].quantity`} formikProps={formikProps} placeholder="Add Quantity"/>
      </Grid>
      <Grid item sm={1} style={{textAlign:'right',color:'#94A3B8'}} className="truncate-container" >
        <Box display={'flex'} gridColumnGap={'0.4rem'} alignItems={'center'} justifyContent={'end'} >
          <Box>${Math.round(price).toLocaleString()}</Box>
         <IconButton data-testId = 'delete_invoice_index' onClick={()=>remove(formIndex)} >
       <DeleteOutline  className="advance_search_remove_leg"/>
        </IconButton> 
        </Box>
      </Grid>
    </Grid>
    }
  
const InputWithLabel=({label,placeholder,formikProps,keyName}:{label:string,placeholder:string,formikProps:FormikProps<IncidentalInvoiceFormType['initial_value']>,keyName:string})=>{
  const {values,touched,errors,setFieldValue}  = formikProps;
  return <Box>
  <PaymentLabel 
   style={{ margin: "0", marginBottom: "5px" }}
   >
    {label}
  </PaymentLabel>
  <PaymentTexfields
    heightOverride = '3rem'
    fontSize={'1rem'}
    id={keyName}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(keyName, event.target.value)
    }}  
    fullWidth
    data-testId = 'invoice_textfield'
    className="label_text_field"
    value={lodashGet(values,keyName)}
    placeholder={placeholder}
    autoComplete="off"
  />
  {lodashGet(touched,keyName) && lodashGet(errors,keyName) && (
      <Box className="login_valid_error" id="profile_error_properties">
        {lodashGet(errors,keyName)}
      </Box>
    )}
</Box>
}

const BookingNumberHeading=({redirectBack}:{redirectBack:()=>void})=>{
  return  <GoldishSimpleButton
  onClick={()=>redirectBack()}
  style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}
  className='cf-avinode-search'
  data-testId = 'redirect_back'
>
<ArrowBack className = 'cf_avinod_arrow_search'/>
  Booking details
</GoldishSimpleButton>
}


const PaymentSummary=({isPaymentSummarySaving,commaToFigure,paymentSummary,submitPaymentSummary,setIsPaymentSumaryModalOpen, isPaymentSumaryModalOpen,bookingType}:PaymentSummaryModalType)=>{
  const {booking_amount,taxes, segment_fees, order_total,landing_fee,payment_processing_fee} = paymentSummary;
  return <Box className = 'order_detail_column_cont'>
     <Box className = 'order_detail_head_edit'>
     <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize" }}>Payment Summary</GoldishSimpleButton>
     <PaymentSummaryEditModal isPaymentSummarySaving={isPaymentSummarySaving} commaToFigure={commaToFigure} paymentSummary={paymentSummary} submitPaymentSummary={submitPaymentSummary} setIsPaymentSumaryModalOpen={setIsPaymentSumaryModalOpen} isPaymentSumaryModalOpen= {isPaymentSumaryModalOpen} bookingType={bookingType}/>
     </Box> 

     <Box className = 'order_detail_black_round'>
     <EachRowInfo keyName={'Flight Price'} valueName={booking_amount} isPrice={true}/>
     <EachRowInfo keyName={'Landing Fee'} valueName={landing_fee} isPrice={true}/>
     <EachRowInfo keyName={'Tax'} valueName={taxes} isPrice={true}/>
     <EachRowInfo keyName={'Segment Fees'} valueName={segment_fees} isPrice={true}/>
     <EachRowInfo keyName={'Payment Processing Fee'} valueName={payment_processing_fee} isPrice={true}/>
     <EachRowInfo keyName={'Order Total'} valueName={order_total} isPrice={true}/>

     </Box>
  </Box>
}



const OrderDetailEditModal = ({isOrderDetailsSaving,vendorStatusOption,paymentStatusOptions ,moment, openAddNewClientModal,orderDetails,optionsbyName,optionsbyId,searchClientByNameFunction,updateOrderDetails,setIsOrderDetailsModelOpen,isOrderDetailsModelOpen,bookingType}:OrderDetailEditModalType) => {
  const classes = useStyles();
  let debounceTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const handleInputChange = (valueToSearch:string,type:"full_name" | 'account_id') => {
    if (debounceTimeoutId) clearTimeout(debounceTimeoutId);

    debounceTimeoutId = setTimeout(() => {
      searchClientByNameFunction(valueToSearch,type)
    }, debounceTime);
  };

  const updateOrderClient=(values:OrderDetailsType & {account_id:string})=>{
    const {account_id:{account_id},payment_status,vendor_status} = values;
    let order_data = {
      "client_id": account_id,
      payment_status:(paymentStatusOptions.find((option:SelectMenuOptionType) => option.stringValue === payment_status) as SelectMenuOptionType).value,
      vendor_status:(vendorStatusOption.find((option:SelectMenuOptionType) => option.stringValue === vendor_status) as SelectMenuOptionType).value
    }
    updateOrderDetails(order_data as unknown as OrderDetailsType);
  }

  return (
    <>
      {bookingType !== "PreviousBooking" && (
        <Box onClick={() => setIsOrderDetailsModelOpen(true)} data-testid="edit_order_details">
        <Edit style={{ color: colors.GOLD_COLOR }} />
      </Box>
      )}
      <MuiModal
        open={isOrderDetailsModelOpen}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
        >
          <Box className='order_detail_view_heading_parent'>
            <GoldishSimpleButton
              style={{ color: 'white', textTransform: "capitalize" }}
            >
              Edit Order Details
            </GoldishSimpleButton>
              <Button variant="text" style={{textTransform:'none',color:colors.GOLD_COLOR,fontSize:'1.2rem'}} onClick={()=>{setIsOrderDetailsModelOpen(false);openAddNewClientModal()}} data-testid="add_new_client">+ Create New Client</Button>
          </Box>

          <Formik
              enableReinitialize
              initialValues={orderDetails}
              onSubmit={(values) => {
                updateOrderClient(values as OrderDetailsType & {account_id:string});
              }}
              validationSchema={order_details_schema}
              validateOnChange
              validateOnBlur
            >
              {({
                setFieldValue,
                handleChange,
                handleSubmit,
                errors,
                values,
                setFieldTouched
              }) => (
                <>  <Grid container spacing={4} alignItems="flex-end">
                <Grid item lg={4} md={4} sm={12}>
                  <PaymentLabel>Client Name</PaymentLabel>
                  <SearchStyledAutocomplete
                    onChange={(_clientnameeve: Event, newValue: AnnoutSearchType) => {setFieldValue('client_name',newValue?.attributes);setFieldValue('account_id',newValue?.attributes || {account_id:null})}}
                    getOptionLabel={(option: AnnoutSearchType) => option?.full_name || option?.attributes?.full_name}
                    options={[...optionsbyName]}
                    data-testid="client_name_autcomplete"
                    renderOption={(option: AnnoutSearchType,{selected}:{selected:boolean}) => <ViewOption isSelected={selected} option={option.attributes}/>}
                    noOptionsText={<Box className={classes.noOptions}>No options</Box>}
                    classes={{
                      paper: classes.dropdown, 
                    }}
                    value={values.client_name}
                    ListboxProps={{ id: "flight_list_container" }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <CustomFormTextField
                        {...params}
                        placeholder='Search Client' 
                        data-testid="client_name_textfeild"
                        onChange={(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>handleInputChange(event.target.value,'full_name')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Box className='profile_hover_icons'>
                              <Search />
                            </Box>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                  <PaymentLabel>Account Id</PaymentLabel>
                  <SearchStyledAutocomplete
                    onChange={(_clientEvent: Event, newValue: AnnoutSearchType) => {setFieldValue('account_id',newValue?.attributes?.profile?.data?.attributes || {account_id:null});setFieldValue('client_name',newValue?.attributes?.profile?.data?.attributes);setFieldTouched('account_id.account_id',true,true);
                    setFieldTouched('client_name.full_name', true, false);}}
                    getOptionLabel={(option: AnnoutSearchType) => `${option?.attributes?.profile?.data?.attributes?.account_id || option?.account_id || ''}`}
                    options={[...optionsbyId]}
                    data-testid="account_id_autcomplete"
                    renderOption={(option: AnnoutSearchType,{selected}:{selected:boolean}) => <ViewOption isSelected={selected} option={option?.attributes?.profile?.data?.attributes}/>}
                    noOptionsText={<Box className={classes.noOptions}>No options</Box>}
                    classes={{
                      paper: classes.dropdown, 
                    }}
                    value={values.account_id}
                    ListboxProps={{ id: "flight_list_container" }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <CustomFormTextField
                        {...params}
                        placeholder='Search Client'
                        data-testid="account_id_textfeild"
                        className={
                          (values as unknown as {account_id:{account_id:string}}).account_id?.account_id === null && (errors.account_id as unknown as {account_id:{account_id:string}})?.account_id
                            ? "register_border_error"
                            : ""
                        }
                        onChange={(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>handleInputChange(event.target.value,'account_id')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Box className='profile_hover_icons'>
                              <Search />
                            </Box>
                          ),
                        }}
                      />
                    )}
                  />
                   <Box
                          className="login_valid_error"
                          id="profile_error_properties"
                        >
                         {(values as unknown as {account_id:{account_id:string}}).account_id?.account_id === null && (errors.account_id as unknown as {account_id:{account_id:string}})?.account_id}
                        </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                  <PaymentLabel>Order Date</PaymentLabel>
                  <CustomFormTextField
                        placeholder='Order Date'
                        fullWidth
                        disabled
                        value={moment(values.order_date).format("DD-MMM-YYYY")}
                        InputProps={{                      
                          endAdornment: (
                            <Box className='profile_hover_icons'>
                              <DateRangeOutlined />
                            </Box>
                          ),
                        }}
                      />
                </Grid>
    
               <Grid item lg={4} md={4} sm={12}>

                 <PaymentLabel>Payment Status</PaymentLabel>
                 <CustomSelectOrderDetails
                        labelId='demo-simple-select-label'
                        variant='outlined'
                        className='admin_job_title'
                        inputProps={{
                          IconComponent: KeyboardArrowDown,
                        }}
                        value={values.payment_status}
                        name='payment_status'
                        onChange={handleChange}
                        fullWidth
                        MenuProps={adminSelectProp as MenuProps}
                      >
                        {
                        (paymentStatusOptions).map(({label,stringValue}:SelectMenuOptionType)=>{
                          return <MenuItem value={stringValue} >{label}</MenuItem>
                        })}
                        <MenuItem value={'none'} disabled style={{display:'none'}}>Select Status</MenuItem>
                  </CustomSelectOrderDetails>
                </Grid>
    
                <Grid item lg={4} md={4} sm={12}>
                  <PaymentLabel>Vendor Status</PaymentLabel>
                  <CustomSelectOrderDetails
                        labelId='demo-simple-select-label'
                        className='admin_job_title'
                        inputProps={{
                          IconComponent: KeyboardArrowDown,
                        }}
                        value={values.vendor_status}
                        name='vendor_status'
                        variant='outlined'
                        onChange={handleChange}
                        fullWidth
                        MenuProps={adminSelectProp as MenuProps}
                      >
                        {
                        (vendorStatusOption).map(({label,stringValue}:SelectMenuOptionType)=>{
                          return <MenuItem value={stringValue} >{label}</MenuItem>
                        })}
                        <MenuItem value={'none'} disabled style={{display:'none'}}>Select Status</MenuItem>
                  </CustomSelectOrderDetails>
                </Grid>
    
                <Grid item lg={4} md={4} sm={12}>
                  <ViewDetailsSpecific viewHead="Order Origin" viewValue={values.order_origin}/>
                </Grid>
              </Grid>
            <CancelAndSaveChanges isDisabled={isOrderDetailsSaving} isSaving={isOrderDetailsSaving} cancel={()=>setIsOrderDetailsModelOpen(false)} submit={()=>handleSubmit()}/></> )}</Formik> 
        </StyledModalContent>
      </MuiModal>
    </>
  )
}


const ViewOption=({option,isSelected}:{option:AnnoutSearchType['attributes'] | AnnoutSearchType['attributes']['profile']['data']['attributes'],isSelected:boolean})=>{
  return <Box className={isSelected ? 'advance_search_selected_option':''}>{option?.full_name + " - " + option?.account_id}</Box>
}

  const ViewDetailsSpecific=({viewHead,viewValue}:{viewHead:string,viewValue:string | React.ReactNode | null})=>{
    return <Box className="view_details_specific">
        <PaymentLabel>{viewHead}</PaymentLabel>
        <Box style={{textTransform:"capitalize"}}>{viewValue || 'NA'}</Box>
    </Box>
  }


  const PaymentSummaryEditModal = ({ isPaymentSummarySaving,commaToFigure,paymentSummary, submitPaymentSummary, isPaymentSumaryModalOpen, setIsPaymentSumaryModalOpen,bookingType }: PaymentSummaryModalType) => {

    const getValues = (values:PaymentSummarySubmitType)  =>{
      const {booking_amount, taxes, segment_fees} = values;
      let object = {data :{
        "tax":taxes ,
        "flight_price": booking_amount, 
        "segment_fee": segment_fees}
      }
      return object;
    }


    return (
      <>
        {bookingType !== "PreviousBooking" && (
          <Box onClick={() => setIsPaymentSumaryModalOpen(true)}
          className="submit_summary_payment_open"
           data-testid="edit_payment_summary" 
           >
          <Edit style={{ color: colors.GOLD_COLOR }} />
        </Box>
        )}
        <MuiModal open={isPaymentSumaryModalOpen}>
          <StyledModalContent
            className="large_modal_styling booking_section"
          >
            <Box className="order_detail_view_heading_parent">
              <GoldishSimpleButton
                style={{ color: 'white', textTransform: "capitalize" }}
              >
                Edit Payment Summary
              </GoldishSimpleButton>
            </Box>
  
            <Formik
              enableReinitialize
              initialValues={{...paymentSummary,taxes:paymentSummary.taxes,segment_fees: paymentSummary.segment_fees,booking_amount:paymentSummary.booking_amount}}
              onSubmit={(values) => {
                submitPaymentSummary(getValues(values as unknown as PaymentSummarySubmitType));
              }}
              validationSchema={payment_summary_validation_schema}
              validateOnChange
              validateOnBlur
            >
              {({
                handleChange,
                handleSubmit,
                touched,
                errors,
                values,
                setFieldValue
              }) => (
                <>
                  <Grid container spacing={4}>
                    <Grid item lg={4} md={4} sm={12}>
                      <PaymentLabel>Flight Price</PaymentLabel>
                      <CustomFormTextField
                        data-testid = 'booking_amount_input'
                        autoComplete  = 'off'
                        placeholder="Enter Booking Amount"
                        fullWidth
                        value={values.booking_amount}
                        name="booking_amount"
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"booking_amount")}
                        className={
                          touched.booking_amount && errors.booking_amount
                            ? "register_border_error"
                            : ""
                        }
                        InputProps = {dollarTextFieldInputProps}
                      />
                      {touched.booking_amount && errors.booking_amount && (
                        <Box
                          className="login_valid_error"
                          id="profile_error_properties"
                        >
                          {errors.booking_amount}
                        </Box>
                      )}
                    </Grid>
  
                    <Grid item lg={4} md={4} sm={12}>
                      <PaymentLabel>Tax</PaymentLabel>
                      <CustomFormTextField
                        data-testid = 'tax_input'
                        autoComplete  = 'off'
                        placeholder="Enter Taxes"
                        fullWidth
                        value={values.taxes}
                        name="taxes"
                        className={
                          touched.taxes && errors.taxes
                            ? "register_border_error"
                            : ""
                        }
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"taxes")}
                        InputProps = {dollarTextFieldInputProps}
                      />
                      {touched.taxes && errors.taxes && (
                        <Box
                          className="login_valid_error"
                          id="profile_error_properties"
                        >
                          {errors.taxes}
                        </Box>
                      )}
                    </Grid>
  
                    <Grid item lg={4} md={4} sm={12}>
                      <PaymentLabel>Segment Fee</PaymentLabel>
                      <CustomFormTextField
                        data-testid = 'segment_input'
                        autoComplete  = 'off'
                        placeholder="Enter Segment Fees"
                        fullWidth
                        value={values.segment_fees}
                        name="segment_fees"
                        onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"segment_fees")}
                        className={
                          touched.segment_fees && errors.segment_fees
                            ? "register_border_error"
                            : ""
                        }
                        InputProps = {dollarTextFieldInputProps}
                      />
                      {touched.segment_fees && errors.segment_fees && (
                        <Box
                          className="login_valid_error"
                          id="profile_error_properties"
                        >
                          {errors.segment_fees}
                        </Box>
                      )}
                    </Grid>
  
                    <Grid item lg={4} md={4} sm={12}>
                      <ViewDetailsSpecific
                        viewHead="Landing Fee"
                        viewValue={`$${paymentSummary.landing_fee}`}
                      />
                    </Grid>
  
                    <Grid item lg={4} md={4} sm={12}>
                      <ViewDetailsSpecific
                        viewHead="Payment Processing Fee"
                        viewValue={`$${paymentSummary.payment_processing_fee}`}
                      />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12}>
                      <ViewDetailsSpecific
                        viewHead="Order Total"
                        viewValue={`$` + paymentSummary.order_total}
                      />
                    </Grid>
                  </Grid>
                  <CancelAndSaveChanges
                    cancel={() => setIsPaymentSumaryModalOpen(false)}
                    submit={() => handleSubmit()}
                    isSaving={isPaymentSummarySaving}
                    isDisabled={isPaymentSummarySaving}
                  />
                </>
              )}
            </Formik>
          </StyledModalContent>
        </MuiModal>
      </>
    );
  };
  
  


const Commission=({isProfitCommissionSaving,commission,adminData,setIsCommissionModelOpen,isCommissionModelOpen,updateCommission,bookingType}:CommissionEditModalType)=>{
  const {sales_rep, commission_percentage, commission_value,profit_exc_landing_fee,profit_exc_landing_fee_percent,profit_inc_landing_fee,profit_inc_landing_fee_percent} = commission;
  const figure_exc_inc = (profit_exc_landing_fee_percent:string | null,profit_exc_landing_fee: number |null)=> profit_exc_landing_fee ? `$${profit_exc_landing_fee.toLocaleString()} (${profit_exc_landing_fee_percent})` : null;
  return <Box className = 'order_detail_column_cont'>
     <Box className = 'order_detail_head_edit'>
     <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize" }}>Profit & Commission</GoldishSimpleButton>
        <CommissionEditModal isProfitCommissionSaving={isProfitCommissionSaving} updateCommission={updateCommission} adminData={adminData} commission={commission} setIsCommissionModelOpen={setIsCommissionModelOpen} isCommissionModelOpen={isCommissionModelOpen} bookingType={bookingType}/>
     </Box> 

     <Box className = 'order_detail_black_round'>
          <EachRowInfo keyName={'Profit (exc. Landing Fee)'} valueName={figure_exc_inc(profit_exc_landing_fee_percent,profit_exc_landing_fee)}/>
          <EachRowInfo keyName={'Profit (inc. Landing Fee)'} valueName={figure_exc_inc(profit_inc_landing_fee_percent,profit_inc_landing_fee)}/>
          <EachRowInfo keyName={'Sales Rep.'} valueName={sales_rep}/>
          <EachRowInfo keyName={'Commission Percentage'} valueName={commission_percentage}/>
          <EachRowInfo keyName={'Commission Value'} valueName={commission_value} isPrice={true}/>
     </Box>
  </Box>
}

const CommissionEditModal = ({isProfitCommissionSaving,adminData,commission, setIsCommissionModelOpen, isCommissionModelOpen,updateCommission,bookingType}:CommissionEditModalType) => {
  const classes = useStyles();
  const { commission_percentage, commission_value,profit_exc_landing_fee,profit_exc_landing_fee_percent,profit_inc_landing_fee,profit_inc_landing_fee_percent} = commission;
  const figure_exc_inc = (profit_exc_landing_fee_percent:string | null,profit_exc_landing_fee: number |null)=> profit_exc_landing_fee ? `${profit_exc_landing_fee.toLocaleString()} (${profit_exc_landing_fee_percent})` : null;
  return (
    <>
      {bookingType !== "PreviousBooking" && (
        <Box onClick={() => setIsCommissionModelOpen(true)} data-testid="edit_commission_summary">
        <Edit style={{ color: colors.GOLD_COLOR }} />
      </Box>
      )}
      <MuiModal
        open={isCommissionModelOpen}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          style={{display:'flex',flexDirection:'column',rowGap:'2rem'}}
        >
          <Box className='order_detail_view_heading_parent'>
            <GoldishSimpleButton
              style={{ color: 'white', textTransform: "capitalize",marginTop:'-0.5rem'}}
            >
              Edit Profit & Commission
            </GoldishSimpleButton>
          </Box>

          <Formik
              enableReinitialize
              initialValues={commission}
              onSubmit={(values) => {
                updateCommission(values.admin_id as unknown as string);
              }}
              validationSchema={commission_schema}
              validateOnChange
              validateOnBlur
            >
              {({
                setFieldValue,
                handleSubmit,
                touched,
                errors,
                values,
              }) => (
                <><Grid container spacing={4}>

                  <Grid item lg={6} md={6} sm={12}>
                    <ViewDetailsSpecific viewHead="Profit (exc. Landing Fee)" viewValue={`$${figure_exc_inc(profit_exc_landing_fee_percent,profit_exc_landing_fee)}`}/>
                  </Grid>
      
      
                  <Grid item lg={6} md={6} sm={12}>
                    <ViewDetailsSpecific viewHead="Profit (inc. Landing Fee)" viewValue={`$${figure_exc_inc(profit_inc_landing_fee_percent,profit_inc_landing_fee)}`}/>
                  </Grid>

                 <Grid item lg={12} md={12} sm={12}>
                        <PaymentLabel>Sales Rep.</PaymentLabel>
                        <SearchStyledAutocomplete
                          onChange={(_salesevent: Event, newValue: AnnoutSearchType) => {setFieldValue('sales_rep',newValue?.full_name || null); setFieldValue('admin_id',newValue?.account_id || null)}}
                          getOptionLabel={(option:AnnoutSearchType) => option.full_name}
                          options={[...adminData]}
                          value={{full_name:values.sales_rep}}
                          data-testid="sales_rep_autcomplete"
                          renderOption={(option: AnnoutSearchType,{selected}:{selected:boolean}) => <Box className={selected ? 'advance_search_selected_option':''}>{option.full_name}</Box>}
                          noOptionsText={<Box className={classes.noOptions}>No options</Box>}
                          classes={{
                            paper: classes.dropdown, 
                          }}
                          ListboxProps={{ id: "flight_list_container" }}
                          renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomFormTextField
                              {...params}
                              className={touched.sales_rep && errors.sales_rep ? "register_border_error": ""}
                              
                              placeholder='Search Client' 
                              data-testid="sales_rep_textfeild"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Box className='profile_hover_icons'>
                                    <Search />
                                  </Box>
                                ),
                              }}
                            />
                          )}
                        />
                         {touched.sales_rep && errors.sales_rep && (
                        <Box
                          className="login_valid_error"
                          id="profile_error_properties"
                        >
                          {errors.sales_rep}
                        </Box>
                      )}
                  </Grid>
      
                  <Grid item lg={6} md={6} sm={12}>
                    <ViewDetailsSpecific viewHead="Commission Percentage" viewValue={commission_percentage}/>
                  </Grid>
      
      
                  <Grid item lg={6} md={6} sm={12}>
                    <ViewDetailsSpecific viewHead="Commission Value" viewValue={`$${commission_value}`}/>
                  </Grid>
      
                </Grid>
                    <CancelAndSaveChanges isDisabled={isProfitCommissionSaving} isSaving={isProfitCommissionSaving} cancel={()=>setIsCommissionModelOpen(false)} submit={()=>handleSubmit()}/></>)}</Formik>
        </StyledModalContent>
      </MuiModal>
    </>
  )
}

const EachRowInfo=({cursor = 'default',valueClick=()=>{},keyName='John',valueName='- - -',isLessSize=false,isPrice=false,textTransform='capitalize'}:EachRowInfoType)=>{
  const parentBoxClassName = `order_detail_each_row ${isLessSize ? 'order_detail_less_size' : ''}`;
  let value = valueName;
  let ValueToShow = (figure:string | number)=>isPrice ? ('$' + figure) : figure;
  if(!React.isValidElement(valueName)){
    value = value !== null && value !== '' ? ValueToShow(value as string) : '- - -';
  }
  return <Box className = {parentBoxClassName}>
    <Box className = 'order_detail_color_grey'>{keyName}</Box>
    <Box className={cursor !== 'default' ? "client_name_id" : ''} data-testid="value_click" onClick={()=>valueClick()} style={{textTransform:textTransform}}>{value}</Box>
  </Box>
}



const OrderDetailSection=({navigationToClientDetails,isOrderDetailsSaving,isPaymentSummarySaving,isProfitCommissionSaving,commaToFigure,booking_no,vendorStatusOption,paymentStatusOptions, moment,orderDetails,paymentSummary,commission,submitPaymentSummary,isPaymentSumaryModalOpen,setIsPaymentSumaryModalOpen,optionsbyName,optionsbyId,searchClientByNameFunction,updateOrderDetails,isOrderDetailsModelOpen,setIsOrderDetailsModelOpen,adminData,isCommissionModelOpen,setIsCommissionModelOpen,updateCommission,setIsNewClientModalOpen,isNewClientModalOpen,bookingType,airports,getSearchedAirports,focusedField,setFocusedField,createNewProfile,clientForm}:OrderDetailsAddCompType & PaymentSummaryModalType & CommissionEditModalType & {booking_no:string})=>{
  return <Box>
      <Grid container spacing={6} style={{padding:"1rem 0rem"}}>
        <Grid lg={6}md={12} sm={12} xs={12} item><OrderDetails navigationToClientDetails={navigationToClientDetails} isOrderDetailsSaving={isOrderDetailsSaving} booking_no={booking_no} vendorStatusOption={vendorStatusOption} paymentStatusOptions={paymentStatusOptions} moment={moment} setIsNewClientModalOpen={setIsNewClientModalOpen} isNewClientModalOpen={isNewClientModalOpen} isOrderDetailsModelOpen={isOrderDetailsModelOpen} setIsOrderDetailsModelOpen={setIsOrderDetailsModelOpen} searchClientByNameFunction={searchClientByNameFunction} orderDetails={orderDetails} optionsbyName ={optionsbyName} optionsbyId={optionsbyId} updateOrderDetails={updateOrderDetails} bookingType={bookingType} airports={airports} getSearchedAirports={getSearchedAirports} focusedField={focusedField} setFocusedField={setFocusedField} createNewProfile={createNewProfile} clientForm = {clientForm} /></Grid>
        <Grid lg={6}md={12} sm={12} xs={12} item>
          <Box className='order_detail_column_cont'>
          <PaymentSummary isPaymentSummarySaving={isPaymentSummarySaving} commaToFigure={commaToFigure} paymentSummary={paymentSummary} submitPaymentSummary={submitPaymentSummary} isPaymentSumaryModalOpen={isPaymentSumaryModalOpen} setIsPaymentSumaryModalOpen={setIsPaymentSumaryModalOpen} bookingType={bookingType}/>
          <Commission isProfitCommissionSaving={isProfitCommissionSaving} updateCommission={updateCommission} commission={commission} adminData={adminData} isCommissionModelOpen={isCommissionModelOpen} setIsCommissionModelOpen={setIsCommissionModelOpen} bookingType={bookingType}/>
          </Box>
        </Grid>
      </Grid>
  </Box>
}

const OrderDetails=({navigationToClientDetails,isOrderDetailsSaving,booking_no,vendorStatusOption,paymentStatusOptions,moment,orderDetails,optionsbyName,optionsbyId,searchClientByNameFunction,updateOrderDetails,setIsOrderDetailsModelOpen,isOrderDetailsModelOpen,setIsNewClientModalOpen,isNewClientModalOpen,bookingType,airports,createNewProfile,focusedField,setFocusedField,clientForm,getSearchedAirports}:OrderDetailsAddCompType & {booking_no:string})=>{
  const {client_name:{full_name}, account_id:{account_id}, order_origin, order_date, payment_status, vendor_status} = orderDetails;
  const vendor_cancel = vendor_status === 'vendor_confirmed' ? {value:'Vendor Confirmed',color:'#20E70E'} : {value:'Trip Cancelled',color:'#FE4023'};
  const vendor_status_val = vendor_status === 'vendor_pending'  ? {value : 'Vendor Pending',color:'#FFB833'} : vendor_cancel;
  const vendor_val:{value:string | null , color:string} = vendor_status !== null ? vendor_status_val : {value:null,color:''};

  return <Box className = 'order_detail_column_cont' >
     <Box className = 'order_detail_head_edit'>
     <GoldishSimpleButton fullWidth={false} style={{ color: 'white', textTransform: "capitalize"  }}>Order Details</GoldishSimpleButton>
     <OrderDetailEditModal isOrderDetailsSaving={isOrderDetailsSaving} vendorStatusOption={vendorStatusOption} paymentStatusOptions={paymentStatusOptions} moment={moment} isOrderDetailsModelOpen={isOrderDetailsModelOpen} setIsOrderDetailsModelOpen={setIsOrderDetailsModelOpen} updateOrderDetails={updateOrderDetails} searchClientByNameFunction={searchClientByNameFunction} openAddNewClientModal={()=>{setIsNewClientModalOpen(true)}} orderDetails={orderDetails} optionsbyName={optionsbyName} optionsbyId={optionsbyId} bookingType={bookingType}/>
     <AddNewClientModal isOpen={isNewClientModalOpen} cancel={()=>{setIsNewClientModalOpen(false);setIsOrderDetailsModelOpen(true)}} airports={airports} getSearchedAirports={getSearchedAirports} focusedField={focusedField} setFocusedField={setFocusedField} createNewProfile={createNewProfile} clientForm={clientForm}>
      </AddNewClientModal> 
     </Box> 

     <Box className = 'order_detail_black_round'>
        <EachRowInfo keyName={'Booking No'} valueName={booking_no}/>
        <EachRowInfo cursor="pointer" valueClick={()=> account_id ? navigationToClientDetails(account_id) : ()=>{}} keyName={'Client Name'} valueName={full_name}/>
        <EachRowInfo cursor="pointer" keyName={'Account ID'} valueName={account_id} valueClick={()=> account_id ? navigationToClientDetails(account_id) : ()=>{}}/>
        <EachRowInfo keyName={'Order Origin'} valueName={order_origin}/>
        <EachRowInfo keyName={'Order Date'} valueName={order_date ? moment(order_date).format("DD-MMM-YYYY") : null}/>
        <EachRowInfo keyName={'Payment Status'} valueName={<Box className={payment_status === 'paid' ? 'order_detail_view_paid': 'order_detail_view_unpaid'}>{payment_status}</Box> }/>
        <EachRowInfo keyName={'Vendor Status'} valueName={<Box style={{color:vendor_val.color}}>{vendor_val.value}</Box>}/>
     </Box>
  </Box>
}

const FlightItinerary = ({flightIteneraryActive,setFlightIteneraryActive,hoveredLeg,setHoveredLeg,inputFileRefAccordion,convertFilesToBase64,inputFileRef,setDeleteFunction,setIsSureModalOpen,flight_init_values,setIsEditFlightMode,isEditFlightMode,setFlightInternariesInitValues,handleFlightItenariesInitialValues, moment, propagationStop, legsData, toAiportsData, fromAiportsData,getSearchedAirports, isFromAirportTouched,flightInternariesInitValues,updateFlightItenerary, setIsFlightIteneraryModalOpen, isFlightIteneraryModalOpen,bookingType,defaultPrevent }: FlightInteneraryCompType) => {

  return (
    <Box className='order_detail_column_cont'>
         <FlightItineraryEditModal
                  isEditFlightMode={isEditFlightMode}
                  isOpen={isFlightIteneraryModalOpen}
                  cancel={() => setIsFlightIteneraryModalOpen(false)}
                  fromAiportsData={fromAiportsData}
                  toAiportsData={toAiportsData}
                  getSearchedAirports={getSearchedAirports}
                  isFromAirportTouched={isFromAirportTouched}
                  flightInternariesInitialValues={flightInternariesInitValues}
                  updateFlightItenerary={updateFlightItenerary}
                  legNumber={0}
                  moment={moment}
                  bookingType={bookingType}
                  propagationStop={propagationStop}
                  defaultPrevent={defaultPrevent}
                  inputFileRef={inputFileRef}
                  convertFilesToBase64={convertFilesToBase64}
                  setFlightIteneraryActive={setFlightIteneraryActive}
                  flightIteneraryActive={flightIteneraryActive}
            />
      <GoldishSimpleButton
        style={{
          color: 'white',
          textTransform: "capitalize",
          justifyContent: "start",
        }}
      >
        Flight Itinerary
      </GoldishSimpleButton>
      <Box
        className='order_detail_black_round booking_section'
        padding={"1rem"}
      >
        {legsData?.map((eachLeg: FlightInteneraryType, elem_index: number) => {
          const {attributes:{contracts : {files}},id} = eachLeg;
            return (
              <Box className='order_detail_view_accordion_parent' key={elem_index}>
             

                <GenralUsedAccordion
                  defaultExpanded={elem_index === 0}
                  style={{ overflow: "visible", width: "100%" }}
                >
                  <MyAccordionSummary
                    expandIcon={<KeyboardArrowDown style={{ color: "white" }} />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    className='cf_avindode_accordion_header order_detail_accord_header'
                  >
                    <Box className='order_detail_view_edit_expand'>
                      <Box>Leg {elem_index + 1}</Box>
                      {bookingType!=="PreviousBooking" &&(
                        <Box>
                          <IconButton data-testid="delete_leg_button" 
                            onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                              propagationStop(event);
                              setDeleteFunction({lastClicked:'leg_del',deleteId:eachLeg.id});
                              setIsSureModalOpen(true);}}>
                              <DeleteOutline className="advance_search_remove_leg"/>
                            </IconButton>
                        <IconButton
                          data-testid='edit_flight_itenerary'
                          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            propagationStop(event);
                            setFlightInternariesInitValues(handleFlightItenariesInitialValues(eachLeg));
                            setIsEditFlightMode(elem_index+1);
                            setIsFlightIteneraryModalOpen(true);
                          }}
                        >
                          <Edit style={{ color: colors.GOLD_COLOR }} />
                        </IconButton>
                        
                      </Box>
                      )}
                    </Box>
                  </MyAccordionSummary>
                  <AccordionDetails className='cf_avindode_accordion_content cf_avindode_accordion_content_filter_option'>
                    <FlightItineraryEachLegParent setHoveredLeg={setHoveredLeg} hoveredLeg={hoveredLeg} key={eachLeg.id} convertFilesToBase64={convertFilesToBase64} updateFlightItenerary={updateFlightItenerary} eachLegData={eachLeg}  moment={moment} bookingType={bookingType} inputFileRef={inputFileRefAccordion} defaultPrevent={defaultPrevent} propagationStop={propagationStop}/>
                  </AccordionDetails>
                </GenralUsedAccordion>
              </Box>
            )
          })}
          <Box><AddLegtTypeButton onClick={()=>{setIsEditFlightMode(null);setFlightInternariesInitValues(flight_init_values);setIsFlightIteneraryModalOpen(true)}} fullWidth data-testid="add_new_leg">+ Add Leg</AddLegtTypeButton></Box>
      </Box>
    </Box>
  )
}



const FlightItineraryEditModal = ({
  isOpen,
  cancel,
  isEditFlightMode,
  toAiportsData,
  fromAiportsData,
  getSearchedAirports,
  isFromAirportTouched,
  flightInternariesInitialValues,
  updateFlightItenerary,
  moment,
  propagationStop,
  defaultPrevent,
  inputFileRef,
  convertFilesToBase64,
  flightIteneraryActive,
  setFlightIteneraryActive
}:FlightItenEditModalType) => {
  const classes = useStyles()
  const heading = isEditFlightMode ? `Edit Flight Itinerary Leg ${isEditFlightMode}` : 'Add Flight Itinerary'
  let airportDebounceTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const handleAirportChange = (valueToSearch:string,isFromAiport:boolean) => {
    isFromAirportTouched.current = isFromAiport
    if (airportDebounceTimeoutId) clearTimeout(airportDebounceTimeoutId);

    airportDebounceTimeoutId = setTimeout(() => {
      getSearchedAirports(valueToSearch);
    }, debounceTime);
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);


  const handleSubmitForm=(values:FlightInteneraryInitType & FlightIteneraryValueType)=>{
       const valuesToSend = handleFlightSubmitData(values as FlightInteneraryInitType & FlightIteneraryValueType & {order_id:string});
       convertFilesToBase64(values.uploadedDocs as File[], (base64Files:FileData[]) => {
     updateFlightItenerary({...valuesToSend,data:{...valuesToSend.data,contracts:base64Files}},isEditFlightMode)
    });
  }

  const handleDeleteUploadeContractFile = (elemIndex:number,  setFieldValue:(field: string,value: number[] | {files : FileType[]},shouldValidate?: boolean)=>void, values:{contracts:{files:FileType[]},contract_ids:number[]}) => {
     const updatedUploadedDocs = [...values.contracts.files];
       const deletedDoc = updatedUploadedDocs.splice(elemIndex, 1)[0];
     setFieldValue('contracts', {files:updatedUploadedDocs});
     setFieldValue('contract_ids', [...values.contract_ids, deletedDoc.id]); 
  };

  const handleDeleteFile = (elemIndex:number, setFieldValue:(field: string,value: File[],shouldValidate?: boolean)=>void,values:{uploadedDocs : File[]}) => {
    const updatedFiles = [...values.uploadedDocs];
    updatedFiles.splice(elemIndex, 1);
    setFieldValue('uploadedDocs', updatedFiles);
  };


const handleFlightSubmitData = (values: FlightInteneraryInitType & FlightIteneraryValueType & {order_id:string}) => {
    const {
        from_airport,
        to_airport,
        departure,
        arrival,
        duration,
        no_of_passengers,
        aircraft_type,
        tail_number,
        fbo,
        fbo_address,
        fbo_telephone,
        name,
        vendor_charged,
        vendor_booking_ref,
        order_id,
        id,
        no_of_stops,
        contract_ids
    } = values;

    let dataObj:{order_id:number,order_item_id? :number | null,data : {[keyType:string]:string | number | null | {files : FileType[]} | []},contract_ids?:[] | number[]} = {
        'order_id': order_id as unknown as number,
        'data': {
            'avinode_from_airport_id': from_airport && from_airport.id,
            'avinode_to_airport_id': to_airport && to_airport.id,
            'departure_date': departure,
            'arrival_date': arrival,
            'no_of_passengers': no_of_passengers,
            'no_of_stops': no_of_stops,
            'duration': duration,
            'aircraft_type': aircraft_type,
            'tail_number': tail_number,
            'fbo': fbo,
            'fbo_telephone': fbo_telephone,
            'fbo_address': fbo_address,
            'name': name,
            'vendor_charged': vendor_charged,
            'vendor_booking_ref': vendor_booking_ref,
            'avinode_aircraft_id':"aircraft_id",
        }
    };
    dataObj = isEditFlightMode ? {...dataObj,order_item_id:id,contract_ids: contract_ids} : dataObj;
    dataObj.data = Object.fromEntries(Object.entries(dataObj.data).filter(([_name, variety]) => variety != null));
    return dataObj;
}

  return (
      <MuiModal open={isOpen}>
        <StyledModalContent
          className='large_modal_styling booking_section'
          style={{ overflow: "auto" }}
        >
          <Box className='order_detail_view_heading_parent'>
            <GoldishSimpleButton
              style={{ color: 'white', textTransform: "capitalize" }}
              data-testId = 'edit_itenerary'
            >
              {heading}
            </GoldishSimpleButton>
          </Box>

          <Formik
              enableReinitialize
              initialValues={flightInternariesInitialValues}
              onSubmit={(values) => {
                handleSubmitForm(values as unknown as FlightInteneraryInitType & FlightIteneraryValueType);
              }}
              validationSchema={flightIteneraryValidationSchema}
              validateOnChange
              validateOnBlur
            >
              {({
                setFieldValue,
                handleChange,
                handleSubmit,
                touched,
                errors,
                values,
              }) => (
                <>
                   <Grid container spacing={2} style={{marginBottom:'1rem'}}>
            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus label="From" activeField={flightIteneraryActive} currentField="from"/>
              <SearchStyledAutocomplete
                onChange={(_fromevent: Event, newValue: FromAndToType) => {setFieldValue('from_airport',newValue)}}
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('from')}
                borderColor={flightIteneraryActive === 'from' ? '#FFD27E' : 'white'}
                isErrorBorder={touched.to_airport && errors.to_airport}
                getOptionLabel={(option:{code:string,name:string,iata:string}) => `${option.code}/${option.iata} - ${option.name}`}
                options={[...fromAiportsData]}
                filterOptions={(options:FromAndToType) => options}
                data-testid='client_name_autcomplete'
                renderOption={(option: FromAndToType, { selected }: {selected : boolean}) => renderOptionFrom(option,selected)}
                noOptionsText={
                  <Box className={classes.noOptions}>No options</Box>
                }
                classes={{
                  paper: classes.dropdown,
                }}
                value={values.from_airport}
                ListboxProps={{ id: "flight_list_container" }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <CustomFormTextField
                    {...params}
                    placeholder='From'
                    data-testid='from_airport_textfeild'
                    height={'3rem'}
                    onChange={(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>handleAirportChange(event.target.value,true)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Box className='profile_hover_icons'>
                          <Search />
                        </Box>
                      ),
                    }}
                    isFocusedTypeInput={true}
                  />
                )}
              />
              <ErrorBoxes errors={errors.from_airport} message={errors.from_airport} touched={touched.from_airport}/>
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField={flightIteneraryActive} currentField={'to'} label={'To'} /> 
              <SearchStyledAutocomplete
                borderColor={flightIteneraryActive === 'to' ? '#FFD27E' : 'white'}
                isErrorBorder={touched.to_airport && errors.to_airport}
                onBlur = {()=> setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('to')}
                onChange={(_toevent: Event, newValue: FromAndToType) => {setFieldValue('to_airport',newValue)}}
                getOptionLabel={(option:{code:string,name:string,iata:string}) => `${option.code}/${option.iata} - ${option.name}`}
                options={[...toAiportsData]}
                filterOptions={(options:FromAndToType) => options}
                value={values.to_airport}
                data-testid='client_name_autcomplete'
                renderOption={(option: FromAndToType, { selected }: {selected : boolean}) => renderOptionFrom(option,selected)}
                noOptionsText={
                  <Box className={classes.noOptions}>No options</Box>
                }
                classes={{
                  paper: classes.dropdown,
                }}
                ListboxProps={{ id: "flight_list_container" }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <CustomFormTextField
                    {...params}
                    placeholder='To'
                    data-testid='to_airport_textfeild'
                    height={'3rem'}
                    onChange={(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>handleAirportChange(event.target.value,false)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Box className='profile_hover_icons'>
                          <Search />
                        </Box>
                      ),
                    }}
                    isFocusedTypeInput={true}
                  />
                )}
              />
                  <ErrorBoxes errors={errors.to_airport} message={errors.to_airport} touched={touched.to_airport}/>
            </Grid>

            <StyledGrid item lg={6} md={6} sm={12} popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
              <LabelCompWithFocus label="Departure (Local)" activeField={flightIteneraryActive} currentField={'departure'}/>
              <DatePicker
                onChange={(date) => {setFieldValue('departure',date)}}
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('departure')}
                open={values.isCalenderOneOpen}
                onInputClick={()=>setFieldValue("isCalenderOneOpen",true)}
                onClickOutside={()=> !values.isTimePickerOneOpen && setFieldValue("isCalenderOneOpen",false)}
                name="departure"
                minDate={tomorrow}
                data-testid='departure_date_cal'
                calendarClassName="calender_white"
                className='advance_search_date_pick'
                value={dateFormatter(values.departure,moment)}
                timeInputLabel='Time'
                dateFormat={"MM/yyyy | h:mm aa"}
                showTimeInput
                placeholderText='Departure (Local)'
                customInput={
                  <CustomFormTextField
                    variant={'outlined'}
                    isFocusedTypeInput={true}
                    initialBorderColor='white'
                    isErrorTouch={touched.departure && errors.departure}
                    placeholder='Departure (Local)'
                    className={`login_test_password`}
                    height={'3rem'}
                    data-testid='departure_date'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                }
                customTimeInput={<TimePickerNew 
                  setFunction = {(value:string)=>setFieldValue('departure',value)}
                  dateValue={values.departure}
                />}
              />
              <ErrorBoxes errors={errors.departure} message={errors.departure} touched={touched.departure}/>
            </StyledGrid>

            <StyledGrid item lg={6} md={6} sm={12} popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
              <LabelCompWithFocus label={'Arrive (Local)'} activeField={flightIteneraryActive} currentField={'arrival'}/>
              <DatePicker
                open={values.isCalenderTwoOpen}
                onClickOutside={()=> !values.isTimePicketTwoOpen && setFieldValue("isCalenderTwoOpen",false)}
                onInputClick={()=>setFieldValue("isCalenderTwoOpen",true)}
                data-testId="date-picker"
                minDate={tomorrow}
                calendarClassName="calender_white"
                onChange={(date) => {setFieldValue('arrival',date)}}
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('arrival')}
                className='advance_search_date_pick'
                value={dateFormatter(values.arrival,moment)}
                timeInputLabel='Time'
                dateFormat={"MM/yyyy | h:mm aa"}
                showTimeInput
                placeholderText='Arrive (Local)'
                customInput={
                  <CustomFormTextField
                    variant='outlined'
                    isFocusedTypeInput={true}
                    initialBorderColor={'white'}
                    height={'3rem'}
                    placeholder='Arrive (Local)'
                    data-testid='arrival_date'
                    isErrorTouch={touched.arrival && errors.arrival}
                    className={`login_test_password`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                }
                customTimeInput={<TimePickerNew 
                  setFunction = {(value:string)=>setFieldValue('arrival',value)}
                  dateValue={values.arrival}
                />}
              />
               <ErrorBoxes errors={errors.arrival} message={errors.arrival} touched={touched.arrival}/>
            </StyledGrid>

            <Grid item lg={12} md={12} sm={12}>
              <LabelCompWithFocus label={'Total Passengers'} activeField={flightIteneraryActive} currentField={'passengers'}/>
              <CustomFormTextField
                isFocusedTypeInput={true}
                isErrorTouch={touched.no_of_passengers && errors.no_of_passengers}
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('passengers')}
                data-testid='no_of_passengers'
                initialBorderColor = 'white'
                variant = 'outlined'
                placeholder='Enter Total Passengers'
                fullWidth
                value={values.no_of_passengers}
                name="no_of_passengers"
                onChange={handleChange}
                type="number"
                height={'3rem'}
              />
              <ErrorBoxes errors={errors.no_of_passengers} message={errors.no_of_passengers} touched={touched.no_of_passengers}/>
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField={flightIteneraryActive} currentField={'flight_time'} label="Flight Time"/>
              <CustomFormTextField
                isFocusedTypeInput={true}
                initialBorderColor = 'white'
                variant = 'outlined'
                data-testid='flight_time'
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('flight_item')}
                placeholder='Enter Flight Time'
                fullWidth
                value={values.duration}
                name="duration"
                onChange={handleChange}
                height={'3rem'}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus currentField={"no_of_stops"} activeField={flightIteneraryActive} label="Number of Stops"/>
              <CustomFormTextField
                isFocusedTypeInput={true}
                isErrorTouch={touched.no_of_stops && errors.no_of_stops}
                initialBorderColor='white'
                data-testid='no_of_stops'
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('no_of_stops')}
                variant='outlined'
                placeholder='Enter Number of Stops'
                fullWidth
                value={values.no_of_stops}
                name="no_of_stops"
                onChange={handleChange}
                height={'3rem'}

              />
               <ErrorBoxes errors={errors.no_of_stops} message={errors.no_of_stops} touched={touched.no_of_stops}/>
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus label="Aircraft Type" activeField={flightIteneraryActive} currentField={"aircraft_type"}/>
              <CustomFormTextField
                isFocusedTypeInput={true}
                initialBorderColor={'white'}
                variant='outlined'
                data-testid='aircraft_type'
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('aircraft_type')}
                placeholder='Enter Aircraft Type'
                fullWidth
                value={values.aircraft_type}
                name="aircraft_type"
                onChange={handleChange}
                height={'3rem'}

              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField={flightIteneraryActive} currentField="tail_number" label="Tail Number"/>
              <CustomFormTextField placeholder='Enter Tail Number' fullWidth 
               value={values.tail_number}
               isFocusedTypeInput={true}
               variant='outlined'
               initialBorderColor='white'
               data-testid='tail_number'
               onBlur={()=>setFlightIteneraryActive('')}
               onFocus={()=>setFlightIteneraryActive('tail_number')}
               name="tail_number"
               onChange={handleChange}
               id = 'tail_number' 
               height={'3rem'}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField="fbo" currentField={flightIteneraryActive} label="FBO"/>
              <CustomFormTextField placeholder='Enter FBO' fullWidth 
               isFocusedTypeInput={true}
               initialBorderColor='white'
               data-testid='fbo'
               onBlur={()=>setFlightIteneraryActive('')}
               onFocus={()=>setFlightIteneraryActive('fbo')}
               variant='outlined'
               value={values.fbo}
               name="fbo"
               onChange={handleChange}
               height={'3rem'}

              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField={flightIteneraryActive} currentField="fbo_telephone" label="FBO Telephone"/>
              <PhoneInput
                enableSearch={true}
                searchClass="search_country_code"
                inputClass={`regsiter_phone_input`}
                dropdownClass='register_drop_down_class'
                containerClass='regsiter_contaitner_class'
                onBlur={()=>setFlightIteneraryActive('')}
                data-testid='fbo_telephone'
                inputProps={{
                  'data-testid': 'fbo_telephone'
                }}
                onFocus={()=>setFlightIteneraryActive('fbo_telephone')}
                searchPlaceholder="Search"
                dropdownStyle={{ zIndex: 2, marginTop: "-10px" }}
                containerStyle={{ zIndex: 4, position: "relative" }}
                country='us'
                value={values.fbo_telephone}
                inputStyle={{height:'3rem'}}
                onChange={(_value,_country, _event, formattedValue) => {
                  setFieldValue('fbo_telephone', formattedValue)
                }}
                placeholder='Your Phone Number'
          />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField={flightIteneraryActive} currentField="fbo_address" label="FBO Address"/>
              <CustomFormTextField placeholder='Enter FBO Address' fullWidth 
                isFocusedTypeInput={true}
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('fbo_address')}
                initialBorderColor = 'white'
                data-testid='fbo_address'
                variant = 'outlined'
                value={values.fbo_address}
                name="fbo_address"
                onChange={handleChange}
                height={'3rem'}

              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus activeField={flightIteneraryActive} currentField="name" label="Vendor Name"/>
              <CustomFormTextField placeholder='Enter Vendor Name' fullWidth
              isFocusedTypeInput={true}
              initialBorderColor='white'
              variant='outlined'
              data-testid='name'
              onBlur={()=>setFlightIteneraryActive('')}
              onFocus={()=>setFlightIteneraryActive('name')}
              value={values.name}
              name="name"
              onChange={handleChange}
              height={'3rem'}

              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <LabelCompWithFocus label="Vendor Charged" activeField={flightIteneraryActive} currentField='vendor_charged'/>
              <CustomFormTextField
                isFocusedTypeInput={true}
                isErrorTouch={touched.vendor_charged && errors.vendor_charged}
                initialBorderColor='white'
                onBlur={()=>setFlightIteneraryActive('')}
                onFocus={()=>setFlightIteneraryActive('vendor_charged')}
                variant='outlined'
                data-testid='vendor_charged'
                placeholder='Enter Vendor Charged'
                fullWidth
                value={values.vendor_charged}
                name="vendor_charged"
                onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"vendor_charged")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Box color={'white'}>$</Box>
                    </InputAdornment>
                  ),
                }}
                height={'3rem'}
              />
                <ErrorBoxes errors={errors.vendor_charged} message={errors.vendor_charged} touched={touched.vendor_charged}/>
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
            <LabelCompWithFocus label="Vendor Booking Ref." activeField={flightIteneraryActive} currentField="vendor_booking_ref"/>
              <CustomFormTextField
                isFocusedTypeInput={true}
                initialBorderColor = 'white'
                variant='outlined'
                onBlur= {()=>setFlightIteneraryActive('')}
                onFocus= {()=>setFlightIteneraryActive('vendor_booking_ref')}
                placeholder='Enter Vendor Booking Ref.'
                fullWidth
                value={values.vendor_booking_ref}
                name="vendor_booking_ref"
                data-testid='vendor_booking_ref'
                onChange={handleChange}
                height={'3rem'}

              />
            </Grid>
          </Grid>
          <UploadDocumentAccordionAndUpload testIdName="flight_modal_accord" propagationStop={propagationStop} defaultPrevent={defaultPrevent} handleDeleteUploadeContractFile={(elemIndex:number)=>handleDeleteUploadeContractFile(elemIndex,setFieldValue,values)} uploadFiles={values.uploadedDocs as []} handleDeleteFile={(elemIndex:number)=>handleDeleteFile(elemIndex, setFieldValue,values as unknown as { uploadedDocs: File[] })} alreadyUploadFiles = {values.contracts.files} onUplaodNewFile = {(validFiles:File[])=>{setFieldValue('uploadedDocs', [...values.uploadedDocs, ...validFiles])}} isVaccinateion={false} inputFileRef={inputFileRef} overflow="clip"/>
          <CancelAndSaveChanges cancel={() => cancel()} submit={()=>handleSubmit()} />
           </>)}

                </Formik>
         
        </StyledModalContent>
      </MuiModal>
  )
}

const LabelCompWithFocus=({label,activeField,currentField}: PaymentWithLabelCompFocusType)=>{
  return <PaymentLabel isFocused={activeField === currentField}>{label}</PaymentLabel>
}

const dateFormatter=(dates:string | Date | null,moment:momentType)=>{
  return dates ? moment(dates).format('MMM D, YYYY  |  ddd - h:mm A') : ''
}

const UploadDocumentAccordionAndUpload=({overflow='visible',testIdName,propagationStop, defaultPrevent, isVaccinateion=false,rowBackground = '',uploadFiles=[],onUplaodNewFile,alreadyUploadFiles=[],handleDeleteFile,handleDeleteUploadeContractFile, inputFileRef}:UploadDocumentAccordionType & {testIdName:string} & {overflow? : 'hidden' | 'visible' | 'clip'})=>{
  const heading = isVaccinateion ? 'Vaccination Documents' : 'Contracts';
  const isNoFiles = alreadyUploadFiles.length === 0 && uploadFiles.length === 0;
  const buttonEnd = isVaccinateion ? 'Document' : 'Contract';
  const isOutSideAccordion = overflow !== 'clip';



  const handleFileDrop = (event:React.DragEvent<HTMLElement>) => {
    defaultPrevent(event);
    const files = Array.from(event.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileSelect = (event:React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files as ArrayLike<File>);
    handleFiles(files);
    event.target.value = '';
  };

  const handleFiles = (files:File[]) => {
    const validFiles = files.filter((file: File) => validContractsExtension.test(file.type));
    onUplaodNewFile(validFiles);
  };




  return <GenralUsedAccordion style={{width:"100%",overflow}} expanded>
  <MyAccordionSummary
    borderRadius={'0'}
    expandIcon={undefined}
    aria-controls='panel1a-content'
    id='panel1a-header'
    className="cf_avindode_accordion_header"
  >
    <Box className = 'order_detail_view_edit_expand'>
      <Box>{heading}</Box>

      {!isNoFiles && <Button disabled={uploadFiles.length !== 0 && isOutSideAccordion} onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {propagationStop(event);inputFileRef.current?.click()}} data-testid="edit_contract_modal_new" style={{color:colors.SAVE_DARK_COLOUR,textTransform:'none'}} 
      > 
      <Box className = 'order_detail_upload_icon'><UPLOAD_ICON/></Box>Upload New {buttonEnd}</Button>}
    </Box>
  </MyAccordionSummary>
  <AccordionDetails className={`cf_avindode_accordion_content ${!isNoFiles && 'cf_avindode_accordion_content_filter_option order_detail_view_accordion_file'}`} style={{backgroundColor:rowBackground,padding: '1rem'}}>
    <Box data-testid = {`${testIdName}-file-input-wrapper-1`}>
        <input
          ref={inputFileRef}
          type="file"
          multiple
          accept="application/pdf, image/jpeg, image/png"
          style={{ display: 'none' }}
          onChange={(event:React.ChangeEvent<HTMLInputElement>) => {propagationStop(event); handleFileSelect(event);}
        }
        />
    </Box>
    {isNoFiles ?  <Box
             className="order_detail_upload_box"
             data-testid={testIdName}
             style={{flexDirection:'column'}}
             onClick={(_event:React.MouseEvent<HTMLElement>) => {inputFileRef.current?.click()}}
             onDragOver={(event:React.DragEvent<HTMLElement>) => defaultPrevent(event)}
             onDrop={(event:React.DragEvent<HTMLElement>) => handleFileDrop(event)}
             >

              <UploadIcon2 />
              <Box>
                <Box>Drag Or Click To Upload</Box>
               </Box>
            </Box> :   <Box className="order_detail_last_child">
              {uploadFiles && (uploadFiles as File[]).map((elem:File,file_index:number)=>{
                return <DocumentShowAndDelete
                testId={testIdName}
                name={elem.name}
                deleteFile={()=>handleDeleteFile(file_index)}
                isLoading={isOutSideAccordion}
                  />
               })}  
                 {alreadyUploadFiles && (alreadyUploadFiles as FileType[]).map((elem:FileType,file_upload_index:number)=>{
                return <DocumentShowAndDelete
                testId={testIdName}
                name={elem.file_name}
                link={elem.file_url}
                deleteFile={()=>handleDeleteUploadeContractFile(file_upload_index)}
                  />
               })}  
                </Box>}
  </AccordionDetails>
</GenralUsedAccordion>
}


const FlightItineraryEachLegParent=({setHoveredLeg,hoveredLeg,convertFilesToBase64,updateFlightItenerary,eachLegData,moment,bookingType,defaultPrevent,propagationStop,inputFileRef}:FlightItenEachCompType)=>{
  const uploadFileOrDelete=(uploadedDocs:File[]) => {
    convertFilesToBase64(uploadedDocs, (base64Files:FileData[]) => {
    updateFlightItenerary({order_item_id:hoveredLeg,data:{contracts:base64Files}},1)
    });
  }
  const deleteCallBack=(updatedUploadedDocs:[] | FileType[],elemIndex:number)=>{
     const deletedDoc  = updatedUploadedDocs.splice(elemIndex, 1)[0];
     updateFlightItenerary({contract_ids:[(deletedDoc).id],order_item_id:eachLegData.id,data:{contracts:[]}},1)
  }

  return  <Grid container onMouseEnter={()=>setHoveredLeg(eachLegData.id)} data-testid={`flight_iten_parent_cont`}>
  <Grid lg={6}md={12} sm={12} xs={12} item><FlightDetails moment={moment} flightData={eachLegData}/></Grid>
  <Grid lg={6}md={12} sm={12} xs={12} item style={{borderLeft:'1px solid #3E4247'}}>
    <Box>
        <BPODetails bpoData={eachLegData}/>
        <UploadDocumentAccordionAndUpload testIdName={'flight_iten_accordion'} defaultPrevent={defaultPrevent} propagationStop={propagationStop} inputFileRef={inputFileRef} handleDeleteUploadeContractFile={(file_index:number)=>{deleteCallBack(eachLegData.attributes.contracts.files,file_index)}} uploadFiles={[]} handleDeleteFile={(_file_index:number)=>{}} alreadyUploadFiles = {eachLegData.attributes.contracts.files} onUplaodNewFile = {(validFiles:File[])=>{uploadFileOrDelete(validFiles)}} isVaccinateion={false}/>
    </Box>
  </Grid>
</Grid>
}

const FlightDetails=({flightData,moment}:{moment: momentType,flightData: FlightInteneraryType })=>{
  const {attributes:{from_airport,to_airport,departure_date,arrival_date,duration,no_of_passengers,aircraft_type,tail_number,no_of_stops,call_sign}} = flightData;
  const departure_local = moment.utc(departure_date).format("DD-MMM-YYYY  |  HH:mm")
  const arrival_local = moment.utc(arrival_date).format("DD-MMM-YYYY  |  HH:mm")
  const fromAiportName =  `${from_airport.icao}/${from_airport.iata} - ${from_airport.name}`;
  const toAiportName = `${to_airport.icao}/${to_airport.iata} - ${to_airport.name}`;
  return <Box className="order_detail_last_child">
      <EachRowInfo keyName={'From'} valueName={fromAiportName}/>
      <EachRowInfo keyName={'To'} valueName={toAiportName}/>
      <EachRowInfo keyName={'Departure (Local)'} valueName={departure_local}/>
      <EachRowInfo keyName={'Arrive (Local)'} valueName={arrival_local}/>
      <EachRowInfo keyName={'Total Passengers'} valueName={no_of_passengers}/>
      <EachRowInfo keyName={'Flight Time'} valueName={duration}/>
      <EachRowInfo keyName={'Number of Stops'} valueName={no_of_stops}/>
      <EachRowInfo keyName={'Aircraft Type'} valueName={aircraft_type}/>
      <EachRowInfo keyName={'Tail Number'} valueName={tail_number}/>
      <EachRowInfo keyName={'Callsign'} valueName={call_sign}/>
  </Box>
}

const BPODetails=({bpoData}:{bpoData: FlightInteneraryType})=>{
  const {attributes:{fbo,fbo_telephone,fbo_address,vendor_name,vendor_charged,vendor_booking_ref}} = bpoData;
  return <Box className="order_detail_last_child">
      <EachRowInfo keyName={'FBO'} valueName={fbo}/>
      <EachRowInfo keyName={'FBO Telephone'} valueName={fbo_telephone}/>
      <EachRowInfo keyName={'FBO Address'} valueName={fbo_address} textTransform="none"/>
      <EachRowInfo keyName={'Vendor Name'} valueName={vendor_name} textTransform="none"/>
      <EachRowInfo keyName={'Vendor Charged'} valueName={vendor_charged} isPrice={true}/>
      <EachRowInfo keyName={'Vendor Booking Ref.'} valueName={vendor_booking_ref} textTransform="none"/>
  </Box>
}

const DocumentShowAndDelete=({testId, isPNGDownload = false,name = 'Default PDF',link = null,deleteFile=()=>{},isLoading=false}:DocumentShowAndDeleteType & {testId:string})=>{
  return <Box className='order_detail_doc' style={{padding :isLoading ? '1rem' : ''}}> 
    <Box className=" order_detail_head_edit">
      <Box className='order_detail_doc_parent'>
        {isPNGDownload ? <PNG_ICON unique_id={uuidv4()}/> : <PDF_ICON unique_id={uuidv4()}/>}
        <Box>{name}</Box>
      </Box>
      <Box display={'flex'} gridColumnGap={'1rem'}>
      <IconButton className = 'order_detail_icon_buton' onClick={()=>deleteFile()}  data-testid={`${testId}-delete_doc_button`}>{!isLoading && <DeleteOutline style={{color:'#DC2626'}}/>}</IconButton>
        {link && <IconButton className = 'order_detail_icon_buton'  href={link} target="_blank"><SaveAlt style={{color:colors.SAVE_DARK_COLOUR}} /></IconButton>}
      </Box>
    </Box>
    {isLoading && <LinearProgressWithLabel/>}
</Box>
}

const PassengerSection=({isApiProcessing,downloadAllPassportAndCustomer,setDeleteFunction,setIsSureModalOpen,makeLeadPassenger,addEditPassengerInitialValues,deletePassenger,setaddEditPassengerInitialValues,addOrEditSavePassenger,focusedField,passportRef,setFocusedField,bookingType,addSavePassengerToBooking,propagationStop,passengerData,isSavedPassengerModalOpen,setIsSavedPassengerModalOpen,isAddPassengerModalOpen,setIsAddPassengerModalOpen}:PassengerSectionType)=>{
  const {additional_passenger,lead_passenger,save_passenger} = passengerData;
  function extractIdsFromData(passenger : PassengerType[]){
    return passenger.map(item => item.attributes.id);
  }

  const selectedPassengers = [
    ...extractIdsFromData(additional_passenger.data),
    ...extractIdsFromData(lead_passenger.data),
  ];


  return <Box className = 'order_detail_column_cont'>
        <Box className = 'order_detail_head_edit'>
          <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}>Passenger Information</GoldishSimpleButton>
          <Box className="order_detail_passenger_button_cont">
          <Button href={downloadAllPassportAndCustomer('passenger')} target="_blank" style={{textTransform:"none",color:'#FFE2AA',border:'1px solid #FEE1AA',fontSize:"1rem",height:'3rem',backgroundColor:'#181C18'}} ><Box className = 'order_detail_upload_icon'><SaveAlt style={{fontSize:'1rem',marginRight:'0.4rem'}}/> </Box>Download All Customer Data as .txt File</Button>
            <Box className="order_detail_module_line">|</Box>
          <Button  href={downloadAllPassportAndCustomer('passport')} target="_blank" style={{textTransform:"none",color:'#FFE2AA',border:'1px solid #FEE1AA',fontSize:"1rem",height:'3rem',backgroundColor:'#181C18'}} ><Box className = 'order_detail_upload_icon'><SaveAlt style={{fontSize:'1rem',marginRight:'0.4rem'}}/> </Box>Download All Passport</Button>
          </Box>

        </Box>
         <Box className='order_detail_black_passenger ' padding={'1rem'}>  

          {lead_passenger.data && lead_passenger.data.map((addition_passenger_info)=>{
            return  <ParentPassengerSections setDeleteFunction={setDeleteFunction} setIsSureModalOpen={setIsSureModalOpen} makeLeadPassenger={makeLeadPassenger} deletePassenger={deletePassenger} setaddEditPassengerInitialValues={setaddEditPassengerInitialValues} setIsAddPassengerModalOpen={setIsAddPassengerModalOpen} eachPassengerData ={addition_passenger_info} isLeadPassenger={true} propagationStop={propagationStop}/> 
           })}       
           
           {additional_passenger.data && additional_passenger.data.map((addition_passenger_info)=>{
            return  <ParentPassengerSections setDeleteFunction={setDeleteFunction} setIsSureModalOpen={setIsSureModalOpen} makeLeadPassenger={makeLeadPassenger} deletePassenger={deletePassenger} setaddEditPassengerInitialValues={setaddEditPassengerInitialValues} setIsAddPassengerModalOpen={setIsAddPassengerModalOpen} eachPassengerData ={addition_passenger_info} isLeadPassenger={false} propagationStop={propagationStop}/> 
           })}
          <SavedPassengerModal
             addPassengerModalOpen={()=>{setIsAddPassengerModalOpen(true); setIsSavedPassengerModalOpen(false)}}
             cancel={()=>setIsSavedPassengerModalOpen(false)}
             isOpen={isSavedPassengerModalOpen}
             addSavePassengerToBooking={addSavePassengerToBooking}
             savedPassengerList={save_passenger.data}
             selectedPassengers={selectedPassengers}
          >
            {bookingType !== "PreviousBooking" && (
            <AddLegtTypeButton  fullWidth onClick={()=>{setaddEditPassengerInitialValues(undefined);setIsSavedPassengerModalOpen(true);}} data-testid="add_new_passenger">+ Add New Passenger</AddLegtTypeButton>
            )}            
          </SavedPassengerModal>
           <AddPassengerModal
              isDisable={isApiProcessing}
              initialValues = {addEditPassengerInitialValues}
              passportRef={passportRef}
              section='client' 
              isOpen={isAddPassengerModalOpen}
              cancel={()=>setIsAddPassengerModalOpen(false)}
              uploadIcon={<UploadIcon2/>} 
              focusField = {setFocusedField}
              focusedField={focusedField}
              submit={(values:AddNewPassType)=>addOrEditSavePassenger(values,addEditPassengerInitialValues)}      
              actionType = {addEditPassengerInitialValues === undefined ?  'add' : 'edit'}
                 >
          </AddPassengerModal> 
         </Box>
  </Box>
}

const SavedPassengerModal=({isOpen,cancel,addPassengerModalOpen,children,addSavePassengerToBooking,savedPassengerList,selectedPassengers}:{isOpen:boolean,cancel:()=>void,addPassengerModalOpen:()=>void,children:React.ReactNode, savedPassengerList: LeadAndAddPassType["save_passenger"]["data"]
selectedPassengers:number[]
addSavePassengerToBooking:(body:number[]) => Promise<void>})=>{
  const save_passenger_init:SavePassengerInitType = {ids : selectedPassengers};
  return <>
    {children}
    <MuiModal 
      open={isOpen}
    >
       <Formik
          enableReinitialize
          initialValues={save_passenger_init}
          onSubmit={(values) => {
            addSavePassengerToBooking(values.ids as number[])
          }}
          validateOnChange
          validateOnBlur
        >
          {(formikProps) => (
              <StyledModalContent
                className='large_modal_styling booking_section'
                style={{ overflowY: "auto" }}
              >
                <Box className='order_detail_view_heading_parent'>
                  <GoldishSimpleButton
                    style={{
                      color: 'white',
                      textTransform: "capitalize",
                    }}
                  >
                    Saved Passenger
                  </GoldishSimpleButton>
                  <AddLegtTypeButton
                    onClick={() => addPassengerModalOpen()}
                    data-testid='add_new_save_passenger'
                  >
                    + Add New Passenger
                  </AddLegtTypeButton>
                </Box>
                {savedPassengerList &&
                  savedPassengerList.map((passenger) => (
                    <PassengerCheckComp passenger={passenger} formikProps={formikProps}/>
                  ))}

                <CancelAndSaveChanges cancel={() => cancel()} submit={()=>formikProps.handleSubmit()}/>
              </StyledModalContent>
          )}
        </Formik>

    </MuiModal>
  </>

}



const PassengerCheckComp=({passenger,formikProps}:{passenger:SavedPassengerType,formikProps:FormikProps<SavePassengerInitType>})=>{
  const {attributes:{full_name,id}} = passenger;
  const {values:{ids},setFieldValue} = formikProps

  const handleCheckboxChange = (isChecked: boolean) => {
    if (isChecked) {
      setFieldValue('ids', [...ids, id]);
    } else {
      setFieldValue('ids', ids.filter(passengerId => passengerId !== id as unknown as number));
    }
  };

 
  return <Box className="save_passenger_cont">
      <CustomCheckbox data-testid='add_save_passenger_check' checked={(ids as number[]).includes(id as unknown as number)} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleCheckboxChange(checked)}/>
      {full_name}
  </Box>
}

const ParentPassengerSections=({setDeleteFunction,setIsSureModalOpen,makeLeadPassenger,setIsAddPassengerModalOpen,setaddEditPassengerInitialValues,eachPassengerData,isLeadPassenger,propagationStop}:ParentPassengerSectionsType)=>{
  const {attributes : {full_name,first_name,last_name,passport_number,date_of_issuance,country_code,country_of_issue,phone_number,email,full_address,gender,date_of_expiration,weight,date_of_birth,id,passport_image}} = eachPassengerData;
  const initial_value = {
    firstName:first_name,
    lastName:last_name,
    passportNumber: passport_number,
    dateOfInsurance: date_of_issuance,
    dateOfExpiration: date_of_expiration,
    countryOfIssue: {name:country_of_issue},
    countryCode:country_code,
    phoneNumber: phone_number,
    emailId: email,
    address: full_address,
    gender:gender,
    weight,
    dateOfBirth: date_of_birth,
    fullNumber:`${country_code}${phone_number}`, 
    id,
    passportFile: passport_image
  };
  return  <Box className = 'order_detail_view_accordion_parent'>
  <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} >
  <MyAccordionSummary
    expandIcon={<KeyboardArrowDown style={{color:'white'}}
    />}
    aria-controls='panel1a-content'
    id='panel1a-header'
    className="cf_avindode_accordion_header order_detail_accord_header"
  >
    <Box className = 'order_detail_view_edit_expand'>
      <Box display={'flex'} alignItems={'center'} gridColumnGap={'0.35rem'}>
        <Box>{full_name}</Box>
        {isLeadPassenger && <Box color={colors.GOLD_COLOR} fontSize={'0.9rem'}>(Lead Passenger)</Box>}
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        {!isLeadPassenger ? <AddLegtTypeButton data-testid="make_lead_passenger" style={{fontSize:"1rem",height:'2.5rem',marginRight:'1rem'}} onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{propagationStop(event) ;makeLeadPassenger(id as unknown as string)}}>Make Lead Passenger</AddLegtTypeButton>
  : <AddLegtTypeButton style={{fontSize:"1rem",height:'2.5rem',marginRight:'1rem',border:'1px solid #94A3B8',color:'#94A3B8'}}>Lead Passenger</AddLegtTypeButton>}
      <IconButton data-testid="delete_saved_passenger" 
      onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{propagationStop(event);
        setDeleteFunction({deleteId:id as unknown as string,lastClicked:'passenger'})
        setIsSureModalOpen(true);
      }}
      >
        <DeleteOutline className="advance_search_remove_leg"/>
      </IconButton>
      <IconButton data-testid="edit_saved_passenger" onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{propagationStop(event);setaddEditPassengerInitialValues(initial_value as unknown as undefined) ;setIsAddPassengerModalOpen(true)}}>
        <Edit style={{color:colors.GOLD_COLOR}} />
      </IconButton>
      </Box>
      

    </Box>
  </MyAccordionSummary>
  <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
    <EachPassengerInfoGrids passengerData={eachPassengerData}/>
  </AccordionDetails>
</GenralUsedAccordion>
</Box>
}

const EachPassengerInfoGrids=({passengerData}:{passengerData:PassengerType})=>{
  return  <Grid container>
  <Grid lg={6}md={12} sm={12} xs={12} item><PassengerGrid1 passengerData={passengerData}/></Grid>
  <Grid lg={6}md={12} sm={12} xs={12} item style={{borderLeft:'1px solid #3E4247'}}>
    <PassengerGrid2 passengerData={passengerData}/>
  </Grid>
</Grid>
}


const PassengerGrid1=({passengerData}:{passengerData:PassengerType})=>{
  const {full_name,date_of_issuance,date_of_expiration,country_of_issue,passport_image,passport_number} = passengerData.attributes;
  return <Box className="order_detail_last_child">
      <EachRowInfo keyName={'Full Name'} valueName={full_name}/>
      <EachRowInfo keyName={'Passport Number'} valueName={passport_number}/>
      <EachRowInfo keyName={'Date of Issuance'} valueName={date_of_issuance}/>
      <EachRowInfo keyName={'Date of Expiration'} valueName={date_of_expiration}/>
      <EachRowInfo keyName={'Country of Issue'} valueName={country_of_issue}/>
    <DocumentShowAndDelete testId="passenger_grid" isPNGDownload={true} name={`${full_name}'s passport`} link={passport_image?.url}/>
  </Box>
}

const PassengerGrid2=({passengerData}:{passengerData:PassengerType})=>{
  const {email, full_address, date_of_birth, weight, gender,phone_number,country_code} = passengerData.attributes;

  return <Box className="order_detail_last_child">
      <EachRowInfo keyName={'Phone Number'} valueName={'+' + country_code + '-' + phone_number} />
      <EachRowInfo keyName={'Email'} valueName={email} />
      <EachRowInfo keyName={'Full Address'} valueName={full_address} />
      <EachRowInfo keyName={'Date of Birth'} valueName={date_of_birth} />
      <EachRowInfo keyName={'Weight'} valueName={weight} />
      <EachRowInfo keyName={'Gender'} valueName={gender} />
    </Box>
}



const RequirementsSection=({requirementFiledUploadRef,isRequirementSaving,propagationStop,requirementAttributes,updateRequirements, setIsRequirementModalOpen, isRequirementModalOpen,convertFilesToBase64,inputFileRef, inputFileRefAccordion,defaultPrevent,bookingType}:RequirementsSectionType)=>{
  return <Box className = 'order_detail_column_cont'>
           <RequirementsEditModal isRequirementSaving={isRequirementSaving} propagationStop={propagationStop} defaultPrevent={defaultPrevent} inputFileRef={inputFileRef} isOpen={isRequirementModalOpen} cancel={()=>setIsRequirementModalOpen(false)} requirement={requirementAttributes} updateRequirements={updateRequirements} convertFilesToBase64={convertFilesToBase64}/>
        <Box className = 'order_detail_head_edit'>
          <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}>Requirements</GoldishSimpleButton>
          <Box className="order_detail_passenger_button_cont">
          {bookingType !== "PreviousBooking" && (
              <Box onClick={() => setIsRequirementModalOpen(true)} data-testid="edit_requirements">
              <Edit style={{ color: colors.GOLD_COLOR }} />
            </Box>
            )}            
          </Box>
        </Box>
         <Box className='order_detail_black_passenger ' padding={'1rem'}>   
         <RequirementsGrids requirementFiledUploadRef={requirementFiledUploadRef} propagationStop={propagationStop} defaultPrevent={defaultPrevent} inputFileRef={inputFileRefAccordion} requirementAttributes={requirementAttributes} updateRequirements={updateRequirements} convertFilesToBase64={convertFilesToBase64} bookingType={bookingType}/>      
         </Box>
  </Box>
}

const RequirementsGrids=({requirementFiledUploadRef,propagationStop,defaultPrevent,inputFileRef, requirementAttributes,updateRequirements,convertFilesToBase64,bookingType}:RequirementsGridsTypes)=>{
  const {requirements:{is_special_luggage,special_luggage_details, car_transportation_required, is_car_transport_required, is_catering_required, catering_required, will_they_be_bringing_pets, are_they_bringing_pets, is_any_special_requests, special_requests},vaccination_pdf} = requirementAttributes;

  const uploadFileOrDelete=(uploadedDocs:File[]) => {
    convertFilesToBase64(uploadedDocs, (base64Files:FileData[]) => {
      updateRequirements({data:{vaccination_files:base64Files}})
    });
  }

  const deleteCallBack=(updatedUploadedDocs:[] | FileType[],elemIndex:number)=>{
    const deletedDoc = updatedUploadedDocs.splice(elemIndex, 1)[0];
    updateRequirements({vaccination_ids:[(deletedDoc).id],data : {...requirementAttributes.requirements}})
  }

  return  <Formik
  enableReinitialize
  initialValues={{uploadedFiles:[]}}
  innerRef={requirementFiledUploadRef}
  onSubmit={(_values) => {}}
  validateOnChange
  validateOnBlur
>
  {({
    setFieldValue,
    values,
  }) => (<Grid container>
    <Grid lg={6}md={12} sm={12} xs={12} item style={{paddingRight:'3rem'}}>
      <Box className="order_detail_last_child">
      <EachRequirement title={'Special Luggage Details'} description={special_luggage_details} answer={is_special_luggage}/>  
      <EachRequirement title={'Car Transportation Required?'} description={car_transportation_required} answer={is_car_transport_required}/>  
      <EachRequirement title={'Catering Required?'} description={catering_required} answer={is_catering_required}/>  
      <EachRequirement title={'Special Requests'} description={special_requests} answer={is_any_special_requests}/>  
      </Box>
    </Grid>
    <Grid lg={6}md={12} sm={12} xs={12} item style={{borderLeft:'1px solid #3E4247',paddingLeft:'3rem'}}>
      <EachRequirement title={'Will they be bringing pets?'} description={will_they_be_bringing_pets} answer={are_they_bringing_pets} isBorderBottom={false} />  
      {bookingType !== "PreviousBooking" &&(
        <UploadDocumentAccordionAndUpload overflow="hidden" testIdName="requirement_grid_accord" defaultPrevent={defaultPrevent} propagationStop={propagationStop} inputFileRef={inputFileRef} handleDeleteUploadeContractFile={(file_index:number)=>{deleteCallBack(vaccination_pdf.files,file_index)}} uploadFiles={values.uploadedFiles} handleDeleteFile={(_file_index:number)=>{}} alreadyUploadFiles = {vaccination_pdf.files} onUplaodNewFile = {(validFiles:File[])=>{setFieldValue('uploadedFiles',[...validFiles,...values.uploadedFiles]);uploadFileOrDelete(validFiles);}} isVaccinateion={true}/>
      )}
    </Grid>
  </Grid>)}
</Formik>
}


const RequirementsEditModal = ({isRequirementSaving,propagationStop, defaultPrevent, isOpen, cancel, requirement, updateRequirements,convertFilesToBase64, inputFileRef }: RequirementEditModalType) => {
  const {requirements,vaccination_pdf} = requirement;
  const eachRequirementObject = (descriptionTitle:string,isRequiredTitle:string, title : string)=>{
    return {
      descriptionTitle,
      isRequiredTitle,
      title
    }
  }
  
  const requirementArray = [
    eachRequirementObject('special_luggage_details','is_special_luggage', 'Special Luggage Details'),
    eachRequirementObject('car_transportation_required','is_car_transport_required', 'Car Transportation Required?'),
    eachRequirementObject('catering_required','is_catering_required', 'Catering Required?'),
    eachRequirementObject('will_they_be_bringing_pets','are_they_bringing_pets','Will they be bringing pets?'),
    eachRequirementObject('special_requests','is_any_special_requests', 'Special Requests'),
  ]

  const handleDescriptionChange=(keyName:string,setFieldValue:(field: string,value: string | boolean,shouldValidate?: boolean) => void,value:string | boolean)=>{
    setFieldValue(keyName,value);
  }

  const handleDeleteUploadeContractFile = (elemIndex:number,  setFieldValue:(field: string,value: number[] | {files : FileType[]},shouldValidate?: boolean)=>void, values:{vaccination_pdf:{files:FileType[]},vaccination_ids:number[]}) => {
    const updatedUploadedDocs = [...values.vaccination_pdf.files];
    const deletedDoc = updatedUploadedDocs.splice(elemIndex, 1)[0];
   setFieldValue('vaccination_pdf', {files:updatedUploadedDocs});
   setFieldValue('vaccination_ids', [...values.vaccination_ids, deletedDoc.id]); 
  };

  const handleDeleteFiles = (elemIndex:number, setFieldValue:(field: string,value: File[],shouldValidate?: boolean)=>void,values:{uploadedDocs : File[]}) => {
    const updatedFile = [...values.uploadedDocs];
    updatedFile.splice(elemIndex, 1);
    setFieldValue('uploadedDocs', updatedFile);
  };

  return (
    <MuiModal open={isOpen}>
      <StyledModalContent className='large_modal_styling booking_section'>
        <Formik
          enableReinitialize
          initialValues={{...requirements,vaccination_pdf,uploadedDocs:[],vaccination_ids:[]}}
          onSubmit={(values) => {
            convertFilesToBase64(values.uploadedDocs, (base64Files:FileData[]) => {
              updateRequirements({data:{...values,is_any_special_requirement: values.is_any_special_requests,vaccination_files:base64Files},vaccination_ids:values.vaccination_ids})
            });
          }}
          validateOnChange
          validateOnBlur
        >
          {({
            setFieldValue,
            handleSubmit,
            values,
          }) => (
            <>
              <Box className='order_detail_view_heading_parent'>
                <GoldishSimpleButton
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Edit Requirements
                </GoldishSimpleButton>
              </Box>
              {requirementArray.map((eachReq:EachRequirementObjectType)=> {
                const isRequireKey = eachReq.isRequiredTitle
                const descriptionKey = eachReq.descriptionTitle

              return <EditRequireMentSection
              key={1}
              title={eachReq.title}
              handleChange = {(event:React.ChangeEvent<HTMLInputElement>)=> handleDescriptionChange(descriptionKey,setFieldValue,event.target.value)}
              value={values[descriptionKey as keyof RequirementTextType]}
              isTurnedOn={values[isRequireKey as keyof RequirementTextType]}
              handleTogglerChange = {(_event:React.ChangeEvent<HTMLInputElement>,checked:boolean)=> handleDescriptionChange(isRequireKey,setFieldValue,checked)}
              />})}
              <UploadDocumentAccordionAndUpload overflow="clip" testIdName="require_modal_accord" propagationStop={propagationStop} defaultPrevent={defaultPrevent} handleDeleteUploadeContractFile={(elemIndex:number)=>handleDeleteUploadeContractFile(elemIndex,setFieldValue,values)} uploadFiles={values.uploadedDocs} handleDeleteFile={(elemIndex:number)=>handleDeleteFiles(elemIndex, setFieldValue,values)} alreadyUploadFiles = {values.vaccination_pdf.files} onUplaodNewFile = {(validFiles:File[])=>{setFieldValue('uploadedDocs', [...values.uploadedDocs, ...validFiles])}} isVaccinateion={true} inputFileRef={inputFileRef}/>
              <CancelAndSaveChanges isSaving={isRequirementSaving} isDisabled={isRequirementSaving} cancel={() => cancel()} submit={()=>handleSubmit()}/>
            </>
          )}
        </Formik>
      </StyledModalContent>
    </MuiModal>
  )
}


const EditRequireMentSection=({handleChange,value,isTurnedOn,handleTogglerChange,title}:EditRequireMentEachSectionType)=>{
  const alignItem = isTurnedOn ? 'center' : 'start';
  return <Box borderBottom={'1px solid #636E72'} className="order_detail_view_heading_parent" style={{alignItems:alignItem,padding:'0.5rem 0rem'}}>
        <Box className="booking_section" gridRowGap={'1.2rem'} paddingBottom={'0.8rem'} width={'91%'}>
            <Box className="order_detail_require_head" >{title}</Box>
          {isTurnedOn &&  <Box className="order_detail_head_edit" >
            <RequirementKindTextField  InputProps={{ "data-testid": "content-input" }} fullWidth onChange={handleChange} value={value} minRows={2} multiline heightOverride={'auto'} fontSize={'1rem'}/>
            </Box>}
        </Box>
        <Box position={'relative'} marginTop={'-5px'}>
          <CustomSwitch
            checked={isTurnedOn as boolean} 
            data-testid={'custom-switch'}
            onChange={handleTogglerChange}/>
           </Box>
        
  </Box>
}


const EachRequirement=({isBorderBottom=true,title, description, answer}:EachRequirementCompType)=>{
  const yesOrNo = answer ? 'Yes' : 'No';
  const isEmpty = answer && (typeof description === 'string' ?  description.trim() : null);
  return <Box className="order_detail_require_parent" style={{borderBottom:!isBorderBottom ? 'none' : ''}}>
    <Box className="order_detail_view_heading_parent order_detail_require_head">
      <Box>{title}</Box>
      <Box className = {yesOrNo === 'Yes' ? 'order_detail_view_paid' : 'order_detail_view_unpaid'}>{yesOrNo}</Box>
    </Box>
      {isEmpty && <Box dangerouslySetInnerHTML={{ __html: (description as string).replace(/\n/g, '<br>') }} className="order_detail_require_content" style={{wordBreak:'break-all'}} />}
  </Box>
}



const PaymentsMethodAndOrderSummary=({orderSummary,paymentMethod}:OrderSummaryPaymentMethodComp)=>{
  return <Grid container 
  style={{borderRadius:'10px',overflow:'hidden'}}
  >
  <Grid lg={6}md={12} sm={12} xs={12} item><PaymentMethod orderSummary={orderSummary} paymentMethod={paymentMethod}/></Grid>
  <Grid lg={6}md={12} sm={12} xs={12} item>
    <OrderSummary orderSummary= {orderSummary}/>
  </Grid>
</Grid>
}

const PaymentMethod=({paymentMethod}:OrderSummaryPaymentMethodComp)=>{
  return <Box className="order_detail_column_cont">
      <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}>Payment Methods</GoldishSimpleButton>
      <Box className="booking_section" padding={'1rem'} bgcolor={'black'} height={'100%'} borderRadius={'10px 0px 0px 0px'}  style={{height:'22rem',overflow:'auto'}}>
      {paymentMethod && (paymentMethod as PaymentMethodType[]).map((method)=> <CreditCard method={method}/> )}
      </Box>
  </Box>
}


const CreditCard=({method} : {method: PaymentMethodType})=>{
  const {attributes:{amount,card:{data:{attributes:{cards:{card_type,card_number}}}}}} = method;
  return  <Box className = 'order_detail_view_accordion_parent' style={{overflow:'visible'}}>
  <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} expanded={true}>
  <MyAccordionSummary
    style={{minHeight:"64px"}}
    expandIcon={null}
    aria-controls='panel1a-content'
    id='panel1a-header'
    className="cf_avindode_accordion_header"
  >
    <Box className = 'order_detail_view_edit_expand' fontWeight={'bold'} padding={'0.5rem'}>
      <Box>{card_type}(**** **** **** {card_number})</Box>
      <Box className="order_detail_debit_icon_parent"><img src={getIcon(card_type)} style={{height:'2.5rem',width:'4rem',position:'relative',top:"5px"}}/></Box>
    </Box>
  </MyAccordionSummary>
  <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
    <Box className="order_detail_last_child" padding={'0.5rem 0rem'}>
    <EachRowInfo keyName={'Balance used for Booking'} valueName={`$${(amount)}`} />
    </Box>
  </AccordionDetails>
</GenralUsedAccordion>
</Box>
}

const OrderSummary=({orderSummary}:{orderSummary:OrderSummaryType})=>{
  const {flight_price, landing_fee, tax, segment_fee, payment_processing, total_price, payment_recevied, payment_remaining} = orderSummary;
  return <Box className="order_detail_column_cont">
  <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}>Order Summary</GoldishSimpleButton>
  <Box className="booking_section order_detail_order_summary" borderRadius={'0px 10px 0px 0px'}  style={{height:'22rem'}}>
      <Box className="order_detail_order_summary_child">
          <OrderPriceDetails title={'Flight Price'} value={flight_price as string} />
          <OrderPriceDetails title={'Landing Fee'} value={landing_fee as string} />
          <OrderPriceDetails title={'Tax (7.50%)'} value={tax as string} />
          <OrderPriceDetails title={'Segment Fee'} value={segment_fee as string} />
          <OrderPriceDetails title={'Payment Processing Fee (4.00%)'} value={payment_processing as string} />
        <Box className="order_detail_view_dashend_border"></Box>
          <OrderPriceDetails title={'Total'} value={total_price as string} color= '#FFD27E'/>
          <OrderPriceDetails title={'Payment Received'} value={payment_recevied as string} color= '#20E60E'/>
          <OrderPriceDetails title={'Payment Remaining'} value={payment_remaining as string} />
      </Box>
  </Box>
</Box>
}


const OrderPriceDetails=({title,value,color=''}:{title:string,value:string | number, color?:string})=>{
  let valueFigure = value !== null ? ('$' + value) : '- - -';
  return <Box className="order_detail_view_order_each">
    <Box>{title}</Box>
    <Box color={color}>{valueFigure}</Box>
  </Box>
}


const VirtualTerminal=({isPaymentProcessing,setIsPaymentProcessing,existingCardFormikRef,newCardFormRef,savedCard,chargeNewCard,setCardDetails,virtualTerminalData,cardDetails,setActiveFieldName,activeFieldName}:VirtualTerminalType & PaymentProcessingCompType)=>{
  return <Box className = 'order_detail_column_cont'>
        <Box className = 'order_detail_head_edit '>
          <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}>Virtual Terminal</GoldishSimpleButton>
        </Box>
         <Box className='order_detail_black_passenger' padding={'1rem 0rem'}>   
           <VirtualTerminalGrid isPaymentProcessing={isPaymentProcessing} setIsPaymentProcessing={setIsPaymentProcessing} existingCardFormikRef={existingCardFormikRef} newCardFormRef={newCardFormRef} savedCard={savedCard} chargeNewCard={chargeNewCard} setCardDetails={setCardDetails} virtualTerminalData={virtualTerminalData} cardDetails={cardDetails} setActiveFieldName={setActiveFieldName} activeFieldName={activeFieldName}/>
         </Box>
  </Box>
}

const VirtualTerminalGrid=({isPaymentProcessing,setIsPaymentProcessing,existingCardFormikRef,newCardFormRef,savedCard,chargeNewCard,setCardDetails,virtualTerminalData,cardDetails,activeFieldName,setActiveFieldName}:VirtualTerminalType & PaymentProcessingCompType)=>{
  const {is_client_order} = virtualTerminalData as VirtualTerminalExactDataType;
  return <Grid container style={{rowGap:'1rem'}}>

  <Grid sm={12} style={{padding:'1rem'}}>
    <PaymentMethodRadioContainer setCardDetails={setCardDetails} cardDetails={cardDetails} virtualTerminalData={virtualTerminalData as VirtualTerminalExactDataType}/>
  </Grid>

  <Grid lg={6}md={12} sm={12} xs={12} item style={{padding:'0rem 1rem'}}>
    <ChargeNewCard is_client_order={is_client_order} isPaymentProcessing={isPaymentProcessing} setIsPaymentProcessing={setIsPaymentProcessing} existingCardFormikRef={existingCardFormikRef} newCardFormRef={newCardFormRef} savedCard={savedCard} chargeNewCard={chargeNewCard} setCardDetails={setCardDetails} virtualTerminalData={virtualTerminalData} cardDetails={cardDetails} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName}/>
  </Grid>
  <Grid lg={6}md={12} sm={12} xs={12} item style={{padding:'0rem 1rem', borderLeft:'1px solid #3E4247'}}>
    <ChargeExisting isPaymentProcessing={isPaymentProcessing} setIsPaymentProcessing={setIsPaymentProcessing} existingCardFormikRef={existingCardFormikRef} chargeNewCard={chargeNewCard} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} savedCard={savedCard} cardDetails={cardDetails}/>
  </Grid>
</Grid>
}

const PaymentMethodRadioContainer=({cardDetails,virtualTerminalData,setCardDetails}:PaymentMethodRadioContainerType)=>{
  const {incidential_invoice,main_invoice} = virtualTerminalData;
  return <Box style={{padding:'1rem',backgroundColor:'#1A1C1E',borderRadius:'10px'}} className="booking_section">
    <Box>Select Invoice(s) to take payment for</Box>
    <Box style={{display:'flex',alignItems:'center',columnGap:'1.2rem'}}>
        <PaymentWithRadio invoiceDetails={main_invoice} setCardDetails={setCardDetails} cardDetails={cardDetails} name="Main Invoice"/>
        {incidential_invoice && incidential_invoice.map((incidental)=> <PaymentWithRadio invoiceDetails={incidental} setCardDetails={setCardDetails} cardDetails={cardDetails} name={incidental.invoice_name}/>)}
    </Box>
  </Box>
}

const PaymentWithRadio=({cardDetails,name,setCardDetails,invoiceDetails}:PaymentWithRadioType)=>{
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {is_paid,amount_to_pay} = invoiceDetails
    setCardDetails({...cardDetails,invoice_id: +event.target.value,is_paid:is_paid,amount:+amount_to_pay})
  };
  return <Box style={{display:'flex',alignItems:'center',columnGap:'0.3rem'}}>
    <CustomRadio style={{padding:'0rem'}} name="invoice_radio" value={invoiceDetails.id} checked={invoiceDetails.id === cardDetails.invoice_id} onChange={handleChange}/>
    <Box>{name}</Box>
  </Box>
}

const ChargeNewCard = ({is_client_order,isPaymentProcessing,setIsPaymentProcessing,newCardFormRef,chargeNewCard,cardDetails,activeFieldName,setActiveFieldName}:VirtualTerminalType & PaymentProcessingCompType & IsClientOrderType) => {

  const chargeCardHandler=(cardDetails:CardFormType)=>{
    setIsPaymentProcessing('new');
    let cardSendData = {
      "invoice_id": cardDetails.invoice_id,
      "data": 
      {   
          "amount": cardDetails.amount,
          "card_number":cardDetails.card_number,
          "card_holder_name":cardDetails.name_on_card,
          "expiry_date":cardDetails.expiry,
          "security_code":cardDetails.security_code,
          "street_address":cardDetails.street_address,
          "city":cardDetails.city,
          "state":cardDetails.state,
          "zip_code":cardDetails.zip_code,
          "country":cardDetails.country
      }
  }
   chargeNewCard(cardSendData,true);
  }

  return (
    <Box className='order_detail_view_cards_parent booking_section' style={{backgroundColor:'#1A1C1E'}}>
      <Box className='order_detail_view_cards_head'>Charge a New Card</Box>

      <Formik
            initialValues={cardDetails}
            onSubmit={(values) => {
              chargeCardHandler({...values,card_number : cleanCardNumber(values.card_number as unknown as string) as unknown as number});
            }}
            innerRef={newCardFormRef}
            enableReinitialize
            validateOnChange
            validateOnBlur
            key={cardDetails.invoice_id || "new"}
            validationSchema={cardFormValidationSchema(+(cardDetails.amount as unknown as number))}
          >
            {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
              <Grid container>
        <Grid
          lg={6} md={6} sm={6} xs={6}
          item
          style={{ paddingRight: "1rem", borderRight: "1px solid #3E4247",rowGap:"1.55rem"}}
          className="booking_section"
        >
        <Box>
          <PaymentFieldWithLabel 
           isError={isErrorTouched(errors.amount as unknown as boolean,touched.amount as boolean)}
           value={values.amount}
           activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"amount"} handleChange={handleChange} placeholderText={'Enter Amount'} label={"Amount($)"}/>
          <ErrorBoxes touched={touched.amount} errors={errors.amount} message={errors.amount}/>
        </Box>

        <Box>
        <PaymentFieldWithLabel 
         value={formatCardNumber(values.card_number)} 
         isError={isErrorTouched(errors.card_number as unknown as boolean,touched.card_number as boolean)}
        activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"card_number"} handleChange={handleChange} placeholderText={'Card Number'} label={"Card Number"}/>
        <ErrorBoxes touched={touched.card_number} errors={errors.card_number} message={errors.card_number}/>
        </Box>

        <Box>
          <PaymentFieldWithLabel isError={isErrorTouched(errors.name_on_card as unknown as boolean,touched.name_on_card as boolean)}
  value={values.name_on_card} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"name_on_card"} handleChange={handleChange} placeholderText={'Name'} label={"Name on Card"}/>
          <ErrorBoxes touched={touched.name_on_card} errors={errors.name_on_card} message={errors.name_on_card}/>

        </Box>

        <Box display={'flex'} justifyContent={'space-between'} gridColumnGap={'1rem'}>
        <Box flex={1}>
          <PaymentFieldWithLabel isError={isErrorTouched(errors.expiry as unknown as boolean,touched.expiry as boolean)}
 value={handleExpiryChange(values.expiry || '')} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"expiry"} handleChange={handleChange} placeholderText={'MM/YY'} label={"Expiry Date"}/>
          <ErrorBoxes touched={touched.expiry} errors={errors.expiry} message={errors.expiry}/>
        </Box>


        <Box flex={1}>
          <PaymentFieldWithLabel isError={isErrorTouched(errors.security_code as unknown as boolean,touched.security_code as boolean)}
 value={values.security_code} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"security_code"} handleChange={handleChange} placeholderText={'CVV'} label={"Security Code"}/>
          <ErrorBoxes touched={touched.security_code} errors={errors.security_code} message={errors.security_code}/>
        </Box>

        </Box>


        </Grid>
        <Grid
          lg={6} md={6} sm={6} xs={6}
          item
          style={{ paddingLeft: "1rem",rowGap:"1.55rem" }}
          className="booking_section"
        >
              <Box>
              <PaymentFieldWithLabel isError={isErrorTouched(errors.street_address as unknown as boolean,touched.street_address as boolean)}
 value={values.street_address} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"street_address"} handleChange={handleChange} placeholderText={'Street Address'} label={"Street Address"}/>
                <ErrorBoxes touched={touched.street_address} errors={errors.street_address} message={errors.street_address}/>
              </Box>

              <Box>
              <PaymentFieldWithLabel isError={isErrorTouched(errors.city as unknown as boolean,touched.city as boolean)}
 value={values.city} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"city"} handleChange={handleChange} placeholderText={'City'} label={"City"}/>
                <ErrorBoxes touched={touched.city} errors={errors.city} message={errors.city}/>

              </Box>

              <Box display={'flex'} justifyContent={'space-between'} gridColumnGap={'1rem'}>
              <Box flex={1}>
              <PaymentFieldWithLabel isError={isErrorTouched(errors.state as unknown as boolean,touched.state as boolean)} value={values.state}  activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"state"} handleChange={handleChange} placeholderText={'State'} label={"State"}/>
                <ErrorBoxes touched={touched.state} errors={errors.state} message={errors.state}/>
              </Box>


              <Box flex={1}>
                <PaymentFieldWithLabel isError={isErrorTouched(errors.zip_code as unknown as boolean,touched.zip_code as boolean)} value={values.zip_code} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"zip_code"} handleChange={handleChange} placeholderText={'Zip Code'} label={"Zip Code"}/>
                <ErrorBoxes touched={touched.zip_code} errors={errors.zip_code} message={errors.zip_code}/>

              </Box>

        </Box>

              <Box>
                <PaymentLabel isFocused = {activeFieldName === 'country'}>Country</PaymentLabel>
                <CustomDepartueAiportAutoCompplete
                                bgColor={"inherit"}
                                showError={isErrorTouched(errors.country as unknown as boolean,touched.country as boolean)}
                                height={'3rem'}
                                onChange={(_event: Event, newValue: ICountry) => {
                                  setFieldValue('country',newValue.name);
                                }}
                                data-testid = 'country'
                                isFocusedTypeInput={activeFieldName === 'country'}
                                onBlur= {()=>setActiveFieldName('')}
                                onFocus= {()=>setActiveFieldName('country')}
                                id = 'profile_country_selector'
                                PopperComponent={(props: PopperProps) => (
                                    <CustomStyledPopper
                                        {...props}
                                        zIndex={1}
                                        marginTop="1px"
                                    />
                                )}
                                ListboxProps={{ id: "regsiter_option_style" }}
                                getOptionLabel={(option: ICountry) => option.name}
                                options={Country.getAllCountries()}
                                renderOption={(
                                    option: ICountry,
                                    { selected }: { selected: boolean },
                                ) =><CountryOption option={option}/>}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <PaymentTexfields
                                        {...params}
                                        
                                        placeholder="Search Country"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box className="profile_hover_icons">
                                                    <KeyboardArrowDown />
                                                </Box>
                                            ),
                                            startAdornment: (
                                                <Box>
                                                    <SearchOutlined />
                                                </Box>
                                            ),
                                        }}
                                    />
                                )}
                            />
                  <ErrorBoxes touched={touched.country} errors={errors.country} message={errors.country}/>

              </Box>

               </Grid>
               </Grid>
               <FormSubmitButton 
               disabled={!(!cardDetails.is_paid && isPaymentProcessing === null && is_client_order)}
              //  disabled={cardDetails.is_paid || isPaymentProcessing !== null}
                 data-testid = {'process_payment'} onClick={()=>handleSubmit()} fullWidth style={{marginTop:'1rem'}}>{submitValue('new',isPaymentProcessing as unknown as string)}</FormSubmitButton>
            </>)}
      </Formik>
    

    </Box>
  )
}

const isErrorTouched=(error:boolean, touch : boolean)=>{
  return error && touch
}

const handleExpiryChange = (expireValue:string) => {
  let value = expireValue.replace(/\D/g, '');
  if (value.length > 2) {
    value = value.slice(0, 2) + '/' + value.slice(2, 4);
  }
  return value;
};

const PaymentFieldWithLabel=({isError,value,activeFieldName,setActiveFieldName,label,handleChange,placeholderText,formName}:PaymentTextFieldType & {isError?: boolean} & {setActiveFieldName : React.Dispatch<React.SetStateAction<string>>,
  activeFieldName : string})=>{
    const cardInputProp = formName === 'card_number' ? {maxLength : 19} : {};
  return <>
  <PaymentLabel isFocused = {activeFieldName === formName} >{label}</PaymentLabel>
  <CustomFormTextField 
     isFocusedTypeInput={true}
     isErrorTouch = {isError}
     height = '3rem'
     variant='outlined'
     initialBorderColor='white'
     name={formName}
     onChange={handleChange}
     placeholder={`Enter ${placeholderText}`} 
     fullWidth
     value={value}
     autoComplete='off'
     onBlur= {()=>setActiveFieldName('')}
     onFocus= {()=>setActiveFieldName(formName)}
     data-testid = {formName}
     InputProps={{
      inputProps : {
        ...cardInputProp,
        style:{padding:'0px'}
      }
     }}
     />
</>
}

const ErrorBoxes = ({touched,errors,message}:{touched: TouchedMessage,errors : string | undefined,message:string | undefined})=>{
  return <>
    {touched && errors && (
      <Box className="login_valid_error" id = 'profile_error_properties'>
        {message}*
      </Box>
    )}
  </>
}

const CountryOption=({option}:{option:ICountry})=>{
  return (
    <Box className="register_airport_option">
       <Box color={'#636E72'}>
         <RoomOutlined/>
       </Box>
       <Box>{option.name}</Box>
    </Box>
  )
}

const submitValue=(type:'new' | 'existing',isPaymentProcessing:string)=>{
  return  isPaymentProcessing === type ? 'Processing...' : 'Process Payment' 
}

const ChargeExisting=({setIsPaymentProcessing,isPaymentProcessing,existingCardFormikRef,chargeNewCard,activeFieldName,setActiveFieldName,cardDetails,savedCard}:ChargeExistingCompType & PaymentProcessingCompType)=>{
  const {is_paid,amount,invoice_id,saved_payment_card_id} = cardDetails;
  const init_values  = {
    amount_pay:amount,
    invoice_id,
    card_id:saved_payment_card_id
  }
  const cannot_pay = !is_paid && savedCard?.length !== 0 && isPaymentProcessing === null
  const handleExistingCardSubmit =(values:InitExistingCardValuesType)=>{
    setIsPaymentProcessing('existing');
    const {amount_pay,card_id,invoice_id} = values;
    let cardData = {
      invoice_id,
      card_id,
      data : {
        amount : amount_pay
      }
    }
    chargeNewCard(cardData,false);
  }

  return <Formik
  initialValues={init_values}
  onSubmit={(values) => {
    handleExistingCardSubmit(values)
  }}
  innerRef={existingCardFormikRef}
  enableReinitialize
  validateOnChange
  validateOnBlur
  key={cardDetails.invoice_id || "newone"}
  validationSchema={chargeExistingCardValidation(+(cardDetails.amount as unknown as number))}
>
  {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
    <Box className="order_detail_view_cards_parent booking_section" style={{rowGap:"1.85rem",backgroundColor:'#1A1C1E'}}>
    <Box className="order_detail_view_cards_head">Charge an Existing Card</Box>
    <Box>
          <PaymentFieldWithLabel isError={(touched.amount_pay && errors.amount_pay) as boolean} value={values.amount_pay} activeFieldName={activeFieldName} setActiveFieldName={setActiveFieldName} formName={"amount_pay"} handleChange={handleChange} placeholderText={'Enter Amount'} label={"Amount($)"}/>
          <ErrorBoxes touched={touched.amount_pay} errors={errors.amount_pay} message={errors.amount_pay}/>
    </Box>
    <Box className="order_detail_last_child" borderRadius={'10px'} overflow={'auto'} height={'16rem'} bgcolor={'black'}>
      {savedCard && savedCard.map((cardSave)=> <PaymentRadios handleChangeCard={()=>{setFieldValue('card_id',cardSave.id)}} cardDetails={cardSave} isChecked = {cardSave.id === values.card_id}/>)}
    </Box>
    <FormSubmitButton data-testid = {'process_payment_existing'} onClick={()=>handleSubmit()} disabled={values.card_id === null || !cannot_pay} fullWidth>{submitValue('existing',isPaymentProcessing as unknown as string)}</FormSubmitButton>
  </Box>
  </>)}</Formik>

}

const getIcon = (card_type:string) => {
  switch (card_type) {
      case 'MasterCard':
          return master_image;
      case 'Visa':
          return visa_image;
      case 'Amex':
          return americalExpess;
      default:
          return defaultCard;
  }
};

const PaymentRadios=({cardDetails,isChecked,handleChangeCard}:{cardDetails:SavedCardType,isChecked:boolean,handleChangeCard:()=>void})=>{

  const {attributes:{cards:{card_number,card_type,expiry_date}}} = cardDetails;
    return <Box className="order_detail_view_radio_parent">
        <CustomRadio checked={isChecked} color="primary" onChange={handleChangeCard} data-testid = {'process_payment_existing_radio'}/>
        <Box className="order_detail_view_image_parent"><img style={{objectFit:"cover"}} src={getIcon(card_type)} height={'80%'} width={'100%'}/></Box>
        <Box className="order_detail_card_name_expiry_parent" marginLeft={'1rem'}>
          <Box fontSize={'1.1rem'} fontWeight={'bold'}>{card_type} ending in {card_number}</Box>
          <Box className="order_detail_expiry_font">Exp date: {expiry_date}</Box>
        </Box>
    </Box>
}


const RefundSection=({refundProcessingLoaderIndex,setRefundProcessingLoaderIndex,moment,refundData,refundInitValue,processRefund}:RefundCompType & RefundLoaderType)=>{
  return <Box className = 'order_detail_column_cont'>
        <Box className = 'order_detail_head_edit '>
          <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start' }}>Refund</GoldishSimpleButton>
        </Box>
         <Box className='order_detail_black_passenger' padding={'1rem'}>   
           {refundData.map((refund,refIndex)=><RefundCard refIndex={refIndex} refundProcessingLoaderIndex={refundProcessingLoaderIndex} setRefundProcessingLoaderIndex={setRefundProcessingLoaderIndex} moment={moment} processRefund={processRefund} refund = {refund} refundInitValue={refundInitValue}/>)}
         </Box>
  </Box>
}

const RefundCard=({moment,refund,refundInitValue,processRefund,refIndex,refundProcessingLoaderIndex,setRefundProcessingLoaderIndex}:RefundCardCompType & RefundLoaderType & {refIndex:number})=>{
  const {attributes:{amount,card:{data:{attributes:{cards:{card_type,card_number}}}}},id} = refund;
  const heading =  `${card_type} (**** **** **** ${card_number})`;
  const isDisable = refIndex === refundProcessingLoaderIndex;
  const buttonPlacehoder = isDisable ? 'Processing...' : 'Process Refund'
  const submitRefundForm=(values : RefundInitValueType)=>{
    const {amount} = values
    const valuesToSubmit = {
      amount,order_payment_id:id
    }
    processRefund(valuesToSubmit);
    setRefundProcessingLoaderIndex(refIndex);
  }

  return  <>
            <Formik
              enableReinitialize
              initialValues={{...refundInitValue}}
              onSubmit={(values) => {
                submitRefundForm(values)
              }}
              key={id}
              validationSchema={refunFormValidation(amount as unknown as number)}
              validateOnChange
              validateOnBlur
            >
              {(formikProps : FormikProps<RefundInitValueType>) => (<>
                    <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} expanded={true}>
                    <MyAccordionSummary
                      expandIcon={null}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                      className="cf_avindode_accordion_header"
                      style={{height:'auto'}}
                    >
                      <Box className = 'order_detail_view_edit_expand'>
                        <Box style={{display:'flex',justifyContent:'space-between',alignItems:"center",width:"100%",padding:'0.5rem 0rem'}}>
                          <Box fontWeight={'bold'}>{heading}</Box>
                          <Box className="order_detail_debit_icon_parent"><img src={getIcon(card_type)} style={{height:'3rem',width:'5rem',position:'relative',top:"5px"}}/></Box>
                        </Box>
                      </Box>
                    </MyAccordionSummary>
                    <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
                      <RefundComponent moment={moment} processRefund={processRefund} refund={refund} formikProps={formikProps} refundInitValue={refundInitValue}/>
                    </AccordionDetails>
                  </GenralUsedAccordion>
                  <FormSubmitButton data-testid = 'submit_form_refund' disabled={refundProcessingLoaderIndex === refIndex} onClick={()=>formikProps.handleSubmit()} fullWidth>{buttonPlacehoder}</FormSubmitButton>
              </>)}</Formik>
  </> 
}

const RefundComponent=({refund,formikProps,moment}:RefundCardCompType & RefundFormikType)=>{
  const {handleChange,errors,touched,values} = formikProps;
  const {attributes:{show_amount}} = refund;
  const {attributes:{refund_history:{data}}} =refund
  return(
    <>
  <Box padding={'2rem 1rem'} className='order_detail_head_edit'>

    <Box className='order_detail_view_menu_box'>
      <Box className="order_detail_doc_parent" style={{columnGap:'0.5rem'}}>
      <Box>Paid using this method</Box>
      <Box>-</Box>
      <Box>${show_amount}</Box>
      </Box>
      <Box className="order_detail_require_content">(Max. Refund to this method is ${show_amount})</Box>
    </Box>
    <Box>
      <PaymentLabel>Refund Amount</PaymentLabel>
      <PaymentTexfields className={ touched.amount && errors.amount ? "register_border_error" : ''} value={values.amount} id='amount' placeholder='Refund Amount' style={{minWidth:'20rem'}} onChange={handleChange}/>
      {touched.amount && errors.amount && (
      <Box className="login_valid_error" id="profile_error_properties">
        {errors.amount}*
      </Box>
    )}
    </Box>    
  </Box>
    <RefundInfoComponent refundInfo={data} moment={moment}/>
  </> 
  )
}

const RefundInfoComponent = ({refundInfo,moment}:RefundInfoComponentType)=>{
  return(
    <>
    {refundInfo.length !== 0 &&  
      <Box style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"1rem",textAlign:"center"}}>
        {refundInfo.map((refund)=>(
          <Box style={{background:"#000000",borderRadius:"1rem",fontSize:"1rem",fontWeight:500,padding:"1rem"}}>
          On {formatDateWithSuperscript(refund.attributes.created_at,moment)} - <span style={{color:"#FFD27E"}}>${refund.attributes.amount}</span> refund issued to this payment method   
        </Box>
        ))}
      </Box>
    }
    </>
  )
}

const formatDateWithSuperscript = (dateString:string,moment:momentType) => {
  const date = moment(dateString);
  const dayName = date.format('D');
  const suffix = date.format('Do').replace(dayName, '');
  const monthYear = date.format('MMM YYYY');

  return (
    <span>
      {dayName}<sup>{suffix}</sup> {monthYear}
    </span>
  );
};



const InvoiceSection=({mainInvoice,downloadInvoice,moment}:InvoiceSectionType)=>{
  return <Box className = 'order_detail_column_cont'>
   <Box className = 'order_detail_view_accordion_parent'>
     <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} >
           <MyAccordionSummary
             expandIcon={<KeyboardArrowDown style={{color:'white'}}
             />}
             aria-controls='panel1a-content'
             id='panel1a-header'
             className="cf_avindode_accordion_header"
           >
             <Box className = 'order_detail_view_edit_expand'>
               <Box>Main Invoice</Box>
             </Box>
           </MyAccordionSummary>
           <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
             <MainInvoiceContent mainInvoice={mainInvoice} downloadInvoice={downloadInvoice} moment={moment}/>
           </AccordionDetails>
     </GenralUsedAccordion>
  </Box>
</Box>
}

const MainInvoiceContent=({mainInvoice,downloadInvoice,moment}:InvoiceSectionType)=>{
  const {id} = mainInvoice;
  return <Box className = 'invoice_section booking_section' padding={'1rem 1.5rem'}>
      <StartJetAddress mainInvoice={mainInvoice} moment={moment}/>
      <FlightItenaryInvoice  mainInvoice={mainInvoice} moment={moment}/>
      <FormSubmitButton data-testid = 'main_invoice_down' fullWidth onClick={()=>downloadInvoice(id)}>Download Invoice</FormSubmitButton>
  </Box>
}

const PassengerInfoMainInvoice=({passenger_information}:PassengerInfoMainInvoiceCompType)=>{
  return <Box className = 'order_detail_view_accordion_parent' data-testid = 'invoice_passenger_info'>
    <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} >
  <MyAccordionSummary
      expandIcon={<KeyboardArrowDown style={{color:'white'}}
      />}
      aria-controls='panel1a-content'
      id='panel1a-header'
      className="cf_avindode_accordion_header order_detail_accord_header"
  >
    <Box className = 'order_detail_view_edit_expand'>
        <Box>Passenger Information</Box>
    </Box>
  </MyAccordionSummary>
  <AccordionDetails style={{backgroundColor:'black'}} className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
    <PassengerInvoiceGrid passenger_information={passenger_information}/>
  </AccordionDetails>
</GenralUsedAccordion>
</Box>
}

const PassengerInvoiceGrid=({passenger_information}:PassengerInfoMainInvoiceCompType)=>{
  return <Grid container>
    {passenger_information.map(({name,passport_number},passengerIndex)=><PassengerInvoiceRow passportName={name} passportNumber={passport_number} passengerIndex={passengerIndex}/>)}
</Grid>
}

const PassengerInvoiceRow=({passportName,passportNumber,passengerIndex}:PassportGridCompType)=>{
  return <>
    <Grid lg={6}md={12} sm={12} xs={12} item className="order_detail_last_child">    
    <EachRowInfo keyName={`Passenger ${passengerIndex+1}`} valueName={passportName}/>
      </Grid>
    <Grid lg={6}md={12} sm={12} xs={12} item style={{borderLeft:'1px solid #3E4247'}} className="order_detail_last_child">
    <EachRowInfo keyName={'Passport Number'} valueName={passportNumber}/>
    </Grid>
  </> 
}



const StartJetAddress=({mainInvoice,moment}:{mainInvoice : MainInvoiceType | IncidentalsInvoiceType['data'], moment :momentType })=>{
  return <Box className = 'booking_section'>
  <Box className = 'order_detail_view_heading_parent order_detail_view_cards_parent' style={{padding:'1rem 0rem'}}>
        <Grid container>
            <Grid item lg={4} sm={12} style={{padding:'0rem 1rem'}}>
                <AddressInfo mainInvoice={mainInvoice as MainInvoiceType}/>
            </Grid>
            <Grid item lg={4} sm={12} style={{padding:'0rem 1rem'}} className="invoice_middle_container">
                 <InvoiceInfo mainInvoice={mainInvoice as MainInvoiceType} moment={moment}/> 
            </Grid>
            <Grid item lg={4} sm={12} style={{padding:'0rem 1rem'}}>
                  <BilledToInfo mainInvoice={mainInvoice as MainInvoiceType}/>
            </Grid>
        </Grid>
  </Box>
  </Box>
}

const AddressInfo=({mainInvoice}:{mainInvoice:MainInvoiceType})=>{
  const {attributes:{starjet_address,starjet_email,fax,phone,url,mobile}} = mainInvoice;
  return <Box className="booking_section" style={{rowGap:'0.5rem'}}>
      <Box className="invoice_section_head">Star Jets International</Box>
      <Box>{starjet_address}</Box>
      <InvoiceKeyAndValue keyName={'Mobile:'} valueName={mobile}/>
      <InvoiceKeyAndValue keyName={'Phone:'} valueName={phone}/>
      <InvoiceKeyAndValue keyName={'Fax:'} valueName={fax}/>
      <InvoiceKeyAndValue keyName={'Email:'} valueName={starjet_email}/>
      <InvoiceKeyAndValue keyName={'URL:'} valueName={url}/>

  </Box>
}


const InvoiceKeyAndValue=({keyName,valueName='Static',isSpreaded=false}:{keyName:string,valueName?:string,isSpreaded?:boolean})=>{
  return <Box style={{display:'flex',columnGap:"0.5rem",justifyContent:isSpreaded ? 'space-between' : ''}}>
      <Box className="order_detail_require_content">{keyName}</Box>
      <Box>{valueName}</Box>
  </Box>
}

const InvoiceInfo=({mainInvoice,moment}:{mainInvoice:MainInvoiceType, moment:momentType})=>{
  const {attributes:{invoice_number,issued_date,due_date,account_id,client_name}} = mainInvoice;
  return <Box className="booking_section" style={{rowGap:'0.5rem'}}>
    <Box className="invoice_section_head">Invoice Details</Box>
    <InvoiceKeyAndValue keyName="Invoice ID:" valueName={invoice_number}/>
    <InvoiceKeyAndValue keyName="Issued Date:" valueName={moment(issued_date).format('DD-MM-YYYY')}/>
    <InvoiceKeyAndValue keyName="Due Date:" valueName={moment(due_date).format('DD-MM-YYYY')}/>
    <InvoiceKeyAndValue keyName="Client Name:" valueName={client_name}/>
    <InvoiceKeyAndValue keyName="Account ID:" valueName={account_id}/>
  </Box>
}

const TotalAmountComp=({figure}:{figure:string})=>{
  return <Box display={'flex'} justifyContent={'space-between'} className="invoice_section_head">
    <Box>Total Amount</Box>
    <Box>${figure}</Box>
  </Box>
}

const BilledToInfo=({mainInvoice}:{mainInvoice : MainInvoiceType})=>{
  const {attributes:{billed_to:{company_name,address,city,pincode,main_country_of_residence}}} = mainInvoice;
  return <Box className="booking_section" style={{rowGap:'0.5rem'}}>
    <Box className="invoice_section_head">Billed To</Box>
    <Box>
      <Box marginBottom={'1rem'}>{company_name}</Box>
      <Box>{address}</Box>
      <Box>{city} {pincode}</Box>
      <Box>{main_country_of_residence}</Box>
    </Box>
  </Box>
}

const FlightItenaryInvoice=({mainInvoice,moment}:{mainInvoice:MainInvoiceType,moment:momentType})=>{
  const {attributes:{invoice_details:{data},passenger_information}} = mainInvoice;
  return <Box className = 'booking_section'>
       <Box className = 'order_detail_view_heading_parent'>
        <Box className='order_detail_view_menu_box'>
          <Box>Itinerary</Box>
          <Box className="order_detail_require_content">View flight details below.</Box>
        </Box>
        </Box>

        {data.map((eachFlightInvoice:FlightInteneraryType,elemIndex:number)=>{
          return <FlightAccordionInvoice eachFlightInvoice={eachFlightInvoice} legNumber={elemIndex+1} moment={moment}/>
        })}
        {passenger_information.length !== 0 && <PassengerInfoMainInvoice passenger_information={passenger_information}/>}
        <Box className = 'order_detail_horizantal_row'/>
        <FlightPaymentMethodInvoice mainInvoice={mainInvoice} moment={moment}/>  

  </Box>
}

const FlightAccordionInvoice=({legNumber,eachFlightInvoice,moment}:{legNumber:number,eachFlightInvoice:FlightInteneraryType,moment: momentType}) =>{
  return <Box className = 'order_detail_view_accordion_parent'>
  <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} >
  <MyAccordionSummary
    expandIcon={<KeyboardArrowDown style={{color:'white'}}
    />}
    aria-controls='panel1a-content'
    id='panel1a-header'
    className="cf_avindode_accordion_header order_detail_accord_header"
  >
    <Box className = 'order_detail_view_edit_expand'>
      <Box>Flight {legNumber}</Box>
    </Box>
  </MyAccordionSummary>
  <AccordionDetails style={{backgroundColor:'black'}} className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
    <InvoiceFlightsGrids eachFlightInvoice={eachFlightInvoice} moment={moment}/>
  </AccordionDetails>
</GenralUsedAccordion>
</Box>
}

const InvoiceFlightsGrids=({eachFlightInvoice,moment}:{eachFlightInvoice:FlightInteneraryType,moment:momentType})=>{
  return  <Grid container>
  <Grid lg={6}md={12} sm={12} xs={12} item>    
    <FlightGrid1 eachFlightInvoice={eachFlightInvoice} moment={moment}/>
    </Grid>
  <Grid lg={6}md={12} sm={12} xs={12} item style={{borderLeft:'1px solid #3E4247'}}>
    <FlightGrid2 eachFlightInvoice={eachFlightInvoice}/>
  </Grid>
</Grid>
}

const FlightGrid1=({eachFlightInvoice,moment}:{eachFlightInvoice:FlightInteneraryType,moment:momentType})=>{
  const { attributes : {from_airport, to_airport, departure_date, arrival_date, duration, no_of_stops}}= eachFlightInvoice;
  const toAiportName = `${to_airport.icao}/${to_airport.iata} - ${to_airport.name}`;
  const fromAiportName = `${from_airport.icao}/${from_airport.iata} - ${from_airport.name}`;


  return <Box className="order_detail_last_child">
      <EachRowInfo keyName={'From'} valueName={fromAiportName}/>
      <EachRowInfo keyName={'To'} valueName={toAiportName}/>
      <EachRowInfo keyName={'Departure (Local)'} valueName={moment.utc(departure_date).format('DD-MMM-YYYY | HH:mm')}/>
      <EachRowInfo keyName={'Arrive (Local)'} valueName={ moment.utc(arrival_date).format('DD-MMM-YYYY | HH:mm')}/>
      <EachRowInfo keyName={'Flight Time'} valueName={duration}/>
      <EachRowInfo keyName={'Number of Stops'} valueName={no_of_stops}/>
  </Box>
}

const FlightGrid2=({eachFlightInvoice}:{eachFlightInvoice:FlightInteneraryType})=>{
  const { attributes : {aircraft_type,tail_number,fbo,fbo_telephone,fbo_address,call_sign}}= eachFlightInvoice;
  return <Box className="order_detail_last_child">
      <EachRowInfo keyName={'Aircraft Type'} valueName={aircraft_type}/>
      <EachRowInfo keyName={'Tail Number'} valueName={tail_number}/>
      <EachRowInfo keyName={'Call Sign'} valueName={call_sign}/>
      <EachRowInfo keyName={'FBO'}  valueName={fbo}/>
      <EachRowInfo keyName={'FBO Telephone'}  valueName={fbo_telephone}/>
      <EachRowInfo keyName={'FBO Address'}  valueName={fbo_address}/>
  </Box>
}


const FlightPaymentMethodInvoice=({mainInvoice,moment}:{mainInvoice : MainInvoiceType} & MomentCompType)=>{
  const {attributes:{payment_methods}} = mainInvoice;
  return <Box className = 'booking_section'>
    <Box>Payment Methods</Box>
    <Box className = 'order_detail_view_cards_parent' style={{padding:'1rem 0rem'}}>

    <Grid container style={{rowGap:"2rem"}}>
      {payment_methods && (payment_methods as PaymentMethodCardType[]).map((payment_card,card_ind)=><GridItem index={card_ind}><DebitSkyCardInvoiceInfo moment={moment} payment_card={payment_card}/></GridItem>)}

  <GridItem index={payment_methods.length}>
  <OrderSummaryInvoiceInfo mainInvoice={mainInvoice}/>
  </GridItem>
</Grid>      

    </Box>
  </Box>
}

const DebitSkyCardInvoiceInfo=({payment_card,moment}:DebitSkyCardInvoiceInfoType & MomentCompType)=>{
  const {account_name,account_number,auth_code,avs_status,cc_type,charged_to_this_payment_method,currency,cvv_status,date,entry_method,merchant,processor,transaction_id,transaction_type} = payment_card;
  return <>
      <Box className="invoice_section_head" data-testid = 'invoice_card_info'>{cc_type} (**** **** **** {account_number})</Box>
      <InvoiceKeyAndValue keyName="Charged to this payment method:" valueName={charged_to_this_payment_method} />
      <InvoiceKeyAndValue keyName="CC Type:" valueName={cc_type} />
      <InvoiceKeyAndValue keyName="Processor:" valueName={processor} />
      <InvoiceKeyAndValue keyName="AVS Status:" valueName={avs_status}/>
      <InvoiceKeyAndValue keyName="CVV Status:" valueName={cvv_status} />
      <InvoiceKeyAndValue keyName="Auth. Code:" valueName={auth_code} />
      <InvoiceKeyAndValue keyName="Currency:" valueName={currency} />
      <InvoiceKeyAndValue keyName="Account Name:" valueName={account_name} />
      <InvoiceKeyAndValue keyName="Account Number:" valueName={account_number} />
      <InvoiceKeyAndValue keyName="Merchant:" valueName={merchant} />
      <InvoiceKeyAndValue keyName="Date:" valueName={moment(date).local().format("MM/DD/YYYY, h:mm:ss A")} />
      <InvoiceKeyAndValue keyName="Transaction ID:" valueName={transaction_id} />
      <InvoiceKeyAndValue keyName="Transaction Type:" valueName={transaction_type} />
      <InvoiceKeyAndValue keyName="Entry Method:" valueName={entry_method} />

  </>
}

const OrderSummaryInvoiceInfo=({mainInvoice}:{mainInvoice : MainInvoiceType})=>{
  const {attributes:{order_summary:{flight_price, landing_fee,payment_processing_fee,segment_fee,tax,total_price}}} = mainInvoice;
  return <>
       <Box className="invoice_section_head">Order Summary</Box>
       <InvoiceKeyAndValue isSpreaded keyName="Flight Price:" valueName={`$${flight_price}`} />
       <InvoiceKeyAndValue isSpreaded keyName="Landing Fee:" valueName={`$${landing_fee}`} />
       <InvoiceKeyAndValue isSpreaded keyName="Tax:" valueName={`$${tax}`} />
       <InvoiceKeyAndValue isSpreaded keyName="Segment Fee:" valueName={`$${segment_fee}`} />
       <InvoiceKeyAndValue isSpreaded keyName="Payment Processing Fee:" valueName={`$${payment_processing_fee}`} />
       <HorizantalRow/>
       <TotalAmountComp figure={`${total_price}`}/>
  </>
}


const IncidentalsInvoice=({ setIsSureModalOpen,setDeleteFunction,moment, propagationStop, eachInvoice,setIsInvoiceVisible, downloadInvoice,manageIncidentalInvoice, setEditInvoiceInitialValue}:IncidientaiInvoiceParentType)=>{
  const {attributes:{invoice_name}} = eachInvoice;

  function extractInvoiceData() {
    const invoiceName = eachInvoice.attributes.invoice_name;
    const invoices = eachInvoice.attributes.invoice_details.data.map((items) => ({
        item_name: items.attributes.item_name,
        price: items.attributes.price,
        quantity: items.attributes.quantity,
        id:items.id
    }));

    setEditInvoiceInitialValue({
      initial_value:{
        invoice_name: invoiceName,
        invoice_items_attributes: invoices
      },
      invoiceId:eachInvoice.id
    } as unknown as null);
    setIsInvoiceVisible(true);
}

  return <Box className = 'order_detail_column_cont'>
   <Box className = 'order_detail_view_accordion_parent'>
     <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} >
           <MyAccordionSummary
             expandIcon={<KeyboardArrowDown style={{color:'white'}}
             />}
             aria-controls='panel1a-content'
             id='panel1a-header'
             className="cf_avindode_accordion_header"
           >
             <Box className = 'order_detail_view_edit_expand' style={{color:'white'}}>
               <Box>{invoice_name}</Box>
               <Box>
                  <IconButton
                    data-testid = 'delete_invoice'
                    onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      propagationStop(event);
                      setDeleteFunction({deleteId:eachInvoice.id,lastClicked:'incidental_invoice'})
                      setIsSureModalOpen(true);
                    }}
                  >
                    <DeleteOutline style={{color:colors.GOLD_COLOR}} className="advance_search_remove_leg"/>
                  </IconButton>
                  <IconButton
                    data-testid = 'edit_invoice'
                    onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
                      propagationStop(event);
                      extractInvoiceData();
                    }}
                  >
                    <Edit style={{color:colors.GOLD_COLOR}}/>
                  </IconButton>
               </Box>
             </Box>
           </MyAccordionSummary>
           <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
             <IncidentalsInvoiceContent eachInvoice={eachInvoice} downloadInvoice={downloadInvoice} moment={moment}/>
           </AccordionDetails>
     </GenralUsedAccordion>
  </Box>
</Box>
}

const IncidentalsInvoiceContent=({eachInvoice,downloadInvoice,moment}:{eachInvoice:MainInvoiceType | IncidentalsInvoiceType['data'],downloadInvoice:(invoiceId:string) => Promise<void>,moment:momentType})=>{
  return <Box className = 'invoice_section booking_section' padding={'1rem 1.5rem'}>
          <StartJetAddress mainInvoice={eachInvoice} moment={moment}/>
          <ItemDetails eachInvoice={eachInvoice} moment={moment}/>
          <FormSubmitButton data-testid = 'incidental_invoice_down' fullWidth onClick={()=>downloadInvoice(eachInvoice.id)}>Download Invoice</FormSubmitButton>
   </Box>
}

const ItemDetails=({eachInvoice,moment}:{eachInvoice : MainInvoiceType | IncidentalsInvoiceType['data']} & MomentCompType)=>{
  return <Box className = 'booking_section'>
      <Box>Order Details</Box>
      <Box className="order_detail_require_content">Type individual line items below.</Box>
      <ItemDetailsTable eachInvoice={eachInvoice as IncidentalsInvoiceType['data']}/>
      <Box className = 'order_detail_horizantal_row'/>
      <IncidentalPaymentMethodInvoice mainInvoice={eachInvoice} moment={moment}/>
  </Box>
}

const IncidentalPaymentMethodInvoice=({mainInvoice,moment}:{mainInvoice:MainInvoiceType | IncidentalsInvoiceType['data']} & MomentCompType)=>{
  const {attributes:{payment_methods}} = mainInvoice;

  return <Box className = 'booking_section'>
    <Box>Payment Methods</Box>
    <Box className = 'order_detail_view_cards_parent' style={{padding:'1rem 0rem'}}>

    <Grid container style={{rowGap:"2rem"}}>
    {payment_methods && (payment_methods as PaymentMethodCardType[]).map((payment_card,card_ind)=><GridItem index={card_ind}><DebitSkyCardInvoiceInfo moment={moment} payment_card={payment_card}/></GridItem>)}

    <GridItem index={1}>
    <IncidentalOrderSummaryInvoiceInfo mainInvoice={mainInvoice as MainInvoiceType}/>
  </GridItem>
</Grid>      
    </Box>
  </Box>
}

const IncidentalOrderSummaryInvoiceInfo=({mainInvoice}:{mainInvoice:MainInvoiceType})=>{
  const {attributes:{invoice_amount:{tax,total_price,sub_total}}} = mainInvoice;
  return <>
      <Box className="invoice_section_head">Order Summary</Box>
      <InvoiceKeyAndValue isSpreaded keyName="Order Amount:" valueName={`$${(+sub_total).toLocaleString()}`} />
      <InvoiceKeyAndValue isSpreaded keyName="Tax:" valueName={`$${(+tax).toLocaleString()}`} />
      <InvoiceKeyAndValue isSpreaded keyName="Payment Processing Fee:" valueName='$0*' />
      <HorizantalRow/>
      <TotalAmountComp figure={(+total_price).toLocaleString()}/>
  </>
}

const ItemDetailsTable=({eachInvoice}:{eachInvoice:IncidentalsInvoiceType['data']}) =>{
  const {attributes:{invoice_details:{data}}} = eachInvoice;
  return <Box className = 'order_detail_view_accordion_parent'>
  <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} expanded={true}>
  <MyAccordionSummary
    expandIcon={null}
    aria-controls='panel1a-content'
    id='panel1a-header'
    className="cf_avindode_accordion_header order_detail_accord_header"
    
  >
    <Box className = 'order_detail_view_edit_expand'>
    <Box flex={1}>Item</Box>
      <Box flex={1} textAlign={'center'}>Price</Box>
      <Box flex={1} textAlign={'center'}>Quantity</Box>
      <Box flex={1} textAlign={'right'}>Sub-Total</Box>
    </Box>
  </MyAccordionSummary>
  <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
  {data.map((itemInfo)=> <ItemDetailsTableContent itemInfo={itemInfo}/>)}
  </AccordionDetails>
</GenralUsedAccordion>
</Box>
}

const ItemDetailsTableContent=({itemInfo}:{itemInfo:IncidentalDetailObjectType})=>{
  const {attributes:{item_name,quantity,price,total_price}} = itemInfo;
  return <Box style={{backgroundColor:'black',padding:'1rem'}}  borderTop={'1px solid #373A3E'}>
  <Box className = 'order_detail_view_edit_expand'>
      <Box flex={1}>{item_name}</Box>
      <Box flex={1} textAlign={'center'}>${price}</Box>
      <Box flex={1} textAlign={'center'}>{quantity}</Box>
      <Box flex={1} textAlign={'right'}>${total_price}</Box>
  </Box>
  </Box>
}


// Customizable Area End

export default OrderManagementView;
