import React from "react";
import { Box, Grid,styled, InputAdornment, FormControlLabel, IconButton, Button } from "@material-ui/core";
import { MuiModal, GoldishSimpleButton, PaymentLabel, CustomRadio, StyledModalContent, CustomFormTextField, 
  CustomDepartueAiportAutoCompplete, CustomStyledPopper, FormSubmitButton, AddLegtTypeButton,CustomTextField,PassengerLabel, StyledGrid, PhoneNumberFieldBox, ContainerSameAsTextField, renderOptionFrom } from "./MuiCustomComponents";
import { theme as colors } from "./utils";
import { KeyboardArrowDown, DateRangeOutlined,RoomOutlined,SearchOutlined,ClearOutlined,DeleteOutline,SaveAlt} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import { Formik,} from "formik";
import moment from "moment";
import * as Yup from 'yup';
import { ICountry, IState, ICity, Country, State,City} from 'country-state-city';
import {PDF_ICON} from "./assets"

const passengerSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    lastName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Name cannot be empty*')
    .required('Last Name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    dateOfBirth:Yup.string().required('Date of Birth is required*'),
    gender:Yup.string().required('Gender is required*'),
    passportNumber:Yup.string().required('Passport Number is required*'),
    dateOfInsurance:Yup.string()
    .required('Date of Insurance is required*')
    .test('insurance-expiration', 'Date of Insurance must be before Date of Expiration', function(value) {
      const {dateOfExpiration} = this.parent;
      if (!dateOfExpiration || !value) return true;
      return new Date(value) <= new Date(dateOfExpiration);
    }),
    phoneNumber: Yup.string().required('Phone number is required*'),
    dateOfExpiration:Yup.string()
    .required("Date of Expiration is required")
    .test('expiration-after-issuance', 'Expiration date must be after issuance date', function(value) {
      const { dateOfInsurance } = this.parent; // Accessing the value of 'dateOfInsurance'
      if (!dateOfInsurance || !value) return true; // If either of the dates is not provided, return true
      const expirationDate = new Date(value);
      const issuanceDate = new Date(dateOfInsurance);
      return expirationDate >= issuanceDate;
    }),
    countryOfIssue:Yup.string().required('Country of Issue is required*'),
    emailId: Yup.string()
    .trim()
    .email('Invalid email*')
    .required('Email is required*'),
    address:Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Address cannot be empty*')
    .required('Address is required*')
    .test('is-not-only-spaces', 'Address cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    weight: Yup.number()
    .typeError('Weight must be a number')
    .min(1, 'Weight cannot be empty*')
    .required('Weight is required*'),
    passportFile: Yup.mixed().nullable().required('Passport is required'),

});

const phoneValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required*'),
  fullName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
  preferredAirport: Yup.object().shape({
    name: Yup.string()
      .required("Preffered Airport is required*")
      .min(1, "Preffered Airport can't be empty")
  })
    .nullable()
    .required('Preffered Airport is required*'),
  emailId: Yup.string()
    .trim()
    .email('Invalid email*')
    .required('Email is required*')
});

const cardValidationSchema = Yup.object().shape({
  cardholderName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Cardholder Name cannot be empty*')
    .required('Cardholder Name is required*')
    .test('is-not-only-spaces', 'Cardholder Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    expiryDate:Yup.string().required('Expiry Date is required*'),
    cardNumber:Yup.string().required('Card Number is required*'),
    securityCode:Yup.string().required('Security Code is required*'),
});





const formatDate = (date:Date|null,cardFormat=false) => {
  return date?cardFormat?moment(date).format("MM/YY"): moment(date).format('MMM D, YYYY'):"" 
};

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label.Mui-disabled":{
    color:"#fff"
  }
})

const radioStyle = (actionType:string,gender:string,selectedGender:string)=>{
  if(actionType === "show" && gender != selectedGender.toLowerCase()){
    return {display:"none"}
  }
  else{
    return {}
  }
}

const today = new Date();
today.setDate(today.getDate());

const countries = Country.getAllCountries()

const CancelAndSaveChanges = ({ cancel, submit = () => null, isDisabled = false,section = "admin",isSaving=false }: { cancel: () => void, submit?: () => void, isDisabled?: boolean,section?:string,isSaving?:boolean }) => {
  const saveButton = isSaving ? "Saving..." : 'Save'
 return(section==="admin"?<>
 <FormSubmitButton fullWidth disabled={isDisabled} onClick={()=>submit()} data-testid="save_changes_button">{saveButton}</FormSubmitButton>
 <AddLegtTypeButton fullWidth onClick={() => cancel()} data-testid="close_modal">Cancel</AddLegtTypeButton>
 </>: <Box mt={3}>
    <FormSubmitButton fullWidth disabled={isDisabled} onClick={()=>submit()} data-testid="save_changes_button">{saveButton}</FormSubmitButton>
    <AddLegtTypeButton fullWidth onClick={() => cancel()} style={{marginTop:"1.7rem"}} data-testid="close_modal">Cancel</AddLegtTypeButton>
    </Box>
    )
}

const renderStateCountryCityOption = (option: any) => {
  return (
    <Box className={`register_airport_option`}>
      <Box color="#636E72">
        <RoomOutlined />
      </Box>
      <Box>{option.name}</Box>
    </Box>
  )
}

const getInputStyle = (fieldName:string|null,actionType:string)=>{  
  return(actionType==="show"?{border:"none"}:fieldName === "phoneNumber"?{border:"1px solid #FFD17A !important"}:{ padding: "15px 14px 15px 70px"})
}

const getModalTitle = (actionType:string,section:string)=>{

  if(section==="client"){
    if(actionType === "add"){
      return "Add New Passenger"
    }
    else if(actionType === "edit"){
      return "Edit Passenger Details"
    }
    else {
      return "Passenger Details"      
    }
  }

  else{
    return "Add Passenger"
  }
}

 const getOptionLabel = (option:{name:string|null,city:string|null,code:string|null,countryCode:string})=>{

  if(option.city){
    return `${option.code} - ${option.name}, ${option?.countryCode}`
  }
  else{
    return option.name
  }

}

const passengerInitialValues = 
{
  firstName: "", lastName: "", dateOfBirth: "", gender: "", passportNumber: "", dateOfInsurance: "", dateOfExpiration: "",
  countryOfIssue: "", phoneNumber: "",countryCode:"",fullNumber:"",emailId: "", address: "", weight: "",passportFile:null
}

const AddPassengerModal = ({isDisable=false, isOpen, cancel,children,uploadIcon,initialValues=passengerInitialValues,submit=()=>{},
focusField=(fieldName:string|null)=>{},focusedField="",section="admin",actionType="add",passportRef=null,editPassengerInfo=()=>{},deletePassenger=()=>{}}: any) => {
  const countries = Country.getAllCountries()
  const today = new Date();
    today.setDate(today.getDate());        
  return (
    <>
      {children}
      <MuiModal
        open={isOpen}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          section={section}                   
        >
          <Box className='norder_detail_view_heading_parent'mb={2}>
            <GoldishSimpleButton
              style={{ color:"white", textTransform: "capitalize",fontSize:"1.8rem" }}
            >
              {getModalTitle(actionType,section)}             
            </GoldishSimpleButton>
          </Box>
          <Formik
            onSubmit={(values) => submit(values)}
            validateOnChange
            validateOnBlur
            initialValues={initialValues}
            validationSchema={passengerSchema}
            enableReinitialize>
            {({values,handleChange,setFieldValue,touched,setFieldError,errors,handleSubmit}:any) => (
              
              <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="firstName"}>First Name</PassengerLabel>
                  <CustomTextField
                    name={"firstName"}
                    disabled={actionType==="show"}
                    isFocusedTypeInput={focusedField==="firstName"}
                    placeholder='Enter first name'
                    fullWidth
                    value={values.firstName}
                    multiline
                    onChange={(event:any)=>setFieldValue("firstName",event.target.value)}
                    data-test-id="firstName"                    
                    id="firstName"
                    onFocus={()=>focusField("firstName")}
                    onBlur={()=>focusField(null)}
                    showError={touched.firstName && errors.firstName}
                    actionType={actionType}
                    section={section}
                    />
                    {touched.firstName && (
                      <Box mt={1} color={"red"} data-test-id="errorField">{errors.firstName}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="lastName"}>Last Name</PassengerLabel >
                  <CustomTextField 
                  placeholder='Enter last name'
                  actionType={actionType}
                  multiline
                  disabled={actionType==="show"} 
                  fullWidth
                  value={values.lastName}
                  onChange={handleChange}
                  name={"lastName"}
                  data-test-id="lastName"
                  isFocusedTypeInput={focusedField==="lastName"}
                  onFocus={()=>focusField("lastName")}
                  onBlur={()=>focusField(null)}
                  showError={touched.lastName && errors.lastName}
                  section={section}/>
                  {touched.lastName && (
                      <Box mt={1} color={"red"}>{errors.lastName}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="dateOfBirth"}>Date of Birth</PassengerLabel >
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
                  <DatePicker
                    maxDate={today}
                    onChange={(date) => {setFieldValue("dateOfBirth",date)}}
                    name={"dateOfBirth"}
                    className='advance_search_date_pick'
                    timeInputLabel='Time'
                    dateFormat={"MM/yyyy | h:mm aa"}
                    disabled={actionType==="show"}
                    showTimeInput={false}
                    placeholderText='Choose date of birth'
                    value={formatDate(values.dateOfBirth)}
                    yearDropdownItemNumber={100}
                    showYearDropdown
                    scrollableYearDropdown
                    customInput={
                      <CustomTextField
                        placeholder='Choose Date of Birth'
                        className={`login_test_password`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <DateRangeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        actionType={actionType}
                        section={section}
                        fullWidth
                        isFocusedTypeInput={focusedField==="dateOfBirth"}                  
                        showError={touched.dateOfBirth && errors.dateOfBirth}
                      />
                    }
                    data-test-id="dateOfBirth"
                    onFocus={()=>focusField("dateOfBirth")}
                    onBlur={()=>focusField(null)}
                  />
                  </StyledGrid>
                  {touched.dateOfBirth && (
                      <Box mt={1} color={"red"}>{errors.dateOfBirth}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel >Gender</PassengerLabel>
                  <Box className="order_detail_head_edit" height={'4rem'}>
                    <CustomFormControlLabel onChange={handleChange} disabled={actionType==="show"} style={radioStyle(actionType,"male",values.gender)} name="gender" value="Male" checked={values.gender?.toLowerCase()=="male"} data-test-id="radioButton" control={<CustomRadio  />} label="Male" />
                    <CustomFormControlLabel onChange={handleChange} disabled={actionType==="show"} style={radioStyle(actionType,"female",values.gender)} name="gender" value="Female" checked={values.gender?.toLowerCase() == "female"} control={<CustomRadio />} label="Female" />
                    <CustomFormControlLabel onChange={handleChange} disabled={actionType==="show"} style={radioStyle(actionType,"other",values.gender)} name="gender" value="Other" checked={values.gender?.toLowerCase() =="other"} control={<CustomRadio />} label="Other" />
                  </Box>
                  {touched.gender && (
                      <Box mt={"-10px"} color={"red"}>{errors.gender}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="passportNumber"}>Passport Number</PassengerLabel >
                  <CustomTextField
                  isFocusedTypeInput={focusedField==="passportNumber"}
                  onFocus={()=>focusField("passportNumber")}
                  onBlur={()=>focusField(null)}
                  name="passportNumber"
                  multiline
                  placeholder='Enter passport number' 
                  fullWidth
                  value={values.passportNumber}
                  onChange={handleChange}
                  actionType={actionType}
                  data-test-id="passportNumber"
                  disabled={actionType==="show"}
                  showError={touched.passportNumber && errors.passportNumber}
                  section={section} />
                  {touched.passportNumber && (
                      <Box mt={1} color={"red"}>{errors.passportNumber}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="dateOfInsurance"}>Date of Issuance</PassengerLabel>
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
                  <DatePicker  
                    disabled={actionType==="show"} 
                    onFocus={()=>focusField("dateOfInsurance")}
                    onBlur={()=>{
                      focusField(null)                                     
                    }}
                    onChange={(date) => {setFieldValue("dateOfInsurance",date) }}
                    name="dateOfInsurance"
                    className='advance_search_date_pick'
                    timeInputLabel='Time'
                    data-test-id="dateOfInsurance"
                    dateFormat={"MM/yyyy | h:mm aa"}
                    yearDropdownItemNumber={50}
                    showYearDropdown
                    scrollableYearDropdown
                    showTimeInput={false}
                    placeholderText='Choose date of issuance'
                    value={formatDate(values.dateOfInsurance)}
                    customInput={
                      <CustomTextField
                        placeholder='Choose date of issuance'
                        actionType={actionType}
                        section={section}
                        className={`login_test_password`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <DateRangeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        isFocusedTypeInput = {focusedField==="dateOfInsurance"}
                        showError={touched.dateOfInsurance && errors.dateOfInsurance}
                      />
                    }
                  />
                  </StyledGrid>
                  {touched.dateOfInsurance && (
                      <Box mt={1} color={"red"}>{errors.dateOfInsurance}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="dateOfExpiration"}>Date of Expiration</PassengerLabel >
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}                   >
                  <DatePicker
                    disabled={actionType==="show"}
                    onFocus={()=>focusField("dateOfExpiration")}
                    onBlur={()=>focusField(null)}
                    name="dateOfExpiration"
                    onChange={(date) => { setFieldValue("dateOfExpiration",date)}}
                    className='advance_search_date_pick'
                    timeInputLabel='Time'
                    dateFormat={"MM/yyyy"}
                    showTimeInput={false}
                    data-test-id="dateOfExpiration"
                    placeholderText='Choose date of expiration'
                    value = {formatDate(values.dateOfExpiration)}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                    customInput={
                      <CustomTextField
                        isFocusedTypeInput = {focusedField==="dateOfExpiration"}
                        placeholder='Choose date of expiration'
                        className={`login_test_password`}
                        showError={touched.dateOfExpiration && errors.dateOfExpiration}
                        actionType={actionType}
                        section={section}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <DateRangeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                    }
                  />
                  </StyledGrid>
                  {touched.dateOfExpiration && (
                      <Box mt={1} color={"red"}>{errors.dateOfExpiration}</Box>
                    )}                  
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="countryOfIssue"}>Country of Issue</PassengerLabel>
                  <CustomDepartueAiportAutoCompplete
                    section={"client"}
                    defaultValue={values.countryOfIssue}
                    disabled={actionType==="show"}
                    actionType={actionType}
                    onChange={(country: Event, newValue: any) => {setFieldValue("countryOfIssue",newValue) }} 
                    onFocus={()=>focusField("countryOfIssue")}
                    onBlur={()=>focusField(null)}  
                    isFocusedTypeInput = {focusedField==="countryOfIssue"}
                    showError={touched.countryOfIssue && errors.countryOfIssue}                 
                    ListboxProps={{ id: "regsiter_option_style" }}
                    getOptionLabel={(option: any) => option.name}
                    data-test-id="countryOfIssue"
                    name="countryOfIssue"
                    options={countries}
                    values={values.countryOfIssue?.name}
                    PopperComponent={(props: any) => (
                      <CustomStyledPopper
                        {...props}
                        zIndex={1500}
                        marginTop="2px"
                      />
                    )}
                    renderInput={(params: any) => (
                      <CustomTextField
                        {...params}                        
                        placeholder='Select Country'
                        InputProps={{
                          ...params.InputProps,
                           endAdornment: (
                            actionType!=="show" &&(<Box className='profile_hover_icons'>
                            <KeyboardArrowDown />
                          </Box>)                            
                          ),
                          startAdornment: (
                            <>
                            {actionType !== "show" && <Box>
                               <SearchOutlined style={{color:"#94A3B8"}} />
                            </Box>}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(
                      option: any,
                      { selected }: { selected: boolean },
                    ) => <>{renderStateCountryCityOption(option)}</>}
                  />
                  {touched.countryOfIssue && (
                      <Box mt={1} color={"red"}>{errors.countryOfIssue}</Box>
                    )}                  
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="phoneNumber"}>Phone Number</PassengerLabel>
                  <PhoneNumberFieldBox
                  onFocus={()=>focusField("phoneNumber")}
                  showError={errors.phoneNumber && touched.phoneNumber}
                  actionType={actionType}
                  section={section}
                  isFocusedTypeInput={focusedField==="phoneNumber"}
                  onBlur={()=>focusField(null)}>
                  <PhoneInput
                    disabled={actionType==="show"}
                    enableSearch={true}
                    onChange={(value, data: { dialCode: string }, event, formattedValue) => {
                      const countryCode = data.dialCode; // This gives you '1' for 'United States'
                      const phoneNumber = countryCode && value.substring(countryCode.length);
                      setFieldValue("phoneNumber", phoneNumber);
                      setFieldValue("countryCode", countryCode);
                      setFieldValue("full_number",value)
                    }}
                    searchClass="search_country_code"
                    inputClass={`phone_number_inputField`}
                    localization={{noEntriesMessage: 'No Options'}}
                    data-test-id="phoneNumber"
                    dropdownClass='register_drop_down_class'
                    containerClass='regsiter_contaitner_class'
                    searchPlaceholder="Search"
                    searchNotFound="No Options"
                    dropdownStyle={{ width:"3rem !important",zIndex: 2,marginTop: "-10px" }}
                    containerStyle={{ zIndex: 4, position: "relative", }}
                    country='us'
                    placeholder='Enter phone number'
                    value = {values.fullNumber}
                  />
                  </PhoneNumberFieldBox>
                  {touched.phoneNumber && (
                      <Box mt={1} color={"red"}>{errors.phoneNumber}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="emailId"}>Email ID</PassengerLabel>
                  <CustomTextField
                  disabled={actionType==="show"}
                  onFocus={()=>focusField("emailId")}
                  onBlur={()=>focusField(null)}
                  isFocusedTypeInput = {focusedField==="emailId"}
                  actionType={actionType}
                  section={section}
                  showError={touched.emailId && errors.emailId}                   
                  placeholder='Enter email ID'
                  data-test-id="emailId" 
                  fullWidth
                  name="emailId"
                  value={values.emailId}
                  onChange={handleChange} />
                  {touched.emailId && (
                      <Box mt={1} color={"red"}>{errors.emailId}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="address"}>Address</PassengerLabel>
                  <CustomTextField
                  disabled={actionType==="show"}
                  onFocus={()=>focusField("address")}
                  onBlur={()=>focusField(null)}
                  isFocusedTypeInput = {focusedField==="address"}
                  showError={touched.address && errors.address}
                  multiline 
                  placeholder='Enter full address' 
                  fullWidth
                  data-test-id="address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  actionType={actionType}
                  section={section} />
                  {touched.address && (
                      <Box mt={1} color={"red"}>{errors.address}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="weight"}>Weight</PassengerLabel>
                  <CustomTextField 
                  disabled={actionType==="show"}
                  placeholder='Enter weight' 
                  fullWidth
                  type='number'
                  actionType={actionType}
                  section={section}
                  name="weight"
                  value={values.weight} 
                  data-test-id="weight"                 
                  onChange={handleChange}
                  onFocus={()=>focusField("weight")}
                  onBlur={()=>focusField(null)}
                  showError={touched.weight && errors.weight}
                  isFocusedTypeInput = {focusedField==="weight"}
                  InputProps={{
                    startAdornment: <InputAdornment style={{position:'absolute',marginLeft:'7rem'}} position="end"><Box color={'white'}>kg</Box></InputAdornment>,
                  }}
                 />
                 {touched.weight && (
                      <Box mt={1} color={"red"}>{errors.weight}</Box>
                    )}
                  
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  {actionType!=="show" && values.passportFile === null && (
                    <>
                    <PaymentLabel style={{color:"rgba(148, 163, 184, 1)"}}>Upload Passport Picture</PaymentLabel>
                    <Box 
                  className="order_detail_upload_box" 
                  onClick={()=>passportRef.current.click()} 
                  border={touched.passportFile && errors.passportFile && "1px dashed #f87171"}>
                    {uploadIcon}                   
                  </Box>
                  <input
                  disabled={actionType==="show"}
                  type="file"
                  id="file-input"
                  ref={passportRef}
                  accept=".png, .jpg, .jpeg, .pdf"
                  style={{ display: "none"}}
                  onChange={(
                     event: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                     if (
                        event.target.files &&
                        event.target.files.length > 0
                     ) {
                         const file = event?.target?.files[0];
                         const maxSize = 5 * 1024 * 1024;
                         const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
                         if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                            setFieldValue("passportFile", [file]);
                                                 
                           } else {
                               setFieldError("passportFile","Please add valid image");
                           }
                       }
                    
                    }}
                  />
                  {touched.passportFile && (
                      <Box mt={1} color={"red"}>{errors.passportFile}</Box>
                    )}
                    </>
                  ) }
                  {values.passportFile && (
                    <>
                    <PassengerLabel>Passport Picture</PassengerLabel >
                    <ContainerSameAsTextField className="order_detail_view_heading_parent" style={{margin:"1rem 0 0 0"}} passenger={true}>
                    <Box className="order_detail_doc_parent">
                      <Box marginLeft={'1rem'}>
                        <PDF_ICON unique_id={"key"}/>
                      </Box>
                      <Box fontSize={"1.4rem"}>{values.passportFile?.[0].name}</Box>
                    </Box>
                    <Box>
                      {actionType !== "show" && <IconButton onClick={()=> setFieldValue("passportFile", null)} data-testid="delete_doc" className = 'order_detail_icon_buton' ><DeleteOutline style={{color:'#DC2626'}}/></IconButton>}
                      {actionType === "show" && <IconButton className = 'order_detail_icon_buton'><SaveAlt style={{color:colors.SAVE_DARK_COLOUR}}/></IconButton>}
                    </Box>
                  </ContainerSameAsTextField>
                   </>
                  )}
                </Grid>

                
                <Grid sm={12}>                  
                </Grid>
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                {actionType === "show" ?(
                  <Box display={"flex"} justifyContent={"space-around"} mt={3}>
                  <AddLegtTypeButton style={{flexGrow:1,marginRight:"1rem"}} onClick={()=>cancel()}>Cancel</AddLegtTypeButton>
                  <AddLegtTypeButton style={{border:"1px solid #f87171",flexGrow:1,marginRight:"1rem",color:"#F87171"}} onClick={()=>deletePassenger(initialValues.passengerId)}>Delete</AddLegtTypeButton>
                  <FormSubmitButton style={{flexGrow:1}} onClick={()=>editPassengerInfo("edit")}>Edit</FormSubmitButton>                 
                  </Box>):<CancelAndSaveChanges cancel={()=>cancel()} submit={()=>handleSubmit()} section={section} isDisabled={isDisable}/>
                 }
              </Grid>
              
              </form>
            )}
          </Formik>          
        </StyledModalContent>
      </MuiModal>
    </>
  )
}

const AddNewClientModal = ({ isOpen, cancel, children,getSearchedAirports,airports,focusedField,setFocusedField,createNewProfile,clientForm,register=false, }: any) => {
  let airportDebounceTimeoutId: ReturnType<typeof setTimeout> | null = null;
  const handleAirportChange = (valueToSearch:string) => { 
    if(valueToSearch.length <= 2){
      return
    }  
    if (airportDebounceTimeoutId) clearTimeout(airportDebounceTimeoutId);

    airportDebounceTimeoutId = setTimeout(() => {
      getSearchedAirports(valueToSearch);
    },500);
  };
  return (
    <>
      {children}
      <MuiModal
        open={isOpen}

      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          section = {"client"}          
        >
          <Box className='order_detail_view_heading_parent'>
            <GoldishSimpleButton
              style={{ color: "white", textTransform: "capitalize" }}
            >
              {register ? "Add New Client" : "Register New Client"}
            </GoldishSimpleButton>
          </Box>
          
          <Formik
            onSubmit={(values)=>{              
              createNewProfile(values)
            }}
            validateOnChange
            validateOnBlur
            initialValues={{phoneNumber: "", countryCode: "", fullName: "", preferredAirport: null, emailId: "" }}
            validationSchema={phoneValidationSchema}
            enableReinitialize
            innerRef={clientForm}
               
            >
            {({values,handleChange,setFieldValue,touched,setFieldError,errors,handleSubmit}:any) => (
              
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <PassengerLabel isFocused={focusedField==="fullName"}>Full Name</PassengerLabel>
                    <CustomTextField
                      placeholder='Full Name'
                      fullWidth                      
                      id="fullName"
                      name="fullName"
                      onChange={handleChange}
                      value={values.fullName}
                      isFocusedTypeInput={focusedField==="fullName"}
                      showError={touched.fullName && errors.fullName}
                      editInfo={true}
                      section = {"client"}
                      onFocus={()=>setFocusedField("fullName")}
                      onBlur={()=>setFocusedField(null)}
                      actionType = {"add"}
                      data-testid="fullName" />
                    {touched.fullName && (
                      <Box className="register_valid_error">
                        {errors.fullName}
                      </Box>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={12}>
                    <PassengerLabel isFocused={focusedField==="emailId"}>Email ID</PassengerLabel>
                    <CustomTextField
                      placeholder='Email ID' fullWidth
                      onChange={handleChange} id="email" 
                      name="emailId"
                      value={values.emailId}
                      isFocusedTypeInput={focusedField==="emailId"}
                      onFocus={()=>setFocusedField("emailId")}
                      section = {"client"}
                      onBlur={()=>setFocusedField(null)}
                      editInfo={true}
                      showError={touched.emailId && errors.emailId}
                      actionType = {"add"}
                      data-testid="email" />
                    {touched.emailId && (
                      <Box className={"register_valid_error"}>{errors.emailId}</Box>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="phoneNumber"}>Phone Number</PassengerLabel>
                  <PhoneNumberFieldBox                                                      
                  showError={errors.phoneNumber && touched.phoneNumber}
                  actionType={"add"}
                  isFocusedTypeInput={focusedField==="phoneNumber"}
                  className="phoneNumberField">                                    
                  <PhoneInput                    
                    enableSearch={true}
                    searchNotFound="No Options"
                    onChange={(value, data: { dialCode: string }, event, formattedValue) => {
                      const countryCode = data.dialCode;
                      const phoneNumber = countryCode && value.substring(countryCode.length);
                      setFieldValue("phoneNumber", phoneNumber);
                      setFieldValue("countryCode", countryCode);
                      setFieldValue("full_number",value)
                    }}
                    searchClass="search_country_code"
                    inputClass={`phone_number_inputField`}
                    data-test-id="phoneNumber"
                    dropdownClass='register_drop_down_class'
                    containerClass='regsiter_contaitner_class'
                    onFocus={()=>setFocusedField("phoneNumber")}
                    onBlur={()=>setFocusedField(null)}
                    searchPlaceholder="Search"
                    localization = {{noEntriesMessage: 'No countries found',}}
                    dropdownStyle={{ width:"32rem",zIndex: 2,marginTop: "-10px" }}
                    containerStyle={{ zIndex: 4,position: "relative" }}
                    country='us'
                    placeholder='Enter phone number'
                    value = {values.fullNumber}
                  />
                  </PhoneNumberFieldBox>
                  {touched.phoneNumber && (
                      <Box mt={1} color={"red"}>{errors.phoneNumber}</Box>
                    )}
                </Grid>

                  <Grid item lg={6} md={6} sm={12}>
                    <PassengerLabel isFocused={focusedField === "preferredAirport"}>Preferred Departure Airport</PassengerLabel>
                    <CustomDepartueAiportAutoCompplete
                      onChange={(airport: Event, newValue: any) => { setFieldValue("preferredAirport", newValue) }}
                      isFocusedTypeInput={focusedField==="preferredAirport"}
                      showError={touched.preferredAirport && errors.preferredAirport}
                      actionType = {"add"}
                      onFocus={()=>setFocusedField("preferredAirport")}
                      onBlur={()=>setFocusedField(null)}
                      data-testId="airport"
                      PopperComponent={(props: any) => (
                        <CustomStyledPopper highIndex={9999}{...props} top="calc(100% + 8px)" left={0} />
                      )}
                      ListboxProps={{ id: "regsiter_option_style", style: { zIndex: 9999 } }}
                      getOptionLabel={(option: any) => getOptionLabel(option)}
                      filterOptions={(option:any)=>option}
                      options={airports}
                      renderOption={(option: any, { selected }: any) => renderOptionFrom(option, selected)}
                      value={values.preferredAirport}
                      data-testid="airport"
                      renderInput={(params: any) => (
                        <CustomFormTextField
                          {...params}
                          placeholder='Select Preferred Departure Airport'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Box className='profile_hover_icons' onClick={()=>{
                                values.preferredAirport && (setFieldValue("preferredAirport",null))
                              }}
                              style={{cursor:'pointer', zIndex:1,position:"relative",top:0}}>
                                {values.preferredAirport ? <ClearOutlined/>:<KeyboardArrowDown/>}
                              </Box>
                            ),
                            
                          }}
                          onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleAirportChange(event.target.value)}
                        />
                      )}
                    />
                    {touched.preferredAirport && (
                      <Box className="register_valid_error">
                        {errors.preferredAirport}
                      </Box>
                    )}
                  </Grid>
                  <Grid md={12} lg={12} sm={12}>              
                  <Box mt={2} pr={1.1} pl={1}>
                  <FormSubmitButton fullWidth onClick={()=>handleSubmit()} data-testid="save_changes_button">Create Account</FormSubmitButton>
                  <AddLegtTypeButton fullWidth onClick={() => cancel()} style={{marginTop:"1.7rem"}} data-testid="close_modal">Cancel</AddLegtTypeButton>
                 </Box>                 
                  </Grid>                 
          </Grid>                            
              </form>
            )}
          </Formik>
        </StyledModalContent>
      </MuiModal>
    </>
  )
}

const getPaymentMethodTitle = (action:string)=>{

  if(action === "add"){
    return "Add Credit/Debit Card"
  }

  else if(action === "edit"){
    return "Edit Credit/Debit Card"
  }

  else{
    return "Credit/Debit Card Details"
  }

}

const cardDetails = {
  cardNumber:"",
  cardholderName : "",
  expiryDate:"",
  securityCode:"",
  streetAddress:"",
  city:"",
  state:"",
  zipCode:"",
  country:{name:""},
  
}


const AddPaymentMethodModal = ({actionType="show",showModal = false,cancel=()=>{},submit=()=>{},openPaymentModal=()=>{},initialValues=cardDetails,
focusedField,focusField=()=>{},createCard = ()=>{},makePrimaryPaymentMethod= ()=>{},primaryMethod=false,deleteCard=()=>{},cardFormik}:any)=>{ 
  

  return(
    <>
    <MuiModal
        open={showModal}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          section={"client"}                             
        >
          <Box className='norder_detail_view_heading_parent'mb={2}>
            <GoldishSimpleButton
              style={{ color:"white", textTransform: "capitalize",fontSize:"1.8rem" }}
            >
              {getPaymentMethodTitle(actionType)}
            </GoldishSimpleButton>
          </Box>
          <Formik
            onSubmit={(values)=>createCard(values)}
            validateOnChange
            validateOnBlur
            innerRef={cardFormik}
            initialValues={initialValues}
            validationSchema={cardValidationSchema}            
            enableReinitialize>
            {({values,handleChange,setFieldValue,touched,setFieldError,errors,handleSubmit}:any) => (
              
              <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12}>
                  <PassengerLabel>Card Number</PassengerLabel>
                  <CustomTextField
                    name={"cardNumber"}
                    actionType={actionType}
                    section={"client"}
                    placeholder='Enter Card Number'
                    fullWidth
                    value={values.cardNumber}
                    multiline
                    isFocusedTypeInput={focusedField==="cardNumber"}
                    onFocus={()=>focusField("cardNumber")}
                    onBlur={()=>focusField(null)}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>setFieldValue("cardNumber",event.target.value)}
                    disabled={actionType==="show"||actionType==="edit"}
                    data-test-id="cardNumber"                    
                    id="cardNumber"                   
                    showError={touched.cardNumber && errors.cardNumber}                    
                    />                    
                    {touched.cardNumber && (
                      <Box mt={1} color={"red"} data-test-id="errorField">{errors.cardNumber}</Box>
                    )}
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  <PassengerLabel>Cardholder Name</PassengerLabel >
                  <CustomTextField 
                  placeholder='Enter Cardholder Name'                  
                  multiline                   
                  fullWidth
                  value={values.cardholderName}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  name={"cardholderName"}
                  data-test-id="cardholderName"                  
                  showError={touched.cardholderName && errors.cardholderName}
                  isFocusedTypeInput={focusedField==="cardHolderName"}
                  onFocus={()=>focusField("cardHolderName")}
                  onBlur={()=>focusField(null)}
                  actionType={actionType}
                  id="cardholderName"
                  section={"client"}
                  />
                  {touched.cardholderName && (
                      <Box mt={1} color={"red"}>{errors.cardholderName}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel>Expiry Date</PassengerLabel >
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
                  <DatePicker 
                    disabled={actionType==="show"}                   
                    onChange={(date) => {setFieldValue("expiryDate",formatDate(date,true))}}
                    minDate={today}
                    name={"expiryDate"}
                    className='advance_search_date_pick'
                    timeInputLabel='Time'
                    dateFormat={"MM/yy | h:mm aa"}                    
                    showTimeInput={false}
                    placeholderText="MM/YY"
                    value={values.expiryDate}
                    yearDropdownItemNumber={100}
                    showYearDropdown
                    scrollableYearDropdown
                    customInput={
                      <CustomTextField                        
                        actionType={actionType}
                        section={"client"}
                        className={`login_test_password`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <DateRangeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        showError={touched.expiryDate && errors.expiryDate}                        
                        isFocusedTypeInput={focusedField==="expiryDate"}
                        onFocus={()=>focusField("expiryDate")}
                        onBlur={()=>focusField(null)}
                      />
                    }
                    data-test-id="expiryDate"
                  />
                  </StyledGrid>
                  {touched.expiryDate && (
                      <Box mt={1} color={"red"}>{errors.expiryDate}</Box>
                    )}
                </Grid>                

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel >Security Code</PassengerLabel >
                  <CustomTextField
                  name="securityCode"
                  multiline
                  placeholder='xxx' 
                  fullWidth
                  value={values.securityCode}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  data-test-id="securityCode"
                  actionType={actionType}
                  section={"client"}
                  showError={touched.securityCode && errors.securityCode}
                  isFocusedTypeInput={focusedField==="securityCode"}
                  onFocus={()=>focusField("securityCode")}
                  onBlur={()=>focusField(null)}/>
                  {touched.securityCode && (
                      <Box mt={1} color={"red"}>{errors.securityCode}</Box>
                    )}
                </Grid>                              

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel>Street Address</PassengerLabel>
                  <CustomTextField
                  showError={touched.emailId && errors.emailId}                   
                  placeholder='Enter Street Address'
                  data-test-id="streetAddress" 
                  fullWidth                  
                  name="streetAddress"
                  value={values.streetAddress}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  actionType={actionType}
                  section={"client"}
                  isFocusedTypeInput={focusedField==="streetAddress"}
                  onFocus={()=>focusField("streetAddress")}
                  onBlur={()=>focusField(null)} />
                  {touched.streetAddress && (
                      <Box mt={1} color={"red"}>{errors.streetAddress}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel>City</PassengerLabel>
                  <CustomTextField                  
                  showError={touched.city && errors.city}
                  isFocusedTypeInput={focusedField==="city"}
                  onFocus={()=>focusField("city")}
                  onBlur={()=>focusField(null)}
                  multiline 
                  placeholder='Enter City' 
                  fullWidth
                  data-test-id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  actionType={actionType}
                  section={"client"}
                   />
                  {touched.city && (
                      <Box mt={1} color={"red"}>{errors.city}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel>State</PassengerLabel>
                  <CustomTextField                   
                  placeholder='Enter State' 
                  multiline
                  actionType={actionType}
                  section={"client"}
                  fullWidth                  
                  name="state"
                  value={values.state} 
                  data-test-id="state"                 
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  showError={touched.state && errors.state}
                  isFocusedTypeInput={focusedField==="state"}
                  onFocus={()=>focusField("state")}
                  onBlur={()=>focusField(null)}
                 />
                 {touched.weight && (
                      <Box mt={1} color={"red"}>{errors.weight}</Box>
                    )}
                  
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel>Zip Code</PassengerLabel>
                  <CustomTextField                   
                  placeholder='Enter Zip Code' 
                  multiline
                  fullWidth                  
                  name="zipCode"
                  value={values.zipCode}
                  actionType={actionType}
                  section={"client"} 
                  data-test-id="zipCode"                 
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  showError={touched.zipCode && errors.zipCode}
                  isFocusedTypeInput={focusedField==="zipCode"}
                  onFocus={()=>focusField("zipCode")}
                  onBlur={()=>focusField(null)}
                 />
                 {touched.zipCode && (
                      <Box mt={1} color={"red"}>{errors.zipCode}</Box>
                    )}
                  
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  <PassengerLabel >Country</PassengerLabel>
                  <CustomDepartueAiportAutoCompplete                    
                    defaultValue={values.country}
                    actionType={actionType}
                    section={"client"}                    
                    onChange={(country: Event, newValue: any) => {setFieldValue("country",newValue) }}
                    disabled={actionType==="show"}                                                           
                    showError={touched.country && errors.country}                 
                    ListboxProps={{ id: "regsiter_option_style" }}
                    getOptionLabel={(option: any) => option.name}
                    options={countries}
                    data-test-id="country"
                    name="country"                  
                    values={values.country}
                    PopperComponent={(props: any) => (
                      <CustomStyledPopper
                        {...props}
                        zIndex={1500}
                        marginTop="1px"
                      />
                    )}
                    renderInput={(params: any) => (
                      <CustomTextField
                        {...params}                        
                        placeholder="Country"
                        InputProps={{
                          ...params.InputProps,                           
                          startAdornment: (
                            <Box>
                              <SearchOutlined style={{color:"#94A3B8"}} />
                            </Box>
                          ),
                          endAdornment:(
                            <Box className='profile_hover_icons'>
                            <KeyboardArrowDown />
                          </Box>
                          )
                        }}
                        isFocusedTypeInput={focusedField==="securityCode"}
                        onFocus={()=>focusField("securityCode")}
                        onBlur={()=>focusField(null)}
                      />
                    )}
                    renderOption={(
                      option: any,
                      { selected }: { selected: boolean },
                    ) => <>{renderStateCountryCityOption(option)}</>}
                  />
                  {touched.country && (
                      <Box mt={1} color={"red"}>{errors.country}</Box>
                    )}                  
                </Grid>
              </Grid>
              {actionType==="show"?(
                <>                
                {values.primaryMethod ?<AddLegtTypeButton style={{marginTop:"2rem",color:"#34D399",background:"rgba(52, 211, 153, 0.1)",border:"1px solid #34D399"}} fullWidth>Primary Method</AddLegtTypeButton>
                :<AddLegtTypeButton fullWidth onClick={()=>makePrimaryPaymentMethod(values.id)} style={{marginTop:"2rem"}} data-test-id="makePrimaryMethodButton">Make Primary Payment Method</AddLegtTypeButton>}
                
              <Box display={"flex"} justifyContent={"space-around"} mt={3}>
                  <AddLegtTypeButton style={{flexGrow:1,marginRight:"1rem"}} onClick={()=>cancel()} data-test-id="cancel">Cancel</AddLegtTypeButton>
                  <AddLegtTypeButton style={{color:"#F87171",border:"1px solid #f87171",flexGrow:1,marginRight:"1rem"}} onClick={()=>{deleteCard()}} data-test-id="deleteCard">Delete</AddLegtTypeButton>
                  <FormSubmitButton style={{flexGrow:1}} onClick={()=>{openPaymentModal("edit")}} data-test-id="editCardButton">Edit</FormSubmitButton>                 
                  </Box>
                  </>):
                  <CancelAndSaveChanges cancel={()=>cancel()} submit={()=>handleSubmit()} section={"client"}></CancelAndSaveChanges>                                  
                  }
              
              </form>
            )}
          </Formik>
                     
          
                            
        </StyledModalContent>
      </MuiModal>
    </>
  )
}


export { AddNewClientModal, AddPassengerModal, CancelAndSaveChanges,AddPaymentMethodModal }